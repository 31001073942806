import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import queryBuilder from "../queryBuilder/queryBuilder";
import { showToastWithTimeout } from "../message";
import { normalizeError } from "../../../utils/errorHandling";
import { initLoadCharts, loadRowExpandChart } from "./dashboard";

function reloadVisTable(chart, dispatch) {
  const { visualizationId, filters } = chart;

  // reload drilldown by vis ID
  if ((visualizationId ?? "").includes("-row-visualization-")) {
    return dispatch(loadRowExpandChart(filters, chart));
  }

  // reload summary table
  dispatch(initLoadCharts(visualizationId));
}

export const getCommentsQueryTable =
  (commentCellData) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.GET_COMMENTS_TABLE_START });

      let query = null;

      const res = await axios.get(`/api/v1/connection-comments/table`);

      query = res.data?.data;

      if (!res.data?.data || res.data.data.length === 0) {
        const created = await axios.post(`/api/v1/connection-comments/table`);

        query = created.data.data;
      }

      const chart = {
        overrides: [],
        queryId: query.uuid,
        allDates: true,
        filters: [
          { type: "io_row_uuid", value: commentCellData.io_row_uuid },
          { type: "io_deleted_at", value: null },
        ],
      };

      const qs = queryBuilder(chart, getState);

      const exec = await axios.get(
        `api/v1/queries/${chart.queryId}/exec?${qs}`
      );

      dispatch({
        type: actionTypes.GET_COMMENTS_TABLE_SUCCESS,
        data: exec.data?.data ?? [],
      });
    } catch (err) {
      dispatch({ type: actionTypes.GET_COMMENTS_TABLE_FAIL });
    }
  };

export const createUpdateComment = (params) => async (dispatch) => {
  const {
    comment,
    tbl,
    field,
    rowUuid: io_row_uuid,
    commentUuid: uuid,
    chart,
    reloadTable,
    closeModal,
  } = params;

  try {
    dispatch({
      type: uuid
        ? actionTypes.UPDATE_COMMENTS_START
        : actionTypes.CREATE_COMMENTS_START,
    });

    const res = await axios({
      method: uuid ? "PUT" : "POST",
      url: `/api/v1/connection-comments/${uuid ?? ""}`,
      data: { comment, tbl, field, io_row_uuid },
    });

    dispatch({
      type: uuid
        ? actionTypes.UPDATE_COMMENTS_SUCCESS
        : actionTypes.CREATE_COMMENTS_SUCCESS,
      results: res.data,
    });

    showToastWithTimeout([`Comment ${uuid ? "updated" : "added"}.`], "success");

    closeModal(null); // close modal on save click

    // reload table only after add comment
    if (reloadTable) {
      reloadVisTable(chart, dispatch);
    }
  } catch (error) {
    dispatch({
      type: uuid
        ? actionTypes.UPDATE_COMMENTS_FAIL
        : actionTypes.CREATE_COMMENTS_FAIL,
    });

    showToastWithTimeout([normalizeError(error).message], "danger");
  }
};

export const deleteComment =
  (commentUuid, setCommentItem, chart, reloadTable) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.DELETE_COMMENTS_START });

      await axios.delete(`/api/v1/connection-comments/${commentUuid}`);

      dispatch({ type: actionTypes.DELETE_COMMENTS_SUCCESS, commentUuid });
      showToastWithTimeout([`Comment deleted.`], "success");
      setCommentItem(null);

      // reload table only if it was last comment in the list
      if (reloadTable) {
        reloadVisTable(chart, dispatch);
      }
    } catch (error) {
      dispatch({ type: actionTypes.DELETE_COMMENTS_FAIL });
      showToastWithTimeout([normalizeError(error).message], "danger");
    }
  };
