import { components } from "react-select";
import { allOption } from "./SelectWithCheckboxes";

export default function MultiValue(props) {
  let labelToBeDisplayed = props.data.label;

  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }

  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
}
