import * as actionTypes from "./actionTypes";
import qs from "qs";
import axios from "../../axios";
import { showToast } from "./message";
import { omitBy, isEmpty } from "lodash-es";
import { handleError } from "../../utils/errorHandling";
import { permissionName } from "../../Pages/UserManagement/Roles/models";

export const loadUserList = () => {
  return {
    type: actionTypes.GET_USER_LIST_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/users`,
      },
    },
  };
};

export const createUser = (user, history) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_USER_START });

  try {
    const res = await axios.post("/api/v1/users", qs.stringify(user));

    if (user.image) {
      dispatch(uploadProfileImage(user.image, res.data.data.uuid));
    }

    history.goBack();

    showToast(
      actionTypes.CREATE_USER_SUCCESS,
      ["User successfully created"],
      "success"
    );
  } catch (err) {
    const handleErrors = err.response.data.errors
      ? Object.values(err.response.data.errors).map((error) => error[0])
      : [err.response.data.message];
    showToast(actionTypes.CREATE_USER_FAIL, handleErrors, "danger");
  }
};

export const deleteUser = (uuid) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_USER_START });

  try {
    await axios.delete(`/api/v1/users/${uuid.toString()}`);
    showToast(
      actionTypes.DELETE_USER_SUCCESS,
      ["User was deleted successfully."],
      "success"
    );

    dispatch(loadUserList());
  } catch (err) {
    const handleErrors = err.response.data.errors
      ? Object.values(err.response.data.errors).map((error) => error[0])
      : [err.response.data.message];
    showToast(actionTypes.DELETE_USER_FAIL, handleErrors, "danger");
  }
};

export const updateUser =
  (user, history, reloadUserList) => async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_USER_START });

    try {
      const { uuid, image, avatar, isAvatarRemoved } = user;

      if ((avatar && image) || isAvatarRemoved) {
        await dispatch(deleteProfileImage(uuid));
      }

      if (image) {
        await dispatch(uploadProfileImage(user.image, uuid));
      }

      const userObj = Object.fromEntries(
        Object.entries(user).filter(
          ([key, val]) => val || ["accountUuid", "group"].includes(key)
        )
      );
      await axios.put(
        `/api/v1/users/${uuid.toString()}`,
        qs.stringify(userObj)
      );

      showToast(
        actionTypes.UPDATE_USER_SUCCESS,
        ["User successfully updated"],
        "success"
      );

      reloadUserList && (await dispatch(loadUserList()));

      history && history.goBack();
    } catch (err) {
      const handleErrors = err.response.data.errors
        ? Object.values(err.response.data.errors).map((error) => error[0])
        : [err.response.data.message];
      showToast(actionTypes.UPDATE_USER_FAIL, handleErrors, "danger");
    }
  };

export const uploadProfileImage = (file, uuid) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_PROFILE_IMAGE_START });

  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  formData.append("file", file);

  try {
    const res = await axios.post(
      `/api/v1/users/${uuid.toString()}/avatar`,
      formData,
      { headers: headers }
    );

    dispatch({
      type: actionTypes.UPLOAD_PROFILE_IMAGE_SUCCESS,
      results: user.uuid === uuid ? res.data.data : null,
    });

    if (user.uuid === uuid) {
      localStorage.setItem(
        "user",
        JSON.stringify({ ...user, avatar: res.data.data })
      );
    }

    showToast(
      actionTypes.UPLOAD_PROFILE_IMAGE_SUCCESS + uuid,
      ["Upload success"],
      "success"
    );
  } catch (err) {
    showToast(
      actionTypes.UPLOAD_PROFILE_IMAGE_FAIL,
      ["Upload failed"],
      "danger"
    );
  }
};

export const deleteProfileImage = (uuid) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));

  try {
    const res = await axios.delete(`/api/v1/users/${uuid.toString()}/avatar`);

    dispatch({
      type: actionTypes.DELETE_PROFILE_IMAGE_SUCCESS,
      results: res,
    });

    if (user.uuid === uuid) {
      localStorage.setItem("user", JSON.stringify({ ...user, avatar: null }));
      dispatch({ type: actionTypes.CLEAR_AVATAR_ON_DELETE });
    }

    showToast(
      actionTypes.DELETE_PROFILE_IMAGE_SUCCESS,
      ["Delete success"],
      "success"
    );
  } catch (err) {
    showToast(
      actionTypes.DELETE_PROFILE_IMAGE_FAIL,
      ["Delete failed"],
      "danger"
    );
  }
};

export const loadUserGroups = () => {
  return {
    type: actionTypes.LOAD_USER_GROUPS_START,
    meta: {
      api: {
        method: "get",
        endpoint: "/api/v1/groups",
      },
    },
  };
};

export const createUserAccount =
  (name, displayName, dataSourcesAccess = []) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_USER_ACCOUNT_START });

    try {
      const res = await axios.post("/api/v1/accounts", {
        name,
        displayName,
        dataSourcesAccess,
      });

      dispatch({
        type: actionTypes.CREATE_USER_ACCOUNT_SUCCESS,
        payload: res.data.data,
      });
      return res;
    } catch (err) {
      handleError(err);
      dispatch({ type: actionTypes.CREATE_USER_ACCOUNT_FAIL });
    }
  };

export const createUpdateUserGroup =
  ({
    name,
    displayName,
    description,
    token,
    expiredAt,
    action,
    uuid,
    accountUuid,
    accounts,
    accessRules,
    alias,
  }) =>
  async (dispatch) => {
    const create = action === "Create";
    const url = `api/v1/groups${create ? "" : "/" + uuid}`;
    let accUuid = accountUuid || accounts[0]?.uuid || null;

    dispatch({
      type: create
        ? actionTypes.CREATE_USER_GROUP_START
        : actionTypes.UPDATE_USER_GROUP_START,
    });

    try {
      if (!accUuid) {
        const res = await dispatch(
          createUserAccount("default", "Default account")
        );
        accUuid = res.data.data.uuid;
      }

      const res = await axios({
        url: url,
        method: create ? "POST" : "PUT",
        data: {
          name,
          displayName,
          description,
          token,
          expiredAt,
          accountUuid: accUuid,
          ...(accessRules && { accessRules }),
          alias,
        },
      });

      dispatch({
        type: create
          ? actionTypes.CREATE_USER_GROUP_SUCCESS
          : actionTypes.UPDATE_USER_GROUP_SUCCESS,
        results: res.data,
      });

      showToast(
        create
          ? actionTypes.CREATE_USER_GROUP_SUCCESS + "toast"
          : actionTypes.UPDATE_USER_GROUP_SUCCESS + "toast",
        ["Group saved"],
        "success"
      );
      dispatch(loadUserGroups());
    } catch (err) {
      dispatch({
        type: create
          ? actionTypes.CREATE_USER_GROUP_FAIL
          : actionTypes.UPDATE_USER_GROUP_FAIL,
      });
      showToast(
        create
          ? actionTypes.CREATE_USER_GROUP_FAIL
          : actionTypes.UPDATE_USER_GROUP_FAIL,
        ["Group not saved"],
        "danger"
      );
      throw err;
    }
  };

export const deleteUserGroup = (uuid) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_USER_GROUP_START });

  try {
    await axios.delete(`/api/v1/groups/${uuid}`);

    dispatch({ type: actionTypes.DELETE_USER_GROUP_SUCCESS });
    dispatch(loadUserGroups());
    showToast(
      actionTypes.DELETE_USER_GROUP_SUCCESS,
      ["Delete group success"],
      "success"
    );
  } catch (err) {
    dispatch({ type: actionTypes.DELETE_USER_GROUP_FAIL });
    showToast(
      actionTypes.DELETE_USER_GROUP_FAIL,
      ["Delete group failed"],
      "danger"
    );
  }
};

export const loadUserAccounts = () => {
  return {
    type: actionTypes.LOAD_USER_ACCOUNTS_START,
    meta: {
      api: {
        endpoint: "/api/v1/accounts",
      },
    },
  };
};

export const loadUserRoles = () => {
  return {
    type: actionTypes.LOAD_USER_ROLES_START,
    meta: {
      api: {
        method: "get",
        endpoint: "/api/v1/roles",
      },
    },
  };
};

export function createUpdateUserRole(role) {
  const payload = omitBy(role, isEmpty);

  return async (dispatch) => {
    await dispatch({
      type: role.uuid
        ? actionTypes.UPDATE_USER_ROLE_START
        : actionTypes.CREATE_USER_ROLE_START,
      meta: {
        api: {
          method: role.uuid ? "put" : "post",
          endpoint: `/api/v1/roles/${role.uuid || ""}`,
          payload,
          toastOnFailure: true,
        },
        toasts: [
          {
            type: "success",
            title: "Role",
            message: `Role ${role.uuid ? "updated" : "created"}`,
            condition: actionTypes.UPDATE_USER_ROLE_SUCCESS,
          },
        ],
      },
    });
    return dispatch(loadUserRoles());
  };
}

export const updateRole = (role) => {
  const hasRoleQueryRead = role.permissions.includes(
    permissionName.QUERIES_READ
  );
  const roleWithQueryRead = hasRoleQueryRead
    ? role
    : {
        ...role,
        permissions: [
          ...role.permissions,
          { name: permissionName.QUERIES_READ },
        ],
      };

  const payload = omitBy(roleWithQueryRead, isEmpty);

  return async (dispatch) => {
    await dispatch({
      type: actionTypes.UPDATE_USER_ROLE_START,
      meta: {
        api: {
          method: "put",
          endpoint: `/api/v1/roles/${role.uuid}`,
          payload,
          toastOnFailure: true,
        },
        toasts: [
          {
            type: "success",
            title: "Role",
            message: "Role has been updated",
            condition: actionTypes.UPDATE_USER_ROLE_SUCCESS,
          },
        ],
      },
    });
    dispatch(loadUserRoles());
  };
};

export const deleteRole = (role) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_USER_ROLE_START });

  try {
    await axios.delete(`/api/v1/roles/${role.uuid}`);

    dispatch({ type: actionTypes.DELETE_USER_ROLE_SUCCESS });
    dispatch(loadUserRoles());
    showToast(
      actionTypes.DELETE_USER_ROLE_SUCCESS,
      ["Role was deleted successfully."],
      "success"
    );
  } catch (err) {
    dispatch({ type: actionTypes.DELETE_USER_ROLE_FAIL });
    handleError(err);
  }
};

export const loadPermissionList = () => {
  return {
    type: actionTypes.LOAD_PERMISSION_LIST_START,
    meta: {
      api: {
        method: "get",
        endpoint: "/api/v1/permissions",
      },
    },
  };
};

export const updateUserPassword = (user) => (dispatch) => {
  const payload = {
    uuid: user.uuid,
    name: user.name,
    password: user.password,
    password_confirmation: user.repeatPassword,
    old_password: user.oldPassword,
    email: user.email,
  };

  dispatch({
    type: actionTypes.UPDATE_USER_PASSWORD_START,
    meta: {
      api: {
        method: "put",
        endpoint: "/api/v1/me",
        payload,
        toastOnFailure: true,
      },
      toasts: [
        {
          type: "success",
          title: "Password",
          message: "Password has been updated",
          condition: actionTypes.UPDATE_USER_PASSWORD_SUCCESS,
        },
      ],
    },
  });
};
