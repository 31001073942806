import styled from "@emotion/styled";
import Flex from "../../../UI/Flex/Flex";
import Input from "../../../UI/Form/Input/Input";
import IOButton from "../../../UI/Form/Button/IOButton";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";

const NameInput = styled(Input)`
  margin-right: 10px;
  width: 350px;
`;

export default function TemplateTopSection(props) {
  const {
    activeTemplate,
    onAddNew,
    onTemlateInnerPropsChange,
    onSave,
    onDelete,
    printForm,
    printLoading,
  } = props;

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {activeTemplate ? (
        <Flex alignItems="center">
          <span>Name: </span>
          <NameInput
            onChange={(e) => onTemlateInnerPropsChange("name", e.target.value)}
            value={activeTemplate.name}
            inPlaceEdit
          />
        </Flex>
      ) : (
        <div></div>
      )}

      <Flex alignItems="center">
        {activeTemplate ? (
          <>
            <Checkbox
              label="Is Default"
              style={{ fontSize: 12, marginRight: 20 }}
              onChange={() =>
                onTemlateInnerPropsChange(
                  "isDefault",
                  !activeTemplate.isDefault
                )
              }
              checked={activeTemplate.isDefault}
            />

            <IOButton
              info
              smallPadding
              type="button"
              style={{ fontSize: 12, marginRight: 20 }}
              onClick={printForm}
              processing={printLoading}
              disabled={!activeTemplate.uuid}
            >
              Print Form
            </IOButton>
          </>
        ) : null}

        <IOButton
          add
          outline
          smallPadding
          type="button"
          style={{ fontSize: 12, marginRight: 20 }}
          onClick={onAddNew}
        >
          Add New
        </IOButton>

        {activeTemplate?.uuid ? (
          <IOButton
            smallPadding
            error
            type="button"
            style={{ fontSize: 12, marginRight: 20 }}
            onClick={onDelete}
          >
            Delete
          </IOButton>
        ) : null}

        <IOButton
          smallPadding
          type="button"
          style={{ fontSize: 12 }}
          disabled={!activeTemplate?.name || !activeTemplate?.content}
          onClick={onSave}
        >
          Save
        </IOButton>
      </Flex>
    </Flex>
  );
}
