import React, { useContext, useMemo } from "react";
import Select from "../../../../UI/Form/Select/Select";
import LoadExistingMessage from "../LoadExistingMessage";
import styled from "@emotion/styled";
import { scrollbar } from "../../../../styles/styledSnippets";
import SelectedQueryContext from "./SelectedQueryContext";
import useUserAccess from "../../../../hooks/useUserAccess";

const FancyScroll = styled.div`
  div {
    ${scrollbar}
  }
`;

const style = (initial) =>
  initial
    ? { width: 600, margin: "0 auto" }
    : {
        width: 300,
        position: "fixed",
        top: 22,
        left: 400,
        zIndex: 100,
        display: "none",
      };

export default function ExplorerQuerySelector(props) {
  const {
    initial,
    queries,
    showSavedList,
    setShowSavedList,
    handleQuerySelect,
  } = props;
  const { selectedQuery, setSelectedQuery } = useContext(SelectedQueryContext);

  const access = useUserAccess();

  const queryOptions = useMemo(() => {
    return queries
      .filter((q) => q.dataSources.length === 1)
      .filter((q) => q.dataSources.find((d) => d.isPrimary))
      .filter((q) => q.type !== "parameterized")
      .filter((q) => !q.other?.keyBy)
      .map(({ name, uuid }) => ({ label: name, value: uuid }));
  }, [queries]);

  const handleSetSelected = ({ value }) => {
    handleQuerySelect(value);
    setSelectedQuery(value);
  };

  return (
    <div style={style(initial)} id="select-query">
      {initial ? (
        <div style={{ fontSize: 36, margin: "100px 0 30px 0" }}>
          Select a query to explore...
        </div>
      ) : null}

      <FancyScroll data-cy="select-query">
        <Select
          placeholder="Select a query..."
          options={queryOptions}
          value={queryOptions.find((o) => o.value === selectedQuery)}
          onChange={handleSetSelected}
        />
      </FancyScroll>

      {access.dataExplorer && (
        <LoadExistingMessage
          onClick={() => setShowSavedList("list", true)}
          show={initial && !showSavedList}
        />
      )}
    </div>
  );
}
