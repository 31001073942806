// non strict compare filter value
function partialNoIndexEqual(rowIndexes, keyValue) {
  const minorMatchedKey = keyValue + "::";
  const [leftPart] = minorMatchedKey.split("::");

  // strict check but without index when filter change affect data length difference
  return rowIndexes?.find((item) => {
    const [withoutIndex] = (item || "").split("::");
    return withoutIndex === leftPart;
  });
}

// non strict compare filter value + row index
function partialEqual(rowIndexes, keyValue, tableRowIndex) {
  // const partialKey = keyValue + "::" + tableRowIndex;
  // const partialExistWithRowIndex = rowIndexes?.find((item) =>
  //   (item || "").includes(partialKey)
  // );

  return partialNoIndexEqual(rowIndexes, keyValue);
}

function isDrilldownKeyNull(key) {
  return key === null || key === "null";
}

// strict compare filter value + row index
export function rowIndexStrictComparison(rowIndexes, keyValue, tableRowIndex) {
  const strictKey = keyValue + "::" + tableRowIndex;
  const strictExist = rowIndexes?.find((item) => item === strictKey);

  // we have case when key is null in many rows
  if (isDrilldownKeyNull(keyValue)) {
    return strictExist;
  }

  return strictExist || partialEqual(rowIndexes, keyValue, tableRowIndex);
}

/**
 * Checks if a row should be excluded from drilldown based on given criteria.
 *
 * @param {Object} excludes - The exclusion criteria with `key` and `values`.
 * @param {Object} row - The row to be checked.
 * @returns {boolean} - Returns `true` if the row should be excluded, `false` otherwise.
 */
export function checkExcludeFromDrilldownRow(excludes, row) {
  const { key, values } = excludes ?? {};
  // Validate input parameters
  if (!key || !Array.isArray(values)) {
    return false;
  }

  // Check if any item in the row's values matches the exclusion criteria
  return (row?.values ?? []).some((item) => values.includes(item[key]));
}
