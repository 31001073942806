import React, { useMemo } from "react";
import { extent } from "d3-array";
import ArrowLine from "./ArrowLine";
import TrendLine from "./TrendLine";
import DifferenceBars from "./DifferenceBars";
import { calculateCAGR } from "./getDataReducer";

export default function PercentLine(props) {
  const { data, x, y, totalTrendType, valueKeys } = props;

  const alias = valueKeys?.[0].alias;

  const allValues = data.map((d) => d.first[alias]);
  // const allValues = data.map((d) => d.first[alias || d.xKey]);
  const [min, max] = extent(allValues);

  const difference = useMemo(() => {
    if (totalTrendType === "CARG") {
      return calculateCAGR(
        allValues[0],
        allValues[data.length - 1],
        data.length
      );
    }

    return (((max - min) / max) * 100).toFixed(1) + "%";
  }, [allValues, data.length, max, min, totalTrendType]);

  const middleX = x.range()[1] / 2;
  const bottom = y.range()[0] + 40;

  const differences = [];

  for (let i = 0; i < data.length - 1; i++) {
    const currentYear = data[i].xKey;
    const nextYear = data[i + 1].xKey;

    const currentValue = data[i].first[alias];
    const nextValue = data[i + 1].first[alias];

    const amountDifference = nextValue - currentValue;
    const percentDifference = (amountDifference / currentValue) * 100;

    differences.push({
      fromYear: currentYear,
      toYear: nextYear,
      fromValue: currentValue,
      toValue: nextValue,
      amountDifference: amountDifference.toFixed(2),
      percentDifference: percentDifference.toFixed(1),
    });
  }

  return (
    <g>
      <ArrowLine
        lineXStart={0}
        lineXEnd={x.range()[1] - 2}
        lineYPosition={bottom - 3}
      />
      <g
        style={{
          textAnchor: "middle",
          fontSize: 11,
          fill: difference?.errorMessage ? "red" : "green",
        }}
        transform={`translate(${middleX}, ${bottom})`}
      >
        <text>{difference?.errorMessage ?? difference}</text>
      </g>
      <TrendLine
        x1={x.range()[0] + x.bandwidth() / 2}
        x2={x.range()[1] - x.bandwidth() / 2}
        y1={y(min)}
        y2={y(max)}
      />
      <DifferenceBars differences={differences} x={x} y={y} />
    </g>
  );
}
