import { useTheme } from "emotion-theming";
import MonacoEditor from "@monaco-editor/react";
import styled from "@emotion/styled";
import { scrollbarDe } from "../../../styles/styledSnippets";

const Container = styled.div(
  ({ theme, isDisabled }) => `
  width: calc(100% - 300px);
  height: calc(100vh - 220px);
  border-radius: 5px;
  overflow-y: auto;
  ${scrollbarDe(theme)};
  ${
    isDisabled &&
    `
    pointer-events:none;
    opacity: 0.4;
    `
  }
`
);
export default function TemplateEditor({
  activeTemplate,
  onTemlateInnerPropsChange,
}) {
  const theme = useTheme();

  return (
    <Container isDisabled={!activeTemplate}>
      <MonacoEditor
        language="html"
        theme={theme.type === "dark" ? "vs-dark" : "light"}
        value={activeTemplate?.content}
        options={{ tabSize: 2 }}
        onChange={(value) => onTemlateInnerPropsChange("content", value)}
      />
    </Container>
  );
}
