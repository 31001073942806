import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../../../../../UI/Form/Select/Select";
import { Note } from "../LimitRows/LimitRows";
import { getBlendedQueryFieldLableWithAlias } from "../../Layout/helper";

export default function ZebraBackgroundGroupRows(props) {
  const { chart, fields, setChartState } = props;
  const { groupedRowBackgroundColumn } = chart;

  function setGroupedRowBackgroundColumn(option) {
    setChartState({
      ...chart,
      groupedRowBackgroundColumn: option?.name ?? null,
    });
  }

  return (
    <div>
      <br />
      <Select
        options={fields}
        getOptionLabel={(option) => getBlendedQueryFieldLableWithAlias(option)}
        getOptionValue={(option) => option.name}
        label="Grouped row background column name"
        simpleValue={groupedRowBackgroundColumn}
        isClearable
        onChange={setGroupedRowBackgroundColumn}
      />

      <Note color="infoDark">
        Group rows by background color based on a specific field.
        <FontAwesomeIcon icon={["fas", "info-circle"]} />
      </Note>
    </div>
  );
}
