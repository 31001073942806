import styled from "@emotion/styled";
import Switcher from "../../../../UI/Switcher/Switcher";
import Select from "../../../../UI/Form/Select/Select";
import { getRidOfAggregation } from "../../../../charts/TableView/Elements/EditableMenu";
import { getOverrideByName } from "../Layout/helper";
import { getAggregationPrefix } from "../Layout/Column/RegularColumn";
import Checkbox from "../../../../UI/Form/Checkbox/Checkbox";
import { useTheme } from "emotion-theming";
import { Note } from "../General/LimitRows/LimitRows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  padding: 10px;
`;

export default function Comments(props) {
  const { chart, setChartState, fields, queries, query } = props;
  const { commentCellConfiguration, overrides } = chart;
  const {
    columns,
    countColumn,
    tbl,
    cellIndicators = [],
  } = commentCellConfiguration ?? {};

  const theme = useTheme();

  const columnsOptionValues = (columns ?? []).map((column) => ({
    name: getRidOfAggregation(column, overrides),
  }));

  const commentIdicatorColumn = getRidOfAggregation(countColumn, overrides);
  const isCurrentTableName = query?.name === tbl;

  function enableCommentCellConfiguration() {
    setChartState({
      ...chart,
      commentCellConfiguration: commentCellConfiguration
        ? null
        : { columns: [] },
    });
  }

  function buildColumn({ name }) {
    const nonAggregated = getRidOfAggregation(name, overrides);
    const override = getOverrideByName(overrides, nonAggregated);
    return getAggregationPrefix(override?.aggregation) + name;
  }

  function onCommentColumnsChange(options) {
    setChartState({
      ...chart,
      commentCellConfiguration: {
        ...commentCellConfiguration,
        columns: options ? options.map(buildColumn) : [],
      },
    });
  }

  function onIndicatorColumnChange(option) {
    setChartState({
      ...chart,
      commentCellConfiguration: {
        ...commentCellConfiguration,
        countColumn: buildColumn(option),
      },
    });
  }

  function onTableNameChange(option) {
    setChartState({
      ...chart,
      commentCellConfiguration: {
        ...commentCellConfiguration,
        tbl: option.name,
      },
    });
  }

  function onCurrentTableNameChange() {
    setChartState({
      ...chart,
      commentCellConfiguration: {
        ...commentCellConfiguration,
        tbl: isCurrentTableName ? "" : query?.name,
      },
    });
  }

  function onSelectedCellIndicatorsChange(options) {
    setChartState({
      ...chart,
      commentCellConfiguration: {
        ...commentCellConfiguration,
        cellIndicators: options
          ? options.map((option) => buildColumn(option))
          : [],
      },
    });
  }

  return (
    <Container>
      <br />
      <div> Comment Configuration</div>
      <br />

      <Switcher
        rightLabel="Enable"
        checked={!!commentCellConfiguration}
        handleSwitchChange={enableCommentCellConfiguration}
      />

      {commentCellConfiguration ? (
        <>
          <br />

          <Select
            options={fields}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={columnsOptionValues}
            onChange={onCommentColumnsChange}
            label="Comment Columns"
            isMulti
          />

          <br />

          <Select
            options={fields}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            simpleValue={commentIdicatorColumn}
            onChange={onIndicatorColumnChange}
            label="Comments Indicator Column"
          />

          <br />

          <Select
            options={queries}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            simpleValue={tbl}
            onChange={onTableNameChange}
            label="Comments Table Name"
            isDisabled={isCurrentTableName}
          />

          <br />
          <Checkbox
            label="Use Current Table Name"
            fontSize={12}
            color={theme.text.secondary}
            checked={isCurrentTableName}
            onChange={onCurrentTableNameChange}
          />
          <br />
          <br />
          <Select
            options={fields}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={(cellIndicators ?? []).map((indicator) =>
              fields.find(
                (field) =>
                  field.name === getRidOfAggregation(indicator, overrides)
              )
            )}
            onChange={onSelectedCellIndicatorsChange}
            label="Selected Cell Indicators"
            isMulti
          />

          <Note color="infoDark">
            Make sure that the columns you are selecting exist and are visible
            in the table.
            <FontAwesomeIcon icon={["fas", "info-circle"]} />
          </Note>
        </>
      ) : null}
    </Container>
  );
}
