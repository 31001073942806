import React, { useMemo, useRef } from "react";
import Select from "../../../UI/Form/Select/Select";
import { getSingleFiscalQuarters } from "../../../utils/formatters/dateFormatter";

export default React.memo(function QuarterRange(props) {
  const {
    dateFiltersConfig,
    getPosition,
    RecentPeriods,
    recentMonthsChange,
    getUserSelectedDateOption,
    activeTabUuid,
    selectedDateType,
    saveStickyDatesToStore,
  } = props;

  const ref = useRef();

  const userSavedOption =
    getUserSelectedDateOption(dateFiltersConfig, activeTabUuid) ?? {};

  const { from, to } = userSavedOption;

  const options = useMemo(
    () => getSingleFiscalQuarters(dateFiltersConfig),
    [dateFiltersConfig]
  );

  function getValue(key) {
    return options.find((option) => option[key] === userSavedOption[key]);
  }

  // not show options which greater then end date
  const endIndex = options.findIndex((option) => option.to === to);
  const startOptions = options.filter((_, index) => index <= endIndex);

  // not show options which less then start date
  const startIndex = options.findIndex((option) => option.from === from);
  const endOptions = options.filter((_, index) => index >= startIndex);

  return (
    <RecentPeriods ref={ref}>
      <Select
        options={startOptions}
        onChange={(option) => {
          recentMonthsChange({ from: option.from, to });
          saveStickyDatesToStore(selectedDateType, { ...option, to });
        }}
        getOptionValue={(o) => o.from}
        placeholder="From"
        value={getValue("from")}
        menuPlacement={getPosition(ref)}
      />
      <br />
      <Select
        options={endOptions}
        onChange={(option) => {
          recentMonthsChange({ from, to: option.to });
          saveStickyDatesToStore(selectedDateType, { ...option, from });
        }}
        getOptionValue={(o) => o.from}
        placeholder="To"
        value={getValue("to")}
        menuPlacement={getPosition(ref)}
      />
    </RecentPeriods>
  );
});
