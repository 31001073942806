import api from "../../axios";
import { v4 as uuid } from "uuid";
import * as actionTypes from "./actionTypes";
import {
  MARK_BLOCKS_REFRESHING,
  CACHE_MENU_FILTER_CHECKED_VALUES,
} from "./actionTypes";
import {
  justCharts,
  loadRowExpandChart,
  loadDashboardConfiguration,
  executeQuery,
  setChartVisibility,
  initLoadCharts,
} from "./dashboard/dashboard";
import {
  FILTER_TYPE_BOOLEAN,
  FILTER_TYPE_DATE_PRESETS,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_MULTI_RANGE,
  FILTER_TYPE_RADIO,
  FILTER_TYPE_SINGLE_DATE,
  FILTER_TYPE_SINGLE_LIST,
  FILTER_TYPE_SLIDER,
  SELECT_ALL_FILTERS,
} from "../../utils/constants/constants";
import { handleError } from "../../utils/errorHandling";
import { headerKpisFormatConverter } from "../../utils/headerKpis";

export const loadLanding = () => (dispatch) => {
  return dispatch({
    type: actionTypes.LOAD_LANDING_SITE,
  });
};

export function loadHeaderConfiguration([
  dashboardName,
  visualizationId,
  forConfig,
]) {
  return async (dispatch, getState) => {
    const { layout } = getState();

    if (layout.tabs && layout.tabs.length) {
      dispatch({
        type: actionTypes.SET_ACTIVE_TAB,
        data: layout.tabs.find((tab) => tab.slug === dashboardName),
      });
      loadDashboardWhenNoHeaderKPIs();
    }

    function loadDashboardWhenNoHeaderKPIs() {
      if (!layout.headerVis) {
        return dispatch(
          loadDashboardConfiguration(dashboardName, visualizationId)
        );
      }
    }

    if (layout.headerVis) {
      // Do not load headers unnecessarily.
      if (getState().layout.header?.blocks.length) {
        dispatchHeaderConfigSuccess();
        return;
      }

      dispatch({ type: actionTypes.LOAD_HEADER_CONFIG_START });

      try {
        return await loadApiHeaderKpis();
      } catch (e) {
        handleError(e);
        throw e;
      }

      function dispatchHeaderConfigSuccess(localData, responseData) {
        const loadBundleId = uuid();
        if (localData) {
          dispatch({
            type: actionTypes.LOAD_HEADER_CONFIG_SUCCESS,
            results: localData,
            responseData,
          });
        }
        if (forConfig) {
          return;
        }
        dispatch(
          initLoadHeaderBlocks([loadBundleId, dashboardName, visualizationId])
        );
      }

      async function loadApiHeaderKpis() {
        return api.get(`/api/v1/header_kpis`).then((res) => {
          const data = headerKpisFormatConverter.response.toLocal(
            res.data.data
          );
          dispatchHeaderConfigSuccess(data, res.data.data);
        });
      }
    }
  };
}

export const initLoadHeaderBlocks =
  ([
    loadBundleId,
    dashboardName,
    visualizationId,
    filterSelect,
    resetFilters,
  ]) =>
  (dispatch, getState) => {
    const justCharts = getHeaderCharts(getState);

    dispatch(
      loadHeaderBlocks([
        loadBundleId,
        justCharts,
        dashboardName,
        visualizationId,
        filterSelect,
        resetFilters,
      ])
    );
  };

export const loadHeaderBlocks = (attrs) => (dispatch, getState) => {
  const [
    loadBundleId,
    remainingCharts,
    dashboardName,
    visualizationId,
    filterSelect,
    resetFilters,
  ] = attrs;
  const [current, ...remaining] = remainingCharts;
  const { filtersUpdateHeaderKPIs, activeTab } = getState().layout;
  const filtersOrReset = filterSelect ?? resetFilters;
  const ignoreAbortSignal = activeTab?.slug !== dashboardName;

  if (current) {
    dispatch(
      executeQuery(
        { ...current, ignoreAbortSignal },
        [
          loadBundleId,
          remaining,
          activeTab?.slug || dashboardName,
          visualizationId,
          filterSelect,
          resetFilters,
        ],
        loadHeaderBlocks,
        "layout",
        loadBundleId,
        false,
        true
      )
    );

    if (filtersUpdateHeaderKPIs) {
      const menuFilters = getState().layout.menuFilters || [];
      const activeMenuFilters = menuFilters
        .filter((mf) => mf.values.find((v) => v.checked))
        .map((mf) => mf.values[0].key);

      const visualizationIds = [
        current.visualizationId,
        ...remaining.map((r) => r.visualizationId),
      ];

      dispatch({
        type: actionTypes.SET_LOAD_HEADER_BUNDLE,
        visualizationIds,
        activeMenuFilters,
      });
    }

    if (filtersOrReset) {
      const charts = justCharts(getState);
      const loadBundleId = uuid();
      dispatch({ type: actionTypes.SET_LOAD_BUNDLE, loadBundleId, charts });
    }
  } else if (filtersOrReset) {
    dispatch(setChartVisibility());
  } else if (!resetFilters) {
    dispatch(
      loadDashboardConfiguration(
        dashboardName,
        visualizationId,
        false,
        loadBundleId
      )
    );
  }

  // when user switching tabs fast we need to drop headers refreshing prop
  // to get rid of infinity loading on them
  if (!current) {
    dispatch({
      type: actionTypes.SET_LOAD_HEADER_BUNDLE,
      visualizationIds: [],
    });
  }
};

export const hideSubmenu = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.HIDE_SUBMENU });
  };
};

// visualizationId need to reload only current chart if user change filter from details page
export function reloadCharts(visualizationId) {
  return (dispatch, getState) => {
    dispatch({ type: MARK_BLOCKS_REFRESHING });
    const { filtersUpdateHeaderKPIs, activeTab } = getState().layout;

    const loadBundleId = uuid();

    if (filtersUpdateHeaderKPIs && !visualizationId) {
      return dispatch(
        initLoadHeaderBlocks([loadBundleId, activeTab?.slug, null, true])
      );
    }

    dispatch(setChartVisibility(null, visualizationId));
  };
}

export const selectFilter =
  (
    filter,
    filterType = "dynamic",
    shouldReloadCharts = true,
    options = {},
    visualizationId
  ) =>
  (dispatch, getState) => {
    const singleQueryFiltersUuid = options.singleQueryFiltersUuid ?? null;
    const filterAdditions = { singleQueryFiltersUuid, filterType };
    switch (filterType) {
      case FILTER_TYPE_BOOLEAN: {
        dispatch({
          type: actionTypes.SET_BOOLEAN_FILTER,
          key: filter.key,
          checked: filter.checked,
          value: filter.value,
          hideOnPages: filter.hideOnPages,
        });
        break;
      }

      case FILTER_TYPE_SINGLE_LIST:
      case FILTER_TYPE_RADIO: {
        dispatch({
          type: actionTypes.SELECT_SINGLE_FILTER,
          filter,
        });

        break;
      }
      case FILTER_TYPE_SLIDER: {
        dispatch({ type: actionTypes.SELECT_SLIDER, filter });
        break;
      }

      case FILTER_TYPE_MULTI_RANGE: {
        dispatch({
          type: actionTypes.SELECT_MULTI_RANGE_FILTER,
          filter: {
            ...filter,
            conditional: true,
            value: "MIN_MAX_RANGE",
            ...filterAdditions,
          },
        });
        break;
      }

      case FILTER_TYPE_DATE_RANGE: {
        dispatch({
          type: actionTypes.SELECT_DATE_RANGE_FILTER,
          filters: filter,
          name: filter[0].key,
        });
        break;
      }

      case FILTER_TYPE_SINGLE_DATE: {
        const filters = convertFilterToArrayIfObject(filter);
        dispatch({
          type: actionTypes.SELECT_SINGLE_DATE_FILTER,
          filters,
          name: filters[0].key,
        });
        break;
      }

      case FILTER_TYPE_DATE_PRESETS: {
        const filters = convertFilterToArrayIfObject(filter);
        dispatch({
          type: actionTypes.SELECT_DATE_PRESETS_FILTER,
          filters,
          name: filters[0].key,
          ...filterAdditions,
        });
        break;
      }

      case SELECT_ALL_FILTERS:
        const filters = convertFilterToArrayIfObject(filter);
        dispatch({
          type: actionTypes.SELECT_ALL_GENERAL_FILTERS,
          filters,
          name: filters[0].key,
        });
        break;

      default: {
        dispatch({
          type: actionTypes.SELECT_FILTER,
          filter: { ...filter, checked: !filter.checked },
          ...filterAdditions,
        });
      }
    }

    if (shouldReloadCharts) {
      dispatch(reloadCharts(visualizationId));
    }

    runExecOnRowExpandedCharts(getState, dispatch);
  };

export const setFilters = (filters) => (dispatch) => {
  const ret = dispatch({
    type: actionTypes.SET_FILTER,
    filters,
  });
  dispatch(setChartVisibility());
  return ret;
};

export const setDateFilters =
  (dates, dateType, shouldReloadCharts = true, fromEffect) =>
  (dispatch, getState) => {
    const { filtersUpdateHeaderKPIs, activeTab } = getState().layout;
    const { blocks } = getState().dashboard;
    // This is a hack for Single Date Filter change mode that is triggering a refresh
    const dateFilterChange = !!blocks.length;

    dispatch({ type: MARK_BLOCKS_REFRESHING });

    const loadBundleId = uuid();

    if (filtersUpdateHeaderKPIs) {
      const ret = dispatch(applyDateFilters(dates, dateType));
      const headers = getHeaderCharts(getState);

      // do not load headers from any useEffect except main Dashboard component
      if (shouldReloadCharts && headers.length && !fromEffect) {
        dispatch(
          initLoadHeaderBlocks([
            loadBundleId,
            activeTab?.slug,
            null,
            dateFilterChange,
          ])
        );
      }

      return ret;
    } else {
      dispatch(applyDateFilters(dates, dateType));
      if (shouldReloadCharts) {
        dispatch(initLoadCharts());
      }
    }
  };

function applyDateFilters(dates, dateType) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_DATE_FILTERS,
      dateFilters: dates,
      dateType,
    });

    runExecOnRowExpandedCharts(getState, dispatch);
  };
}

export const setDateTerm = (term) => (dispatch, getState) => {
  const { filtersUpdateHeaderKPIs, activeTab } = getState().layout;

  const loadBundleId = uuid();

  if (filtersUpdateHeaderKPIs) {
    const ret = dispatch({
      type: actionTypes.SET_DATE_TERM,
      term,
    });
    dispatch(initLoadHeaderBlocks([loadBundleId, activeTab?.slug, null, true]));
    return ret;
  }

  const ret = dispatch({
    type: actionTypes.SET_DATE_TERM,
    term,
  });
  dispatch(initLoadCharts());
  return ret;
};

export const resetFilters = () => (dispatch, getState) => {
  const { filtersUpdateHeaderKPIs, activeTab } = getState().layout;

  const loadBundleId = uuid();

  if (filtersUpdateHeaderKPIs) {
    const ret = dispatch({
      type: actionTypes.RESET_FILTERS,
    });
    dispatch(
      initLoadHeaderBlocks([loadBundleId, activeTab.slug, null, null, true])
    );
    return ret;
  }

  dispatch({
    type: actionTypes.RESET_FILTERS,
  });
  dispatch(initLoadCharts());
};

export const updateTwoFactorAuthenticationRequired = (value) => ({
  type: actionTypes.UPDATE_TWO_FACTOR_AUTHENTICATION_REQUIRED,
  payload: value,
});

/**
 * Loads a shortlist of values for a menu filter which depends on the selection
 * of a "parent" menu filter.
 */
export function loadMenuFilterValues(menuFilterUuid, parentsValuesRef) {
  return {
    type: actionTypes.LOAD_MENU_FILTER_VALUES_START,
    payload: {
      menuFilterUuid,
      parentsValuesRef,
      parentsValues: parentsValuesRef.current,
    },
    meta: {
      api: {
        method: "POST",
        endpoint: `/api/v1/menu_filters/${menuFilterUuid}/values`,
        payload: { parentsValues: parentsValuesRef.current },
        toastOnFailure: true,
      },
    },
  };
}

export function resetMenuFilterValues(menuFilterUuid) {
  return {
    type: actionTypes.RESET_MENU_FILTER_VALUES,
    payload: menuFilterUuid,
  };
}

export function cacheMenuFilterSelectedValues(menuFilterUuid, values) {
  return {
    type: CACHE_MENU_FILTER_CHECKED_VALUES,
    payload: {
      menuFilterUuid,
      values,
    },
  };
}

export const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ACTIVE_TAB,
    data: tab,
  });
};

export const extendMenuFilters = (filters, veryBy) => async (dispatch) => {
  await dispatch({ type: actionTypes.EXTEND_MENU_FILTERS, filters });

  if (veryBy) {
    await dispatch(reloadCharts());
  }
};

export const changeComparisonType = (comparisonModeItem) => {
  return {
    type: actionTypes.CHANGE_COMPARISON_TYPE,
    comparisonModeItem,
  };
};

export const setSelectedComparisonModeFilterName = (name) => {
  return {
    type: actionTypes.CHANGE_COMPARISON_MODE_FILTER_NAME,
    name,
  };
};

// Set date types (ytd, mtd, etc)
export const setCurrentDateFilterTerm = (term) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_CURRENT_DATE_FILTER_TERM, term });
};

export const setGlobalStickyHeaders = (isSticky) => (dispatch) => {
  dispatch({ type: actionTypes.SET_FULL_PAGE_STICKY_HEADERS, isSticky });
};

export const selectAllComparisonFilters =
  (key, checked, count, shouldReloadCharts = true) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.SELECT_ALL_COMPARISON_FILTERS,
      key,
      checked,
      count,
    });

    if (shouldReloadCharts) {
      dispatch(reloadCharts());
    }

    runExecOnRowExpandedCharts(getState, dispatch);
  };

export const saveSelectedDatePresets = (customDateKeyValue) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_SELECTED_DATE_PESETS_FILTER_MAP,
    customDateKeyValue,
  });
};

export const getMenuFilterValues =
  (filter, searchTerm = "", page = 1, perPage = 1000) =>
  async (dispatch) => {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.set("searchTerm", searchTerm);
    }

    params.set("page", page);
    params.set("perPage", perPage);

    const endpoint = `api/v1/menu_filters/${
      filter.uuid
    }/values?${params.toString()}`;

    dispatch({
      type: actionTypes.GET_MENU_FILTER_VALUES_START,
      meta: {
        api: {
          method: "GET",
          endpoint,
          toastOnFailure: true,
        },
      },
      filter,
    });
  };

export const saveStickyDateType = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_STICKY_DATE_TYPE,
    ...params,
  });
};

function convertFilterToArrayIfObject(filter) {
  return Array.isArray(filter) ? filter : [filter];
}

// related to localRowExpandGrouping on multiple charts on same page with same unique value key from api
function runExecOnRowExpandedCharts(getState, dispatch) {
  // Extract rowExpandedCharts from the dashboard state
  const { rowExpandedCharts } = getState().dashboard;

  // Iterate over each key in the rowExpandedCharts object
  Object.entries(rowExpandedCharts).forEach(([key, chart]) => {
    // Check if the chart has a queryId and dispatch the action directly
    if (chart.queryId) {
      dispatch(loadRowExpandChart(null, chart));
    } else {
      // If no queryId, assume the chart contains nested queries and dispatch actions for each
      Object.values(chart).forEach((query) => {
        dispatch(loadRowExpandChart(null, query));
      });
    }
  });
}

function getHeaderCharts(getState) {
  return getState()
    .layout.header.blocks.reduce((acc, curr) => [...acc, ...curr.charts], [])
    .filter((c) => c.queryId);
}
