import DatePickerContainer from "../../../UI/DatePicker/DatePickerContainer";
import { format, parseISO, subDays } from "date-fns";
import { dateConfig } from "../../../utils/formatters/dateFormatter";
import { getUserSelectedDateOption } from "./customDateUtils";

export function getDefaultSingleDay() {
  const date = parseISO(dateConfig.defaultDateString);
  return format(subDays(date, 1), "yyyy-MM-dd");
}

export default function SingleDay(props) {
  const {
    Container,
    recentMonthsChange,
    theme,
    activeTabUuid,
    selectedDateType,
    saveStickyDatesToStore,
    dateFiltersConfig,
  } = props;

  const { from } =
    getUserSelectedDateOption(dateFiltersConfig, activeTabUuid) ?? {};

  function setSingleDay(e) {
    const day = e.target.value;
    recentMonthsChange({ from: day, to: day });
    saveStickyDatesToStore(selectedDateType, { from: day, to: day });
  }

  return (
    <Container>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          onChange={setSingleDay}
          value={from}
          data-cy="single-day-input"
        />
      </DatePickerContainer>
    </Container>
  );
}
