import React from "react";
import { scaleLinear, scaleTime, scaleBand } from "d3-scale";
import { extent } from "d3-array";
import PositionCirclesPlaced from "../PositionCircle/PositionCirclesPlaced";
import YAxis from "../Axes/YAxis";
import { useTheme } from "emotion-theming";
import SpringLine from "./SpringLine";
import { linear } from "regression";
import { getMappingType } from "../../utils/getVisualizationLabel";

import timeSeriesValues, {
  getSortedXAxisValues,
  months,
} from "./timeSeriesValues";
import { percentFormats } from "../../utils/constants/constants";
import SimpleXAxis from "../SimpleXAxis";

export const TREND_LINE_COLOR = "orange";

export default function MultipleLineChart({
  width,
  height,
  setTooltip,
  colors,
  xKeyFormat,
  lineGroups,
  meta,
  trendLine,
  xKey,
  valueKeys: propsValueKeys,
  yAxisFormat,
  yAxisPad = 10,
  showCircles,
  lineLabelFormat,
  lineLabelPrecision,
  lineLabelUnit,
  relativeY,
  bands,
  yRightAxisFormat,
  yRightAxis,
  showLabel,
  yearOverYear,
  yAxisTypeLabel,
  yRightAxisTypeLabel,
  activeValueKey,
  xKeyDomain,
  tooltipForceType,
  QO,
  tooltipDateFormat,
  fiscalQuarterStartOffset,
  fixedYTickStep,
  labelFormat,
  showFancyWeeks,
  term,
  xSortSettings, // Forcing this currently, can be overridden (? where do we override)
  data,
  yClampedRange,
  rawData,
  rawValueKeys,
  groupBy,
  padZero,
  dateFilters,
  xAxisFormat,
}) {
  const valueKeys = propsValueKeys.map((v) => v?.alias || v);

  const theme = useTheme();
  const allValues = timeSeriesValues(lineGroups, yearOverYear);

  const customDomain = months(xKeyDomain);
  const dateExtent = extent(allValues.dates);

  const format = () =>
    yAxisFormat || getMappingType(meta?.fields, activeValueKey || valueKeys[0]);
  const isPercentFormat = () =>
    percentFormats.includes(format()) || yAxisFormat?.includes("%");

  const sorted = getSortedXAxisValues(
    xSortSettings,
    allValues.dates,
    data,
    xKey,
    meta,
    dateFilters
  );

  const x = bands
    ? scaleBand()
        .domain(customDomain || sorted)
        .range([0, width])
    : scaleTime()
        .domain(dateExtent)
        .range([0, width - 3]);

  function setFullRange(values) {
    const boundaryExpander = isPercentFormat() ? 0.02 : 2;
    const rangeSpread = values[1] - values[0];
    const padAmount = rangeSpread * yAxisPad;
    values[0] = values[0] - padAmount;
    values[1] = values[1] + padAmount;

    return [
      values[0] < 0 || relativeY ? values[0] - boundaryExpander : 0,
      values[1] + (relativeY ? boundaryExpander : 0),
    ];
  }

  const allValuesForDomain = rawData.reduce((acc, curr) => {
    return [
      ...acc,
      ...valueKeys
        .map((v) => curr[v]) // Map the current keys to their values
        .filter((value) => typeof +value === "number" && !isNaN(+value)), // Ensure values are numbers
    ];
  }, []);

  const maxMinValues = {
    max: Math.max(...allValuesForDomain),
    min: Math.min(...allValuesForDomain),
  };
  const paddingAmount = (maxMinValues.max - maxMinValues.min) * 0.2;
  let yDomainMin = 0;
  if (relativeY && maxMinValues.min - paddingAmount > 0) {
    yDomainMin = maxMinValues.min - paddingAmount;
  } else if (maxMinValues.min < 0) {
    yDomainMin = maxMinValues.min - paddingAmount;
  }
  const yDomain = [yDomainMin, maxMinValues.max + paddingAmount];

  const yRightDomain = setFullRange(
    extent(allValues.rightAxisValues.flat(Infinity))
  );

  const ry = scaleLinear()
    .domain(yRightDomain)
    .range([height - 20, 0]);

  const clampedDomain = () => {
    if (!yClampedRange) {
      return yDomain;
    }

    return [
      Math.min(...allValues.values) - yClampedRange.min,
      Math.max(...allValues.values) * 0.5 - yClampedRange.max,
    ];
  };

  const y = scaleLinear().domain(clampedDomain()).range([height, 0]);

  const allPositions = lineGroups.length
    ? lineGroups[0].values.map((v) => {
        return [x(yearOverYear ? v.scaleDate : v.xValue), y(v.value)];
      })
    : [];

  const coefficients = linear(allPositions);

  const isRightAxis = (section) => {
    return section.rightAxisValues?.length;
  };

  return (
    <g data-cy="multiple-lines">
      <SimpleXAxis
        width={width + 15}
        height={height}
        xScale={x}
        values={x.domain()}
        xKeyFormat={xAxisFormat}
        bands={true}
        allTicks
      />
      <g>
        <YAxis
          yScale={y}
          yAxisGridColor={theme.divider}
          yAxisGrid
          yTicksCount={6}
          width={width}
          hideYAxisLine
          yAxisFormat={
            yAxisFormat ||
            getMappingType(meta?.fields, activeValueKey || valueKeys[0])
          }
          yAxisTypeLabel={yAxisTypeLabel}
          domain={fixedYTickStep ? yDomain : null}
        />
      </g>

      {yRightAxis && (
        <YAxis
          yScale={ry}
          translateAxis={width + 10}
          width={0}
          yAxisGridColor={theme.divider}
          yAxisGrid
          yTicksCount={6}
          hideYAxisLine
          yAxisFormat={yRightAxisFormat}
          orient="right"
          domain={yRightDomain}
          yAxisTypeLabel={yRightAxisTypeLabel}
          translateRightLabel={width + 40}
        />
      )}
      {lineGroups.map((section, i) => (
        <g data-cy="multiple-lines-line" key={i}>
          <SpringLine
            width={width}
            values={rawData}
            stroke={colors[i]?.color}
            style={section.dashed ? { strokeDasharray: "5, 8" } : {}}
            curved={section.curved}
            xKey={xKey}
            yKey={section.key}
            strokeWidth={1.5}
            fill="none"
            x={x}
            y={isRightAxis(section) ? ry : y}
            // bandwidth={bands ? x.bandwidth() / 2 : 0}
            xOffset={x.bandwidth() / 2}
            padZero={padZero}
          />
          {showCircles ? (
            <g data-cy="circles">
              <PositionCirclesPlaced
                data={rawData}
                markerKey="insight"
                yScale={isRightAxis(section) ? ry : y}
                xScale={x}
                xKey={xKey}
                rootXKey={xKey}
                yKey={section.key}
                xLabel={xKey}
                yLabel={
                  QO
                    ? QO.sourceKey(valueKeys[i] || valueKeys[0])
                    : valueKeys[i] || valueKeys[0]
                }
                segment={section.key}
                color={colors[i]?.color}
                setTooltip={setTooltip}
                borderSize={0.5}
                radius={3}
                meta={meta}
                lineLabelFormat={lineLabelFormat}
                lineLabelPrecision={lineLabelPrecision}
                lineLabelUnit={lineLabelUnit}
                showLabel={showLabel}
                lineBands={bands}
                activeValueKey={activeValueKey}
                noDate={!!showFancyWeeks}
                tooltipForceType={tooltipForceType}
                tooltipDateFormat={tooltipDateFormat ?? term}
                fiscalQuarterStartOffset={fiscalQuarterStartOffset}
                // xOffset={showLabel ? 15 : 0}
                labelFormat={labelFormat}
                groupBy={groupBy}
                valueKeys={rawValueKeys}
              />
            </g>
          ) : null}
        </g>
      ))}
      {trendLine
        ? coefficients.points.map((c, i) => (
            <circle key={i} r={2} cx={c[0]} cy={c[1]} fill={TREND_LINE_COLOR} />
          ))
        : null}
    </g>
  );
}
