import React, { useState } from "react";
import MultipleLineChart from "./MultipleLineChart/MultipleLineChart";
import Details from "../Layout/Details/Details";

import {
  assignAsKey,
  groupByKey,
  pipe,
  dateSort,
  groupUnder,
  doIfElse,
  doIf,
  addScaleDate,
  switchAxes,
  unique,
} from "../utils/func";
import VisualizationBase from "./BaseChart/VisualizationBase";
import MarkerToggleSwitch from "./LineChart/MarkerToggleSwitch";
import { chartSectionsLimit } from "../utils/constants/constants";
import { orderBy } from "lodash-es";
import { toDateType } from "../utils/dates/dateFunc";
import ChartActionsToggle from "./ChartActions/ChartActionsToggle";
import styled from "@emotion/styled";
import mobileBreakpoints from "../styles/mobileBreakpoints";
import Legend from "./Legend/Legend";
import mapColorConfig from "./BaseChart/mapColorConfig";
import mapAxisSettings from "./BaseChart/mapAxisSettings";
import { getXAxisFormat } from "../utils/charts/xKeyParser";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    padding-top: 35px;
  }
`;

const hasAttr = (attrs, key) => attrs && attrs.find((a) => a === key);

const LineVisualization = (props) => {
  const { chart, details, dashboardId, QO, term } = props;

  const {
    data,
    xKey,
    xKeyFormat,
    xTickCount,
    valueKeys: propsValueKeys,
    dashedKeys,
    curvedKeys,
    meta,
    xDateKey,
    groupBy,
    legendItems,
    trendLine,
    dateParserStyle,
    dateParserYearKey,
    yAxisFormat,
    yAxisPad,
    yAxisActualRange,
    lineLabelFormat,
    lineLabelPrecision,
    legendSize,
    defaultColors,
    splitedDateFormat,
    legendLinkKey,
    lineLabelUnit,
    relativeY,
    xAxisDate,
    hideLegend,
    hideCircles,
    yRightAxis,
    yRightAxisFormat,
    valueKeysRightAxis = [],
    showLabel,
    yearOverYear,
    yAxisTypeLabel,
    yRightAxisTypeLabel,
    disableLegendSizeOrder,
    variableValueKeys,
    showQuarterShade,
    showFancyWeeks,
    xKeyDomain,
    formatBands,
    tooltipForceType,
    tooltipDateFormat,
    fiscalQuarterStartOffset,
    fixedYTickStep,
    labelFormat,
    forceRotateLabels,
    ignoreDateTerm,
    rightAxisGroupByValues,
    legendHeight,
    withNegative,
    chartSectionsLimitOverride,
    tooltipConfig,
    padZero,
    colors,
  } = chart;

  const [showCircles, setShowCircles] = useState(!hideCircles);
  const newBands = true;

  const [activeValueKey, setActiveValueKey] = useState(
    groupBy
      ? propsValueKeys?.[0]?.alias
      : variableValueKeys?.length && variableValueKeys[0]
  );

  let valueKeys = propsValueKeys;
  let rawData = data;
  if (groupBy) {
    const allXKeys = unique(data.map((d) => d[xKey]));
    const allYKeys = unique(data.map((d) => d[groupBy]));
    rawData = allXKeys.map((xKeyValue) => {
      const matchedRows = data.filter((d) => d[xKey] === xKeyValue);
      const mergedValueRows = matchedRows.reduce((acc, curr) => {
        return { ...acc, [curr[groupBy]]: curr[valueKeys[0].alias] };
      }, {});
      return { [xKey]: xKeyValue, ...mergedValueRows };
    });
    valueKeys = allYKeys;
  }

  const dated = filterOutW();
  const chartSectionsCount = chartSectionsLimitOverride || chartSectionsLimit;

  function filterNegatives(item) {
    const allNonNegative = !Object.values(item).some(
      (val) => !isNaN(+val) && +val < 0
    );
    return withNegative || allNonNegative;
  }

  function filterOutW() {
    return data.filter((d) => d[xKey] !== " W").filter(filterNegatives);
  }

  const valueKeysAsStrings = valueKeys && valueKeys.map((v) => v?.alias || v);

  const initialLineGroups = pipe(
    assignAsKey(xKey, "xValue"),
    doIf(!newBands, toDateType("xValue", dateParserStyle, dateParserYearKey)),
    doIf(yearOverYear, addScaleDate()),
    dateSort("xValue"),
    doIf(
      groupBy,
      assignAsKey(activeValueKey?.valueKey || valueKeysAsStrings[0], "value")
    ),
    doIfElse(
      groupBy,
      groupByKey(groupBy),
      groupUnder(valueKeysAsStrings, valueKeysRightAxis)
    )
  )(dated);

  const preFormulaLineGroup = switchAxes(
    initialLineGroups,
    rightAxisGroupByValues
  ).map((l) => ({
    ...l,
    curved: hasAttr(curvedKeys, l.key),
    dashed: hasAttr(dashedKeys, l.key),
    link: l[legendLinkKey],
    values: (l.values ?? []).map((v) => ({ ...v, value: +v.value })),
    sum: (l.values ?? []).reduce((acc, curr) => acc + (curr.value ?? 0), 0),
    rightAxisValues: l.rightAxisValues?.map((v) => ({
      ...v,
      value: +v.value,
    })), // if has right Y axis
  }));

  const ordered = disableLegendSizeOrder
    ? preFormulaLineGroup
    : orderBy(preFormulaLineGroup, "summ", "desc");

  const chartMargin = {
    top: 30,
    left: 52,
    right: yRightAxis ? 80 : 20,
    bottom: 62,
  };

  // @Farhod, what's this?
  // do not draw line chart with wrong not number values
  // if (!activeValueKey?.valueKey && !valueKeys.length) {
  //   return null;
  // }

  const sections = ordered.slice(0, chartSectionsCount).map((k) => k.key);
  const colorArray = mapColorConfig(sections, legendItems, null, null, colors);
  const { yLabelText } = mapAxisSettings(
    groupBy,
    propsValueKeys,
    yAxisTypeLabel,
    activeValueKey,
    meta,
    "line"
  );

  const xAxisFormat = getXAxisFormat(xKeyFormat, xKey, meta);

  return (
    <>
      <Container id={chart.visualizationId} offset-height="true">
        <VisualizationBase
          {...{ ...props, margin: chartMargin }}
          tooltipConfig={tooltipConfig}
        >
          <MultipleLineChart
            {...chart}
            lineGroups={ordered.slice(0, chartSectionsCount)}
            xAxisFormat={xAxisFormat}
            legendSize={legendSize}
            colors={colorArray}
            markers
            sectionKey="segment"
            xKeyFormat={xKeyFormat}
            tickCount={xTickCount || 12}
            isLinear
            xKey={xKey}
            xDateKey={xDateKey}
            valueKeys={valueKeys}
            dashedKeys={dashedKeys}
            curvedKeys={curvedKeys}
            meta={meta}
            QO={QO}
            trendLine={trendLine}
            yAxisFormat={yAxisFormat}
            yAxisPad={yAxisPad}
            yAxisActualRange={yAxisActualRange}
            showCircles={showCircles}
            defaultColors={defaultColors}
            splitedDateFormat={splitedDateFormat}
            lineLabelFormat={lineLabelFormat}
            lineLabelPrecision={lineLabelPrecision}
            lineLabelUnit={lineLabelUnit}
            relativeY={relativeY}
            xAxisDate={xAxisDate}
            bands
            yRightAxis={yRightAxis}
            yRightAxisFormat={yRightAxisFormat}
            showLabel={showLabel}
            yearOverYear={yearOverYear}
            yAxisTypeLabel={yLabelText}
            yRightAxisTypeLabel={yRightAxisTypeLabel}
            showQuarterShade={showQuarterShade}
            showFancyWeeks={showFancyWeeks}
            activeValueKey={activeValueKey?.valueKey || valueKeys[0]}
            xKeyDomain={xKeyDomain}
            formatBands={formatBands}
            dateParserStyle={dateParserStyle}
            tooltipForceType={tooltipForceType}
            tooltipDateFormat={tooltipDateFormat}
            fiscalQuarterStartOffset={fiscalQuarterStartOffset}
            fixedYTickStep={fixedYTickStep}
            labelFormat={labelFormat}
            forceRotateLabels={forceRotateLabels}
            term={ignoreDateTerm ? null : term}
            groupBy={groupBy}
            rawValueKeys={propsValueKeys}
            rawData={rawData}
            padZero={!!padZero}
          />
        </VisualizationBase>
        <Legend
          colors={colorArray}
          horizontal
          sections={sections}
          wrapping
          meta={meta}
          legendHeight={legendHeight}
          hide={hideLegend}
        />
        <MarkerToggleSwitch
          onClick={() => setShowCircles(!showCircles)}
          active={showCircles}
        />
        <ChartActionsToggle
          variableValueKeys={variableValueKeys}
          setVariableValueKey={setActiveValueKey}
          activeValueKey={activeValueKey}
        />
      </Container>
      {details ? (
        <Details
          dashboardName={dashboardId}
          visualizationId={chart.visualizationId}
          marginTop={0}
          chart={chart}
        />
      ) : null}
    </>
  );
};

export default React.memo(LineVisualization);
