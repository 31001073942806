import * as actionTypes from "./actionTypes";
import queryBuilder from "./queryBuilder/queryBuilder";
import * as queryString from "qs";

export function getQueryHtmlTemplates(queryUuid) {
  return {
    type: actionTypes.GET_QUERY_HTML_TEMPLATES_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/queries/${queryUuid}/export-templates`,
        toastOnFailure: true,
      },
    },
  };
}

export function saveQueryHtmlTemplate(params) {
  const { uuid, queryUuid, name, isDefault, content } = params;

  return {
    type: uuid
      ? actionTypes.UPDATE_QUERY_HTML_TEMPLATES_START
      : actionTypes.CREATE_QUERY_HTML_TEMPLATES_START,
    meta: {
      api: {
        method: uuid ? "PUT" : "POST",
        endpoint: `api/v1/queries/${queryUuid}/export-templates/${
          uuid ? uuid : ""
        }`,
        toastOnFailure: true,
        payload: {
          name,
          isDefault,
          content,
        },
      },
      toasts: [
        {
          type: "success",
          title: "Template!",
          message: `Template ${uuid ? "updated" : "added"}.`,
          condition: uuid
            ? actionTypes.UPDATE_QUERY_HTML_TEMPLATES_SUCCESS
            : actionTypes.CREATE_QUERY_HTML_TEMPLATES_SUCCESS,
        },
      ],
    },
  };
}

export function deleteQueryHtmlTemplate(queryUuid, templateUuid) {
  return {
    type: actionTypes.DELETE_QUERY_HTML_TEMPLATES_START,
    meta: {
      api: {
        method: "DELETE",
        endpoint: `api/v1/queries/${queryUuid}/export-templates/${templateUuid}`,
        toastOnFailure: true,
      },
    },
    toasts: [
      {
        type: "success",
        title: "Template!",
        message: `Template deleted.`,
        condition: actionTypes.DELETE_QUERY_HTML_TEMPLATES_SUCCESS,
      },
    ],
    templateUuid,
  };
}

export function printQueryHtmlTemplateForm({ queryUuid, templateName, chart }) {
  return async (dispatch, getState) => {
    const qs = queryBuilder(chart, getState, false, "pdf-template");

    dispatch({
      type: actionTypes.PRINT_QUERY_HTML_TEMPLATE_FORM_START,
      meta: {
        api: {
          method: "POST",
          endpoint: `api/v1/queries/${queryUuid}/exec`,
          toastOnFailure: true,
          payload: queryString.parse(qs, { depth: 10 }),
          format: ".pdf",
          file: true,
          fileName: templateName,
        },
      },
    });
  };
}
