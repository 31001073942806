import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faSigma, faLink } from "@fortawesome/pro-solid-svg-icons";
import { colors } from "../constants";

function background(props) {
  const { active, theme, visible } = props;
  if (visible && !active) return colors.visible;
  return active
    ? theme.notification.infoMain
    : theme.type === "dark"
    ? theme.gray.gray700
    : theme.gray.gray500;
}

const ColumnTile = styled.div`
  background: ${background};
  color: white;
  padding: 6px 8px 6px 8px;
  margin-right: 6px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  position: relative;
  font-size: 12px;
  white-space: nowrap;

  svg {
    margin-left: 0.5rem;
  }
`;

export default function DraggableColumnTile(props) {
  const {
    provided,
    handleSetActive,
    active,
    col,
    maxChars,
    dragging,
    joinColumn,
  } = props;

  const isJoinColumn = col.field === joinColumn;
  let title = `${col.field} | ${col.type}${
    isJoinColumn ? " (unique field)" : ""
  }`;

  return (
    <ColumnTile
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={() => handleSetActive(col.colId)}
      active={active(col)}
      data-cy={dragging ? "dragging-column-tile" : "column-tile"}
      data-active={active(col)}
      maxChars={maxChars}
      visible={col.visible}
      editable={col.editable}
      title={title}
    >
      {col.displayName}

      {isJoinColumn ? (
        <FontAwesomeIcon icon={faLink} fixedWidth />
      ) : col.aggregationType ? (
        <FontAwesomeIcon icon={faSigma} fixedWidth />
      ) : col.editable ? (
        <FontAwesomeIcon icon={faPencil} fixedWidth />
      ) : null}
    </ColumnTile>
  );
}
