export default function determineTickFormat(yFormat, yDomain) {
  const [min, max] = yDomain;
  const range = max - min;

  // Handle custom format strings first
  if (/percent-whole/.test(yFormat)) {
    return { format: "percent-round", tickCount: 6 };
  }

  if (/percent|%/.test(yFormat)) {
    if (range < 0.01) return { format: ".2%", tickCount: 10 };
    if (range < 0.08) return { format: ".1%", tickCount: 10 };
    if (range <= 2.2) return { format: ".0%", tickCount: 6 }; // No decimals for percentages within the 0-1 range
    if (range < 100) return { format: ",%", tickCount: 6 };
    return { format: ".2s", tickCount: 6 };
  }

  if (/currency|\$/.test(yFormat)) {
    if (range < 0.01) return "$.3f"; // Extra precision for very small currency ranges
    if (range < 1.2) return { format: "$.1f", tickCount: 6 };
    if (range < 100) return { format: "$.0f", tickCount: 6 }; // Fine-grained for small currency values
    return { format: "$.2s", tickCount: 6 }; // Scaled for larger values
  }

  if (range < 0.01) return ".3f"; // Extra precision for very small currency ranges
  if (range < 0.6) return { format: ".2f", tickCount: 6 };
  if (range < 5) return { format: ".1f", tickCount: 10 };
  if (range < 20) return { format: ".0f", tickCount: 6 };
  if (range < 500) return { format: ".0f", tickCount: 10 }; // Fine-grained for small currency values
  if (range < 5000) return { format: ",.0f", tickCount: 6 };
  if (range < 10000) return { format: ".3s", tickCount: 6 };
  if (range < 1000000) return { format: ".2s", tickCount: 5 };
  return { format: ".2s", tickCount: 4 };
}
