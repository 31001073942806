import React, { Fragment, useCallback } from "react";
import { useTheme } from "emotion-theming";
import { scaleBand, scaleLinear, scaleOrdinal } from "d3-scale";
import { schemeTableau10 } from "d3-scale-chromatic";
import { extent } from "d3-array";
import YAxis from "../Axes/YAxis";
import SpringBar from "../GroupedBar/SpringBar";
import { mapping } from "../../utils/getVisualizationLabel";
import { labelFormatter, formatter } from "../../utils/formatters/formatter";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import SpringGroup from "../GroupedBar/SpringGroup";
import SpringLine from "../MultipleLineChart/SpringLine";
import PositionCirclesPlaced from "../PositionCircle/PositionCirclesPlaced";
import SimpleXAxis from "../SimpleXAxis";

export default function LineBarChart(props) {
  const {
    width,
    xKey,
    data,
    height,
    yAxisFormat = "$.2s",
    xAxisFormat,
    barValueKey,
    setTooltip,
    meta,
    colors,
    labelFormat,
    lineGroups,
    showCircles,
    lineValueKeys = [],
    defaultColors,
    yRightAxis,
    yRightAxisFormat,
    offsetWidth = 0,
    legendItems,
    xAxisDate,
    dynamicTicks,
    tooltipDateFormat,
    bands,
    barValueKeys,
    xKeys,
    noDateObject,
    valuePadding: paddingValue,
    targetBarValueKey,
  } = props;

  const valuePadding = paddingValue || 1.05;
  const theme = useTheme();
  let xValues = [];
  const hasManyXKeys = Array.isArray(xKeys);
  const hasManyBarKeys = Array.isArray(barValueKeys);

  if (hasManyXKeys) {
    xValues = xKeys;
  } else if (xKey) {
    xValues = data.map((d) => d[xKey]);
  }

  function multipleBarValues(item) {
    if (hasManyBarKeys) {
      return barValueKeys.map((bvk) => +item[bvk]);
    }

    return [+item[barValueKey], +item[targetBarValueKey] || 0];
  }

  const allBarValues = data.reduce((acc, curr) => {
    return [...acc, multipleBarValues(curr)].flat();
  }, []);

  const allLineValues = data.reduce(
    (acc, curr) => [...acc, +curr[lineValueKeys[0]]],
    []
  );

  function getScale() {
    return scaleBand()
      .domain(hasManyBarKeys ? barValueKeys : xValues)
      .rangeRound([0, width])
      .paddingInner(0.3)
      .paddingOuter(0.1);
  }

  const x = getScale();

  const barDomain = extent([...allBarValues, 0]).map((d, i) =>
    i === 1 ? d * valuePadding : d
  );

  const isAllZeros = allLineValues.every((v) => v === 0);
  const indexes = Array.from({ length: 10 }, (_, i) => i + 1);

  const lineDomain = extent([...(isAllZeros ? indexes : allLineValues), 0]).map(
    (d, i) => (i === 1 ? d * valuePadding : d)
  );

  const rightYAxis = scaleLinear().domain(lineDomain).range([height, 0]);
  const leftYAxis = scaleLinear()
    .domain([0, extent(allBarValues)[1]])
    .range([height, 0]);

  const divider = targetBarValueKey ? 3 : 2;

  const xWidth = x.bandwidth() / divider;
  const autoFillColor = scaleOrdinal(schemeTableau10);
  const xOffset = xWidth;

  const xPos = useCallback(
    (d, i, key) => {
      if (key) {
        return x(key) + xWidth / 2;
      }
      return x(xKey ? d[xKey] : i) + xWidth / 2;
    },
    [x, xKey, xWidth]
  );

  const { isMobile } = mobileBreakpoints;
  const noSpace = width / (data.length || 1) < 20;
  const rotateDeg = isMobile || noSpace ? "-90deg" : "0deg";

  const formatBarLabel = useCallback(
    (value) => {
      if (labelFormat) {
        return labelFormatter(value, labelFormat);
      }

      return formatter(value, yAxisFormat);
    },
    [labelFormat, yAxisFormat]
  );

  const getColor = useCallback(
    (section, i) => {
      const matched = legendItems?.find((li) => {
        if (hasManyBarKeys) {
          return li.names.includes(section.key);
        }
        return li.name === section.key;
      });

      if (matched?.color) {
        return matched.color;
      }

      return defaultColors || !colors ? autoFillColor(i + 1) : colors[i + 1];
    },
    [autoFillColor, colors, defaultColors, hasManyBarKeys, legendItems]
  );

  const labelSize = xWidth + x.paddingInner() * 10 < 10 ? 8 : 10;
  const y = yRightAxis ? rightYAxis : leftYAxis;

  const renderBars = useCallback(
    (d, i, barValueKey, targetBarOffset = 0) => {
      if (d[barValueKey] === null) {
        return null;
      }

      return (
        <Fragment key={i}>
          <SpringBar
            data-cy="grouped-bar-bar"
            startPos={leftYAxis(0)}
            y={leftYAxis(d[barValueKey])}
            x={xPos(d, i, hasManyXKeys ? barValueKey : null) + targetBarOffset}
            height={height - leftYAxis(d[barValueKey])}
            width={xWidth}
            chartNum={i}
            color={getColor({ key: barValueKey }, -1)}
            onMouseOver={() =>
              setTooltip({
                x:
                  xPos(d, i, hasManyXKeys ? barValueKey : null, true) +
                  xWidth / 2 +
                  targetBarOffset,
                y: leftYAxis(d[barValueKey]),
                tooltip: [
                  {
                    ...mapping(meta.fields, barValueKey),
                    value: d[barValueKey],
                  },
                ],
                allValues: d,
              })
            }
            onMouseOut={() => setTooltip(null)}
          />

          <SpringGroup
            y={leftYAxis(d[barValueKey]) - 12}
            x={
              xPos(d, i, hasManyXKeys ? barValueKey : null) +
              xWidth / 2 +
              targetBarOffset
            }
          >
            <text
              data-cy="grouped-bar-label"
              textAnchor="middle"
              fontSize={labelSize}
              fontWeight={"bold"}
              fill={theme.text.secondary}
              style={{ transform: `rotate(${rotateDeg})` }}
            >
              {formatBarLabel(d[barValueKey])}
            </text>
          </SpringGroup>
        </Fragment>
      );
    },
    [
      formatBarLabel,
      getColor,
      hasManyXKeys,
      height,
      labelSize,
      leftYAxis,
      meta?.fields,
      rotateDeg,
      setTooltip,
      theme.text.secondary,
      xPos,
      xWidth,
    ]
  );

  return (
    <>
      <YAxis
        {...props}
        yScale={leftYAxis}
        height={height}
        width={width + offsetWidth - 10}
        yAxisGridColor={theme.divider}
        yAxisGrid
        yTicksCount={5}
        yAxisFormat={yAxisFormat}
        domain={barDomain}
      />

      {yRightAxis && (
        <YAxis
          {...props}
          yScale={rightYAxis}
          height={height}
          translateAxis={width + offsetWidth - 10}
          width={0}
          yAxisGridColor={theme.divider}
          yAxisGrid
          yTicksCount={5}
          translateLabel={5}
          yAxisFormat={yRightAxisFormat}
          orient="right"
          domain={lineDomain}
        />
      )}

      <SimpleXAxis
        values={xKeys || data.map((d) => d[xKey])}
        height={height}
        xScale={x}
        xKeyFormat={xAxisFormat}
        width={width}
        allTicks={!dynamicTicks}
        xAxisDate={xAxisDate}
        bands={bands}
        hasManyXKeys={hasManyXKeys}
        meta={meta}
      />

      {data
        .map((d, i) => {
          if (hasManyBarKeys) {
            return barValueKeys.map((bvk, index) =>
              renderBars(d, index + i, bvk)
            );
          }
          return renderBars(d, i, barValueKey);
        })
        .flat()}

      {targetBarValueKey &&
        data.map((d, i) => renderBars(d, i, targetBarValueKey, xWidth))}

      {lineGroups.map((section, i) => (
        <g data-cy="multiple-lines-line" key={i}>
          <SpringLine
            width={width}
            values={section.values}
            stroke={getColor(section, i)}
            style={section.dashed ? { strokeDasharray: "5, 8" } : {}}
            curved={section.curved}
            strokeWidth={1.5}
            fill="none"
            x={x}
            y={y}
            xOffset={xOffset}
          />
          {showCircles ? (
            <g data-cy="circles">
              <PositionCirclesPlaced
                data={section.values}
                markerKey="value"
                yScale={y}
                xScale={x}
                xKey="xValue"
                yKey="value"
                xLabel={xKeys ? lineValueKeys[i] || lineValueKeys[0] : xKey}
                yLabel={lineValueKeys[i] || lineValueKeys[0]}
                segment={section.key}
                color={getColor(section, i)}
                setTooltip={setTooltip}
                borderSize={0.5}
                radius={3}
                meta={meta}
                noDate
                xOffset={xOffset}
                yRightAxisFormat={yRightAxisFormat}
                tooltipDateFormat={
                  tooltipDateFormat || xAxisFormat || xAxisDate
                }
                noDateObject={noDateObject}
                valueKeys={lineValueKeys.map((v) => ({ alias: v }))}
                xKeys={xKeys ?? []}
              />
            </g>
          ) : null}
        </g>
      ))}
    </>
  );
}
