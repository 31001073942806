import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import { UPDATE_SITE_CONFIGURATION_FAIL } from "./actionTypes";
import { showToast } from "./message";
import { convertLocalDraftToDateFilters } from "../../utils/siteConfiguration/dateFilters";
import {
  reportError,
  handleError,
  VALIDATION_FAILURE,
} from "../../utils/errorHandling";
import { stringifyMinimal } from "../../utils/browser";
import { FILTER_TYPE_DATE_PRESETS } from "../../utils/constants/constants";

export const getSiteConfiguration = (tenantId) => async (dispatch) => {
  try {
    dispatch(getDataSources());
    dispatch({ type: actionTypes.GET_SITE_CONFIGURATION_START });
    const res = await axios.get("/api/v1/tenant_config/" + tenantId);

    onReceiveTenantConfig(dispatch, res.data.data);
  } catch (error) {
    let handleErrors;
    if (!error.response) {
      reportError(error);
      handleErrors = ["An error has occurred."];
    } else {
      handleErrors = error.response.data.errors
        ? Object.values(error.response.data.errors).map((error) => error[0])
        : [error.response.data.message];
    }
    showToast(actionTypes.GET_SITE_CONFIGURATION_FAIL, handleErrors, "danger");
  }
};

export function onReceiveTenantConfig(dispatch, tenantConfig) {
  dispatch({
    type: actionTypes.GET_SITE_CONFIGURATION_SUCCESS,
    results: tenantConfig,
  });
}

export const getDataSources = (dataSourceIds) => async (dispatch) => {
  try {
    const query = { perPage: 1000, includeFields: 1 };
    const res = await axios.get(
      "/api/v1/data_sources" + stringifyMinimal(query, { addQueryPrefix: true })
    );

    dispatch({
      type: actionTypes.GET_DATA_SOURCES_SUCCESS,
      results: { sources: res.data.data },
    });
  } catch (error) {
    const handleErrors = error.response.data.errors
      ? Object.values(error.response.data.errors).map((error) => error[0])
      : [error.response.data.message];
    showToast(actionTypes.GET_DATA_SOURCES_FAIL, handleErrors, "danger");
  }
};

export const updateSiteConfiguration = (config) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.UPDATE_SITE_CONFIGURATION_START,
    });

    const {
      tenant,
      background,
      customPrimaryColor,
      darkThemeEnabled,
      favicon,
      logo,
      siteName,
      domain,
      dateFilters,
      showHeaderKPI,
      filtersUpdateHeaderKPIs,
      menuFilters,
      additionalTheme,
    } = config;

    const dateFiltersApiForm = convertLocalDraftToDateFilters(dateFilters);

    const formData = new FormData();

    formData.append("recacheMenuFiltersNow", "conservative");
    formData.append("domain", domain);
    formData.append("siteName", siteName);
    formData.append("darkThemeEnabled", darkThemeEnabled ? 1 : 0);
    formData.append(
      "customPrimaryColor",
      customPrimaryColor.checked ? customPrimaryColor.color : null
    );
    formData.append(
      "dateFilters",
      dateFiltersApiForm ? JSON.stringify(dateFiltersApiForm) : null
    );
    formData.append("tenantName", tenant.name);

    if (logo.file) formData.append("logo", logo.file);
    else if (!logo.imageName) formData.append("logo", "");

    if (background.file) formData.append("background", background.file);
    else if (!background.imageName) formData.append("background", "");

    if (favicon.file) formData.append("favicon", favicon.file);
    else if (!favicon.imageName) formData.append("favicon", "");

    formData.append("showHeaderKPI", showHeaderKPI ? 1 : 0);
    formData.append("settings", JSON.stringify({ filtersUpdateHeaderKPIs }));
    formData.append(
      "additionalTheme",
      additionalTheme
        ? JSON.stringify({
            ...additionalTheme,
            booleanFilters: additionalTheme.booleanFilters?.filter(Boolean),
          })
        : null
    );

    if (!menuFilters.checked || menuFilters.list.length <= 0) {
      formData.append("menuFilters", "");
    } else {
      menuFilters.list.forEach((item, index) => {
        if (item.uuid) {
          formData.append(`menuFilters[${index}][uuid]`, item.uuid);
        }

        formData.append(`menuFilters[${index}][position]`, index);

        if (item.dataSourceUuid && !item.usesCustomValues) {
          formData.append(
            `menuFilters[${index}][dataSourceUuid]`,
            item.dataSourceUuid
          );
          formData.append(
            `menuFilters[${index}][dataSourceFieldUuid]`,
            item.dataSourceFieldUuid
          );
        }

        if (item.sortDataSourceFieldUuid) {
          formData.append(
            `menuFilters[${index}][sortDataSourceFieldUuid]`,
            item.sortDataSourceFieldUuid
          );
          formData.append(
            `menuFilters[${index}][sortOrder]`,
            item.sortOrder ?? "asc"
          );
        }

        if (item.keyDataSourceFieldUuid) {
          formData.append(
            `menuFilters[${index}][keyDataSourceFieldUuid]`,
            item.keyDataSourceFieldUuid
          );
        }
        formData.append(`menuFilters[${index}][displayName]`, item.displayName);

        formData.append(`menuFilters[${index}][type]`, item.type);

        // send something to labeled values for validation reason
        const isDatePresets = item.type === FILTER_TYPE_DATE_PRESETS;
        const labeledValues = isDatePresets
          ? [{ value: "value", label: "label" }]
          : [];

        if (item.usesCustomValues) {
          formData.append(`menuFilters[${index}][field]`, item.name);
          (item.labeledValues ?? labeledValues)
            .filter((item) => item.value)
            .forEach((item, i) => {
              formData.append(
                `menuFilters[${index}][labeledValues][${i}][label]`,
                item.label || item.value
              );
              formData.append(
                `menuFilters[${index}][labeledValues][${i}][value]`,
                item.value
              );
            });
        } else if (item.labeledValues) {
          formData.append(`menuFilters[${index}][labeledValues]`, []);
        }

        item.defaultValues &&
          Array.isArray(item.defaultValues) &&
          item.defaultValues.forEach((selected, i) => {
            formData.append(
              `menuFilters[${index}][defaultValues][${i}]`,
              selected
            ); // Default filters applied
          });

        const showHideProperties = ["hide", "show"];

        for (const property of showHideProperties) {
          const value = item[property];
          value &&
            Array.isArray(value) &&
            value.forEach((showHideItem, i) => {
              formData.append(
                `menuFilters[${index}][${property}][${i}]`,
                showHideItem
              ); // This should now be just the id
            });
        }

        let anyMenuFilterDependencies = false;
        if (item.dependencyParents) {
          for (const [
            dependencyParentIndex,
            dependencyParent,
          ] of Object.entries(item.dependencyParents)) {
            if (dependencyParent.stagedForDelete) {
              continue;
            }
            if (
              menuFilters.dependencyParentUuidStagedForDeleteMap[
                dependencyParent.parentMenuFilterUuid
              ]
            ) {
              // This whole parent was staged for deletion.
              continue;
            }
            const prefix = `menuFilters[${index}][dependencyParents][${dependencyParentIndex}]`;
            formData.append(
              `${prefix}[parentMenuFilterUuid]`,
              dependencyParent.parentMenuFilterUuid
            );
            formData.append(
              `${prefix}[dataSourceFieldUuid]`,
              dependencyParent.dataSourceFieldUuid
            );
            anyMenuFilterDependencies = true;
          }
          // @todo Getting 500 error with this added as null...
          if (!anyMenuFilterDependencies) {
            formData.append(`menuFilters[${index}][dependencyParents]`, "");
          }
        }
      });
    }

    const res = await axios.post(
      "/api/v1/tenant_config/" + tenant.uuid,
      formData
    );

    dispatch({
      type: actionTypes.UPDATE_TENANT_CONFIG,
      results: { tenantConfig: res.data.data },
    });

    onReceiveTenantConfig(dispatch, res.data.data);

    showToast(
      actionTypes.UPDATE_SITE_CONFIGURATION_SUCCESS,
      ["Site Configuration Updated"],
      "success"
    );
  } catch (error) {
    const normalizedError = handleError(error);

    dispatch({
      type: UPDATE_SITE_CONFIGURATION_FAIL,
      errors:
        normalizedError.type === VALIDATION_FAILURE
          ? normalizedError.data
          : [normalizedError.message],
    });
  }
};

export const recacheMenuFilters = (tenant) => async (dispatch) => {
  dispatch({ type: actionTypes.RECACHE_MENU_FILTERS_START });

  try {
    await axios.post("/api/v1/tenant_config/" + tenant.uuid, {
      recacheMenuFiltersNow: 1,
    });

    dispatch({ type: actionTypes.RECACHE_MENU_FILTERS_SUCCESS });
    showToast(
      actionTypes.RECACHE_MENU_FILTERS_FAIL,
      ["Menu Filters Refreshed."],
      "success"
    );
  } catch (error) {
    dispatch({ type: actionTypes.RECACHE_MENU_FILTERS_FAIL });
    handleError(error);
  }
};

export const recacheMenuFilter = (uuid) => (dispatch) => {
  dispatch({
    type: actionTypes.RECACHE_MENU_FILTER_START,
    meta: {
      api: {
        method: "POST",
        endpoint: `/api/v1/menu_filters/${uuid}/recache`,
        toastOnFailure: true,
      },
      toasts: [
        {
          type: "success",
          title: "Menu Filter Refreshed.",
          condition: actionTypes.RECACHE_MENU_FILTER_SUCCESS,
        },
      ],
    },
    uuid,
  });
};
