import { isLight } from "./colorConvertor";

const general = {
  // can be used in charts or buttons
  primaryLight: "#ff784e",
  primaryMain: "#ff5722",
  primaryDark: "#b23c17",
  secondaryLight: "#f73378",
  secondaryMain: "#f50057",
  secondaryDark: "#ab003c",
  shadow1dp: `0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)`,
  shadow2dp: `0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)`,
};

export const notification = {
  errorLight: "#ff876a",
  errorMain: "#ff4816",
  errorDark: "#a43933",
  warningLight: "#f8c376",
  warningMain: "#ff9800",
  warningDark: "#f57c00",
  infoLight: "#90caf9",
  infoMain: "#1e88e5",
  infoDark: "#2863be",
  successLight: "#81c784",
  successMain: "#00ce08",
  successDark: "#388e3c",
  errorLighter: "#FFC7CE",
  successLighter: "#C6EFCE",
};

export const gray = {
  gray50: "#FAFAFA",
  gray100: "#F5F5F5",
  gray200: "#EEEEEE",
  gray300: "#E0E0E0",
  gray400: "#BDBDBD",
  gray500: "#9E9E9E",
  gray600: "#757575",
  gray700: "#616161",
  gray800: "#424242",
  gray900: "#212121",
};

const blueGray = {
  blueGray50: "#eceff1",
  blueGray100: "#cfd8dc",
  blueGray200: "#b0bec5",
  blueGray300: "#90a4ae",
  blueGray400: "#78909c",
  blueGray500: "#607d8b",
  blueGray600: "#546e7a",
  blueGray700: "#455a64",
  blueGray800: "#37474f",
  blueGray900: "#263238",
};

const themeConfig = {
  light: {
    type: "light",
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    buttons: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      primary: "#ffffff",
      secondary: "#f7f7f7",
      overlay: "#666666",
      admin: "#f4f7f9",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    borderColor: "rgba(0, 0, 0, 0.12)",
    hover: "rgba(0, 0, 0, 0.05)",
    chip: {
      background: "#e0e0e0",
      primary: "#004eaa",
    },
    excel: {
      general: "#f2f2f2",
      background: "#e6e6e6",
      color: "#757575",
    },
    stickyBackground: "#F2F2F2",
    freezeBackground: "#e0e0e0",
    groupedZebraRows: {
      dark: "#F2F2F2",
      light: "#ffffff",
    },
  },
  dark: {
    type: "dark",
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    buttons: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    background: {
      primary: "#303030",
      secondary: "#181818",
      overlay: "#666",
      admin: "#303030",
    },
    divider: "rgba(255, 255, 255, 0.22)",
    borderColor: "rgba(255, 255, 255, 0.22)",
    hover: "rgba(255,255,255,.05)",
    chip: {
      background: "#616161",
      primary: "#004eaa",
    },
    excel: {
      general: "#292929",
      background: "#232323",
      color: "#c1c1c1",
    },
    stickyBackground: "#292929",
    freezeBackground: "#212121",
    groupedZebraRows: {
      dark: "#212121",
      light: "#303030",
    },
  },
  globalOverride: {
    type: "global",
    primary: blueGray.blueGray500,
    secondary: blueGray.blueGray200,
    textprimary: "#fff",
    textsecondary: "rgba(0, 0, 0, 0.54)",
  },
};

export default function theme(config) {
  const baseTheme = { ...themeConfig[config.name] };
  const primary = config.primary || "#0080bf";
  const textOnPrimary = isLight(primary) ? "#000" : "#FFF";
  const updatedTheme = {
    ...baseTheme,
    text: { ...baseTheme.text, textOnPrimary },
    notification,
    gray,
    blueGray,
    general,
    primary,
    textOnPrimary: isLight(primary) ? "#000" : "#FFF",
    menuPrimary: config.menuPrimary,
  };

  return updatedTheme;
}

/**
 * Returns a filter brightness value depending on if the theme is dark or light.
 * If light, makes the background color darker, and vice-versa.
 *
 * @param theme
 * @param ratio
 * @returns {string}
 */
export function getEnhancedColorFilter(theme, ratio) {
  const percent = theme.type === "dark" ? (1 + ratio) * 100 : (1 - ratio) * 100;
  return `brightness(${percent}%)`;
}

// also here we can use lodash deep copy because it is faster then JSON conversion
// const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));
