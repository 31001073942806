import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Input from "../../../../../../UI/Form/Input/Input";
import { ColorModeItem } from "./ColorMode";
import Checkbox from "../../../../../../UI/Form/Checkbox/Checkbox";
import { omit, isEmpty } from "lodash-es";
import useDebounceChange from "../../../../../../hooks/useDebounceChange";
import { rightAligns } from "../../../../../../utils/constants/constants";

const InnerContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const InputsWidth = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    max-width: 49%;
  }
`;

export default function FixedColumnsWidth({
  generalColumnsWidth,
  dynamicColumnsWidth = {},
  maxColumnsWidth = {},
  selectedColumn,
  setChartState,
  renderAsImageKeys = [],
  theme,
  chart,
}) {
  const { debounce } = useDebounceChange();

  const { column, mapping } = selectedColumn;

  const [state, setState] = useState({
    dynamic: dynamicColumnsWidth[column],
    general: generalColumnsWidth,
    max: maxColumnsWidth[column],
  });

  // Handles changes in column width for a specific type.
  function handleChangeWidth(type, e) {
    // Create a key based on the type for accessing the appropriate column width in state
    const key = `${type}ColumnsWidth`;

    // Convert the new value to a number
    const val = +e.target.value;

    // Create the updated state object based on the type and new value
    const updatedState = {
      ...chart,
      [key]:
        type === "general" ? val : { ...(chart[key] || {}), [column]: val },
    };

    // Update the component state with the new column type value
    setState((prev) => ({ ...prev, [type]: e.target.value }));

    // Debounce the update of the chart state to avoid frequent updates
    debounce(() => setChartState(updatedState));
  }

  const isActive = !!(
    dynamicColumnsWidth[column] ||
    generalColumnsWidth ||
    maxColumnsWidth[column]
  );

  function setFixedWidth() {
    setChartState({
      ...chart,
      dynamicColumnsWidth: { ...chart.dynamicColumnsWidth },
      generalColumnsWidth: 100,
      maxColumnsWidth: { ...chart.maxColumnsWidth },
    });
  }

  function unSetFixedWidth() {
    const {
      dynamicColumnsWidth,
      generalColumnsWidth,
      maxColumnsWidth,
      ...rest
    } = chart;
    setChartState(rest);
  }

  function setMediaMode() {
    setChartState({
      ...chart,
      renderAsImageKeys: [
        ...renderAsImageKeys.filter((media) => media !== column),
        column,
      ],
    });
  }

  function unSetMediaMode() {
    setChartState({
      ...chart,
      renderAsImageKeys: [...renderAsImageKeys].filter(
        (media) => media !== column
      ),
    });
  }

  const isDefined =
    dynamicColumnsWidth[column] !== undefined &&
    dynamicColumnsWidth[column] !== null;

  function switchDynamicColumnWidth() {
    let temp = { ...dynamicColumnsWidth };

    if (isDefined) {
      temp = omit(temp, [column]);
    } else {
      temp = { ...temp, [column]: 10 };
    }

    if (isEmpty(temp)) {
      return setChartState(omit(chart, "dynamicColumnsWidth"));
    }

    setChartState({ ...chart, dynamicColumnsWidth: temp });
  }

  // do not allow click on media type if its not a link or string
  const type = mapping?.type;
  const nonMediaType = rightAligns.some((t) => type === t);

  return (
    <div>
      <span>Fixed width</span>
      <span style={{ marginLeft: 115 }}>Media mode</span>
      <InnerContainer style={{ justifyContent: "space-between" }}>
        <InnerContainer>
          <ColorModeItem
            isActive={!isActive}
            onClick={unSetFixedWidth}
            data-cy="fixed-width-none"
            style={{ color: !isActive && "white" }}
          >
            <FontAwesomeIcon icon={["fas", "thumbs-down"]} />
          </ColorModeItem>
          <ColorModeItem
            style={{ marginLeft: 20, color: isActive && "white" }}
            isActive={isActive}
            onClick={setFixedWidth}
            data-cy="fixed-width"
          >
            <FontAwesomeIcon icon={["fas", "thumbs-up"]} />
          </ColorModeItem>
        </InnerContainer>

        {/* Media mode */}
        <InnerContainer>
          <ColorModeItem
            isActive={!renderAsImageKeys.includes(column)}
            onClick={unSetMediaMode}
            data-cy="media-mode-none"
            disabled={nonMediaType}
          >
            <FontAwesomeIcon
              icon={["far", "times-circle"]}
              style={{ color: theme.notification.errorMain, fontSize: 18 }}
            />
          </ColorModeItem>
          <ColorModeItem
            style={{ marginLeft: 20, fontSize: 16 }}
            isActive={renderAsImageKeys.includes(column)}
            onClick={setMediaMode}
            data-cy="media-mode-on"
            disabled={nonMediaType}
          >
            <FontAwesomeIcon icon={["fas", "camera-movie"]} />
          </ColorModeItem>
        </InnerContainer>
      </InnerContainer>
      {isActive && (
        <InputsWidth>
          <Input
            label="General Column Width"
            value={state.general || generalColumnsWidth || ""}
            hideEditIcon
            type="number"
            ignoreValue
            onChange={(e) => handleChangeWidth("general", e)}
            cy="fixed-width-general"
          />

          <Input
            label="Max Column Width"
            value={state.max}
            hideEditIcon
            type="number"
            ignoreValue
            onChange={(e) => handleChangeWidth("max", e)}
            cy="fixed-width-max"
          />
        </InputsWidth>
      )}
      <br />
      <Checkbox
        label="Custom width of selected column"
        checked={isDefined}
        onChange={switchDynamicColumnWidth}
      />
      <br />
      {isDefined && (
        <Input
          label="Dynamic Column Width"
          value={state.dynamic}
          hideEditIcon
          type="number"
          ignoreValue
          onChange={(e) => handleChangeWidth("dynamic", e)}
          cy="fixed-width-dynamic"
        />
      )}
      <br />
    </div>
  );
}
