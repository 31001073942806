import Flex from "../../../UI/Flex/Flex";
import ColumnsSelectionSidebar from "./ColumnsSelectionSidebar";
import ColumnSelectionListItem from "./ColumnSelectionListItem";
import SelectionListItemIcon from "./SelectionListItemIcon";
import { ColumnsList, ColumnListItem } from "./styles";
import { seveExpandedRowHiddenColumns } from "../../../store/actions/dashboard/dashboard";
import { getJoinedName } from "../functions/tableMapper";

export default function GrouppedColumnsSelection({
  isOpened,
  meta,
  allGroups,
  hiddenGroupings,
  allColumns,
  hiddenColumns,
  onClose,
  visualizationId,
  dispatch,
}) {
  const groupList = allGroups.map((header, index) => {
    const isHeaderHidden = hiddenGroupings.includes(header);
    const indexOverride =
      allColumns.length - 1 < index ? allColumns.length - 1 : index;

    return {
      hidden: isHeaderHidden,
      name: header,
      columns:
        allColumns
          .at(indexOverride)
          ?.map((column) => ({
            hidden:
              isHeaderHidden ||
              hiddenColumns.includes(getJoinedName(header) + column),
            name: column,
          }))
          .filter((column) => !!column.name) ?? [],
    };
  });

  function getUpdatedGroups(name, hide, isLastColumn) {
    if (hide) {
      return isLastColumn ? [...hiddenGroupings, name] : hiddenGroupings;
    }

    return hiddenGroupings.filter((hiddenGroup) => hiddenGroup !== name);
  }

  function getUpdatedColumns(columns, column, hide, groupName) {
    const starred = getJoinedName(groupName);

    if (hide) {
      if (column) {
        return [...hiddenColumns, starred + column.name];
      } else {
        return columns.map(({ name }) => starred + name);
      }
    }

    return hiddenColumns.filter((hiddenColumn) => {
      if (column) {
        return hiddenColumn !== starred + column.name;
      } else {
        return !columns.some(({ name }) => starred + name === hiddenColumn);
      }
    });
  }

  function buildHiddenOnSelect(group, column, hide, isLastColumn) {
    const { name, columns } = group;

    return {
      groups: getUpdatedGroups(name, hide, isLastColumn),
      columns: getUpdatedColumns(columns, column, hide, name),
    };
  }

  const onSelectGroup = (group, hide) => {
    const { columns, groups } = buildHiddenOnSelect(group, null, hide, true);

    dispatch(
      seveExpandedRowHiddenColumns(
        visualizationId,
        ["hiddenGroupings", "hiddenColumns"],
        [groups, columns]
      )
    );
  };

  const onSelectColumn = (column, hide, groupIndex) => {
    const group = groupList.at(groupIndex);

    const isLastColumn =
      group.columns.filter((column) => !column.hidden).length === 1;

    const { groups, columns } = buildHiddenOnSelect(
      group,
      column,
      hide,
      isLastColumn
    );

    dispatch(
      seveExpandedRowHiddenColumns(
        visualizationId,
        ["hiddenGroupings", "hiddenColumns"],
        [groups, columns]
      )
    );
  };

  const onClearGroups = () => {
    dispatch(
      seveExpandedRowHiddenColumns(
        visualizationId,
        ["hiddenGroupings", "hiddenColumns"],
        [allGroups.slice(), allColumns.flat()]
      )
    );
  };

  const onFullQueryGroups = () => {
    dispatch(
      seveExpandedRowHiddenColumns(
        visualizationId,
        ["hiddenGroupings", "hiddenColumns"],
        [[], []]
      )
    );
  };

  return (
    <ColumnsSelectionSidebar
      isOpened={isOpened}
      onClose={onClose}
      onClearClick={onClearGroups}
      onFullQueryClick={onFullQueryGroups}
    >
      {groupList?.map((group, groupIndex) => (
        // we use index to make unique key for empty group name
        <Flex
          key={group.name + `-${groupIndex}`}
          direction="column"
          gap="1.5rem"
        >
          <ColumnsList>
            <ColumnListItem
              data-cy="selection-group-item"
              onClick={() => onSelectGroup(group, !group.hidden)}
            >
              <SelectionListItemIcon hidden={group.hidden} />
              {group.name || "Group"}
            </ColumnListItem>
            {!!group.columns.length && (
              <ColumnsList paddingLeft="1rem">
                {group.columns.map((column) => (
                  <ColumnSelectionListItem
                    key={column.name}
                    column={column}
                    metaFields={meta?.fields}
                    onItemClick={(item, hide) =>
                      onSelectColumn(item, hide, groupIndex)
                    }
                  />
                ))}
              </ColumnsList>
            )}
          </ColumnsList>
        </Flex>
      ))}
    </ColumnsSelectionSidebar>
  );
}
