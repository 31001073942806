import styled from "@emotion/styled";
import {
  getActiveTableFileTypeConfig,
  getSpecificFileTypeByFilename,
  activeTableTypes,
} from "../../../utils/activeTable";
import { useActiveTableFileDownload, noFileOpacity } from "./common";
import ApiImage from "../../TableView/Elements/ApiImage";
import Flex from "../../../UI/Flex/Flex";
import { colors } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import PdfViewer from "./FileViewer/PdfViewer";
import WordExcelViewer from "./FileViewer/WordExcelViewer";

const Container = styled.div`
  height: 100%;
  & > div {
    height: 100% !important;
    width: 80px !important;
    margin: 0 auto;
  }
`;

export default function (value) {
  const colDef = value.colDef;
  const columnType = colDef.type;
  const editable = colDef.editable;
  const rowIndex = value.node.rowIndex;
  const colId = colDef.colId;
  const fileTypeConfig = getActiveTableFileTypeConfig(columnType);
  const [modalToShow, setModalToShow] = useState(null);

  const field = colDef.field;
  const fileValue = value.data[field];

  const fileIsProcessed = !!fileValue && typeof fileValue === "string";
  const isImage = columnType === "api-image";

  const effectiveFileTypeConfig =
    getSpecificFileTypeByFilename(fileValue) ?? fileTypeConfig;
  const icon = effectiveFileTypeConfig.solid_icon;

  const { downloadIcon, download, downloading } =
    useActiveTableFileDownload(fileValue);

  const apiType = effectiveFileTypeConfig.apiType;
  const clickHandler = useMemo(() => {
    if (!fileIsProcessed) {
      return;
    }

    if (apiType === activeTableTypes.PDF) {
      return () => setModalToShow(modalTypes.PDF);
    }
    if ([activeTableTypes.EXCEL, activeTableTypes.WORD].includes(apiType)) {
      return () => setModalToShow(modalTypes.EXCEL_WORD);
    }
  }, [apiType, fileIsProcessed]);

  const onEdit = useMemo(() => {
    if (!editable) {
      return;
    }

    return () => {
      value.api.startEditingCell({
        rowIndex,
        colKey: colId,
      });
    };
  }, [editable, rowIndex, value.api, colId]);

  return (
    <Container
      onClick={clickHandler}
      className={clickHandler ? "clickable" : ""}
    >
      {modalToShow === modalTypes.PDF ? (
        <PdfViewer
          filePath={fileValue}
          close={closeFileViewerModel}
          edit={onEdit}
          download={download}
          downloading={downloading}
        />
      ) : null}
      {modalToShow === modalTypes.EXCEL_WORD ? (
        <WordExcelViewer
          filePath={fileValue}
          close={closeFileViewerModel}
          edit={onEdit}
          download={download}
          downloading={downloading}
        />
      ) : null}
      {isImage && fileIsProcessed ? (
        <ApiImage value={fileValue} blockZoom contain />
      ) : (
        <Flex
          style={{
            fontSize: 32,
            color: colors.fileIcon,
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          gap="1rem"
          title={fileIsProcessed ? "" : "No File Uploaded."}
        >
          <FontAwesomeIcon
            icon={icon}
            color={colors.fileIcon}
            opacity={fileIsProcessed ? 1 : noFileOpacity}
          />

          {!isImage && downloadIcon}
        </Flex>
      )}
    </Container>
  );

  function closeFileViewerModel() {
    setModalToShow(null);
  }
}

const modalTypes = {
  PDF: Symbol("pdf"),
  EXCEL_WORD: Symbol("excelWord"),
};
