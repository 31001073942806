import React, { useMemo } from "react";
import SortableList from "../../../Editors/BlockEdit/GuiSorting/SortableList";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { formatDataSourceFieldName } from "../../../utils/dataSources";

export default function ActiveTableViewSortingEditor({
  viewForm: { control, setValue, watch },
  queryUuid,
}) {
  const keyPrefix = "displaySettings.orders";
  const fieldArray = useFieldArray({ control, name: keyPrefix });
  const { append, fields, move, remove } = fieldArray;
  const orders = watch(keyPrefix);

  const queryFields = useSelector((state) => state.activeTable.queryFields);
  const fieldOptions = useMemo(() => {
    return queryFields.map((field) => ({
      value: field.name,
      label: formatDataSourceFieldName(field.mapping.displayName, field.name),
      displayName: field.mapping.displayName,
      type: field.mapping.type,
    }));
  }, [queryFields]);

  return (
    <SortableList
      keyPrefix={keyPrefix}
      fields={fields}
      orders={orders}
      control={control}
      append={append}
      move={move}
      remove={remove}
      fieldOptions={fieldOptions}
      queryUuid={queryUuid}
      setValue={setValue}
    />
  );
}
