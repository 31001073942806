import { useEffect, useState } from "react";
import { isEqual } from "lodash-es";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import toDateFiltersOptions from "./toDateFiltersOptions";
import { dateFormats } from "../../../utils/constants/constants";
import { saveStickyDateType } from "../../../store/actions/layout";
import { getUserSelectedDateOption, toDateOptions } from "./customDateUtils";
import {
  getCustomRangeDates,
  getPastYearsToNextDaysDateRange,
  getSingleFiscalQuarters,
  setDynamicDateOption,
} from "../../../utils/formatters/dateFormatter";
import { getDefaultSingleDay } from "./SingleDay";
import { rollingList } from "./CustomDateFilters";
import { defaultDaysQuantityByDomain } from "./FutureDaysAndEarlier";

const RecentPeriods = styled.div`
  margin-top: 24px;
`;

export default function useToDateFilters(props) {
  const {
    dateFiltersConfig,
    selectedDateType,
    recentMonthsChange,
    domain,
    userSettings,
    dropDateGroup,
    additionalTheme,
    activeTab,
    onDateTypeChangeHandler,
  } = props;

  const [recentSelected, setRecentSelected] = useState(null);
  const dispatch = useDispatch();

  const dateType = selectedDateType?.value;
  const activeTabUuid = activeTab?.uuid;

  const hardcodedPinnacle = { activeTab, domain };

  const { recentMonths, recentQuarters } =
    dateFormats[domain] ?? dateFormats.default;

  const secondaryOptions = toDateFiltersOptions(
    recentMonths,
    recentQuarters,
    dateFiltersConfig,
    undefined, // remove this when pinnacle Q2 2022 will be ready
    hardcodedPinnacle // remove this when pinnacle Q2 2022 will be ready
  );

  const { defaultOptions } = getSecondaryDropdownProperties(selectedDateType);

  const defaultOption = setDynamicDateOption({
    options: defaultOptions,
    dateType,
    dateFilterSettings: userSettings?.dateFilterSettings,
    dateFiltersConfig,
  });

  // local state change
  useEffect(() => {
    const userSelected = getUserSelectedDateOption(
      dateFiltersConfig,
      activeTabUuid
    );

    const option = userSelected ?? recentSelected ?? defaultOption;

    if (!isEqual(option, recentSelected)) {
      setRecentSelected(option);
    }
  }, [
    recentMonthsChange,
    recentSelected,
    selectedDateType,
    activeTabUuid,
    dateFiltersConfig,
    defaultOption,
  ]);

  // special feature for pages
  // now we have opportunity to set different date filters on each page
  const mainOptions = toDateOptions(
    dateFiltersConfig.filterCustomValues,
    dateFormats[domain],
    dateFiltersConfig.pageOriented,
    activeTab,
    domain,
    additionalTheme
  );

  const mainSelection = mainOptions.find((o) => o.value === dateType);

  const noneOption = mainOptions.find((o) => o.value === "none");
  const options = mainOptions.filter((o) => o.value !== "none");
  const currentNoneOption = dropDateGroup
    ? { value: null, label: "None..." }
    : noneOption;

  const formatOptionLabel = ({ label, period }) => (
    <div>
      <div>{label}</div>
      {period && <div>{period}</div>}
    </div>
  );

  function getSecondaryDropdownProperties(type) {
    const options = secondaryOptions[type?.value]?.options ?? [];

    const option = setDynamicDateOption({
      options,
      dateType: type?.value,
      dateFilterSettings: userSettings?.dateFilterSettings,
      dateFiltersConfig,
    });

    return {
      defaultOptions: options,
      defaultOption: option,
    };
  }

  function setSecondaryDropdownOption(type) {
    const { defaultOption } = getSecondaryDropdownProperties(type);
    const dateOption = getUserSelectedDateOption() ?? defaultOption;

    if (dateOption) {
      setRecentSelected(dateOption);
      recentMonthsChange(dateOption, type);
    }
  }

  function saveStickyDatesToStore(type, option) {
    dispatch(
      saveStickyDateType({
        pageUuid: activeTab?.uuid,
        term: type,
        dateOption: option,
      })
    );
  }

  // this function handling such types as custom-range, single-day and etc
  function setSpecialDateRanges(type) {
    const { toDateType } = rollingList.find((r) => r.key === type.value) ?? {};
    const specialTypes = [
      "cr",
      "sd",
      "past3YearsToNextDays",
      "fiscalQuarterRange",
    ];

    if (!specialTypes.includes(type.value) && !toDateType) return;

    let dates = getSpecialDateRange(type.value) || {};

    if (toDateType) {
      const { from, to } =
        (dateFiltersConfig.rollingDates ?? {})[toDateType] ?? {};

      // if there is no default rolling dates then do not call reload charts
      if (!from && !to) {
        dates = null;
      } else {
        dates = { ...dates, from, to };
      }
    }

    // if there is no dates then do not call reload charts
    if (dates) {
      onDateTypeChangeHandler(type, dates);
    }

    saveStickyDatesToStore(type, dates);
  }

  // Extracted function to handle different date ranges
  function getSpecialDateRange(type) {
    switch (type) {
      case "cr": {
        const { start, end } = getCustomRangeDates();
        return { from: start, to: end };
      }

      case "sd": {
        const yesterday = getDefaultSingleDay();
        return { from: yesterday, to: yesterday };
      }

      case "past3YearsToNextDays": {
        const days = defaultDaysQuantityByDomain[domain] ?? 1;
        const { start, end } = getPastYearsToNextDaysDateRange(days, 3);
        return { from: start, to: end, days };
      }

      case "fiscalQuarterRange": {
        const options = getSingleFiscalQuarters(dateFiltersConfig);
        const { defaultFiscalQuarter } = dateFiltersConfig;
        return (
          options.find((o) => o.label === defaultFiscalQuarter) || {
            from: options[0]?.from,
            to: options[options.length - 1]?.to,
          }
        );
      }

      default:
        return null;
    }
  }

  return {
    mainOptions,
    mainSelection,
    secondaryOptions,
    dateType,
    getSecondaryDropdownProperties,
    setSecondaryDropdownOption,
    formatOptionLabel,
    options,
    currentNoneOption,
    RecentPeriods,
    recentSelected,
    setRecentSelected,
    saveStickyDatesToStore,
    activeTabUuid,
    setSpecialDateRanges,
  };
}
