import { components } from "react-select";
import { allOption } from "./SelectWithCheckboxes";

export default function ValueContainer({ children, ...props }) {
  const currentValues = props.getValue();
  let toBeRendered = children;

  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
}
