import styled from "@emotion/styled";
import { themeGray } from "../../../styles/styledSnippets";
import Flex from "../../../UI/Flex/Flex";
import {
  downloadFileFromwarehouseImages,
  downloadFile,
} from "../../../utils/files";
import { handleError } from "../../../utils/errorHandling";
import React, { useState, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { orderBy } from "lodash-es";

export const PreviewContainer = styled(Flex)`
  width: 360px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  background-color: white;
  border: 1px solid ${themeGray(300)};
  align-items: center;
  justify-content: center;
`;

export const noFileOpacity = 0.4;

export function useActiveTableFileDownload(fileValue, signal) {
  const [downloading, setDownloading] = useState(false);

  const doDownload = useCallback(async () => {
    try {
      setDownloading(true);
      const response = await downloadFileFromwarehouseImages(fileValue, signal);

      const parts = fileValue.split("/");

      const filename = parts[parts.length - 1];
      downloadFile(response.data, filename);
    } catch (e) {
      handleError(e);
    } finally {
      setDownloading(false);
    }
  }, [fileValue, signal]);

  const download = useMemo(
    () => (fileValue ? doDownload : undefined),
    [doDownload, fileValue]
  );

  const downloadIcon = (
    <FontAwesomeIcon
      className={fileValue && !downloading ? "clickable" : ""}
      style={download ? undefined : { opacity: noFileOpacity }}
      title={download ? "Download File" : ""}
      icon={!downloading ? faDownload : faSpinner}
      spin={downloading}
      fontSize="1rem"
      onClick={download}
    />
  );

  return { download, downloading, downloadIcon };
}

export function getOrderedKeys(array, direction) {
  return orderBy(array, null, direction);
}
