import Select from "../../../../../UI/Form/Select/Select";
import { getRidOfAggregation } from "../../../../../charts/TableView/Elements/EditableMenu";
import {
  aggregations,
  extraColumnOperators,
} from "../../../../../utils/constants/constants";
import { FormulaItem } from "../../Layout/Column/FormulaColumn";
import {
  getBlendedQueryFieldLableWithAlias,
  getColumn,
  getCurrentAggregation,
  getUpdatedOverrides,
} from "../../Layout/helper";

export default function RowKeys({ chart, setChartState, queryType, fields }) {
  const isParameterized = queryType === "parameterized";
  const { groupingExtraColumns, overrides } = chart;
  const { rowKeys = [], operator } = groupingExtraColumns;

  const operatorOption = extraColumnOperators.find(
    (option) => option.value === operator
  );

  const values = Array.from(Array(operatorOption?.count ?? 0).keys()).map(
    (index) => rowKeys[index]
  );

  function updateOrReplace(field, prevValue, index) {
    const key = rowKeys.find((key, i) => key === prevValue && index === i);

    return !key
      ? [...rowKeys, field.name]
      : rowKeys.map((item, i) =>
          item === prevValue && i === index ? field.name : item
        );
  }
  function onRowKeyChange(field, prevValue, index) {
    setChartState({
      ...chart,
      groupingExtraColumns: {
        ...groupingExtraColumns,
        rowKeys: updateOrReplace(field, prevValue, index),
      },
      overrides: getUpdatedOverrides([field], chart.overrides, "name"),
    });
  }

  function onRowKeyAggregationChange(option, currentValue, index) {
    const nonAggregatedGroupingKey = getRidOfAggregation(
      currentValue,
      chart.overrides
    );
    const field = { name: option.prefix + nonAggregatedGroupingKey };

    setChartState({
      ...chart,
      groupingExtraColumns: {
        ...groupingExtraColumns,
        rowKeys: updateOrReplace(field, currentValue, index),
      },
      overrides: overrides.map((override) =>
        override.name === nonAggregatedGroupingKey
          ? { ...override, aggregation: option.value }
          : override
      ),
    });
  }

  return (
    <>
      {values.map((col, i) => (
        <FormulaItem key={"col" + i} isParameterized={isParameterized}>
          <Select
            options={fields}
            getOptionLabel={(option) =>
              getBlendedQueryFieldLableWithAlias(option)
            }
            getOptionValue={(option) => option.name}
            value={getColumn(overrides, col)}
            onChange={(o) => onRowKeyChange(o, col, i)}
            label={`Value ${[i + 1]}`}
            menuPlacement="top"
            isDisabled={!rowKeys[0] && i > 0}
          />
          {!isParameterized && (
            <Select
              options={[{ label: "None", prefix: "" }, ...aggregations]}
              value={getCurrentAggregation(col, overrides)}
              label="Aggregate"
              onChange={(o) => onRowKeyAggregationChange(o, col, i)}
              isDisabled={!rowKeys[i]}
              menuPlacement="top"
            />
          )}
        </FormulaItem>
      ))}
    </>
  );
}
