import React from "react";
import { warehouseImagesBaseUrl } from "../../../../utils/env";
import FileViewerModal from "./FileViewerModal";
import { contentHeight } from "./common";

export default function PdfViewer({
  filePath,
  close,
  edit,
  download,
  downloading,
}) {
  return (
    <div>
      <FileViewerModal
        close={close}
        edit={edit}
        download={download}
        downloading={downloading}
      >
        <object
          data={`${warehouseImagesBaseUrl}/${filePath}`}
          type="application/pdf"
          width="100%"
          aria-label="PDF document viewer"
          style={{ height: contentHeight }}
        />
      </FileViewerModal>
    </div>
  );
}
