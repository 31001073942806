import * as actionTypes from "./actionTypes";
import axios from "../../axios";
import { showToastWithTimeout } from "./message";
import { handleError } from "../../utils/errorHandling";

export const getReportedQueries = () => (dispatch) => {
  dispatch({
    type: actionTypes.GET_REPORTED_QUERIES_START,
    meta: {
      api: {
        endpoint: "/api/v1/reported_queries",
        method: "get",
      },
    },
  });
};

export const bulkSyncWithOriginTemplate =
  (reports, history) => async (dispatch) => {
    for (const report of reports) {
      await dispatch(createReportedQuery(report, report.uuid, history));
    }

    dispatch(getReportedQueries());
  };

export const createReportedQuery =
  (report, uuid, history) => async (dispatch) => {
    await dispatch({
      type: uuid
        ? actionTypes.UPDATE_REPORTED_QUERIES_START
        : actionTypes.CREATE_REPORTED_QUERIES_START,
      meta: {
        api: {
          endpoint: `/api/v1/reported_queries/${uuid ?? ""}`,
          method: uuid ? "PUT" : "POST",
          payload: report,
        },
        toasts: [
          {
            type: "success",
            title: "Automation!",
            message: "Schedule set successfully",
            condition: uuid
              ? actionTypes.UPDATE_REPORTED_QUERIES_SUCCESS
              : actionTypes.CREATE_REPORTED_QUERIES_SUCCESS,
          },
          {
            type: "danger",
            title: "Automation!",
            apiMessages: true,
            condition: uuid
              ? actionTypes.UPDATE_REPORTED_QUERIES_FAIL
              : actionTypes.CREATE_REPORTED_QUERIES_FAIL,
          },
        ],
      },
    });

    if (!uuid && history) {
      history.replace("/admin/automation/scheduled-emails");
    }
  };

export const getReportedQuery = (uuid) => (dispatch) => {
  dispatch({
    type: actionTypes.GET_REPORTED_QUERY_START,
    meta: {
      api: {
        endpoint: `/api/v1/reported_queries/${uuid}`,
        method: "get",
      },
    },
  });
};

export const deleteReportedQuery = (uuid) => async (dispatch) => {
  await dispatch({
    type: actionTypes.DELETE_REPORTED_QUERIES_START,
    meta: {
      api: {
        endpoint: `/api/v1/reported_queries/${uuid}`,
        method: "DELETE",
      },
    },
  });

  dispatch(getReportedQueries());
};

export const addRecipient = (uuid, recipient) => async (dispatch) => {
  await dispatch({
    type: actionTypes.CREATE_REPORTED_QUERIES_RECIPIENT_START,
    meta: {
      api: {
        endpoint: `/api/v1/reported_queries/${uuid}/recipient`,
        method: "POST",
        payload: recipient,
      },
      toasts: [
        {
          type: "success",
          title: "Automation!",
          message: "Recipient added successfully",
          condition: actionTypes.CREATE_REPORTED_QUERIES_RECIPIENT_SUCCESS,
        },
        {
          type: "danger",
          title: "Automation!",
          apiMessages: true,
          condition: actionTypes.CREATE_REPORTED_QUERIES_RECIPIENT_FAIL,
        },
      ],
    },
  });
};

export const deleteRecipient = (uuid, recipientUuid) => async (dispatch) => {
  await dispatch({
    type: actionTypes.DELETE_REPORTED_QUERIES_RECIPIENT_START,
    meta: {
      api: {
        endpoint: `/api/v1/reported_queries/${uuid}/recipient/${recipientUuid}`,
        method: "DELETE",
      },
      toasts: [
        {
          type: "success",
          title: "Automation!",
          message: "Recipient Deleted",
          condition: actionTypes.DELETE_REPORTED_QUERIES_RECIPIENT_SUCCESS,
        },
        {
          type: "danger",
          title: "Automation!",
          apiMessages: true,
          condition: actionTypes.DELETE_REPORTED_QUERIES_RECIPIENT_FAIL,
        },
      ],
    },
  });
  dispatch(getReportedQuery(uuid));
};

export const previewEmail = (uuid) => async (dispatch) => {
  dispatch({ type: actionTypes.PREVIEW_EMAIL_START });

  try {
    const res = await axios.get(`/api/v1/reported_queries/${uuid}/test`);
    const isEmpty =
      res.data === "Attachment does not have results, email not sent";

    const message = isEmpty ? res.data : "Report mailed";

    dispatch({ type: actionTypes.PREVIEW_EMAIL_SUCCESS });

    showToastWithTimeout(message, isEmpty ? "warning" : "success");
  } catch (error) {
    handleError(error);
    dispatch({ type: actionTypes.PREVIEW_EMAIL_FAIL });
  }
};

export const clearReportedQuery = () => (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_REPORTED_QUERY });
};

export const addGroupRecipient = (uuid, recipientGroup) => async (dispatch) => {
  await dispatch({
    type: actionTypes.CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_START,
    meta: {
      api: {
        endpoint: `/api/v1/reported_queries/${uuid}/recipient_groups`,
        method: "POST",
        payload: recipientGroup,
      },
      toasts: [
        {
          type: "success",
          title: "Automation!",
          message: "Recipient added successfully",
          condition:
            actionTypes.CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS,
        },
        {
          type: "danger",
          title: "Automation!",
          apiMessages: true,
          condition: actionTypes.CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL,
        },
      ],
    },
  });
};

export const deleteGroupRecipient =
  (uuid, recipientGroupUuid) => async (dispatch) => {
    await dispatch({
      type: actionTypes.DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_START,
      meta: {
        api: {
          endpoint: `/api/v1/reported_queries/${uuid}/recipient_groups/${recipientGroupUuid}`,
          method: "DELETE",
        },
        toasts: [
          {
            type: "success",
            title: "Automation!",
            message: "Recipient Deleted",
            condition:
              actionTypes.DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS,
          },
          {
            type: "danger",
            title: "Automation!",
            apiMessages: true,
            condition: actionTypes.DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL,
          },
        ],
      },
    });
    dispatch(getReportedQuery(uuid));
  };
