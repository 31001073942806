import React from "react";

import "d3-transition";
import SpringLineLine from "./SpringLineLine";

export default function SpringLine(props) {
  const {
    values,
    curved,
    x,
    y,
    width,
    xOffset = 0,
    bandwidth = 0,
    xKey,
    yKey,
    padZero,
    ...remaining
  } = props;

  const allXValues = x.domain();

  const filteredValues =
    padZero || values.find((v) => v.allData)
      ? [values]
      : allXValues.reduce(
          (acc, curr) => {
            const rowMatch = values.find((v) => v[xKey] === curr);
            if (rowMatch?.[yKey] !== undefined) {
              const lastIndex = acc.length - 1;
              acc[lastIndex].push(rowMatch);
            } else acc.push([]);
            return acc;
          },
          [[]]
        );

  const zeroValues = filteredValues.map((segment) =>
    segment.map((v) => ({ ...v, [yKey]: 0 }))
  );

  return filteredValues.map((segment, i) => (
    <SpringLineLine
      key={i}
      remaining={remaining}
      zeroValues={zeroValues[i]}
      filteredValues={segment}
      curved={curved}
      width={width}
      xOffset={xOffset}
      bandwidth={bandwidth}
      xKey={xKey}
      yKey={yKey}
      x={x}
      y={y}
    />
  ));
}
