import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRidOfAggregation } from "../../../../charts/TableView/Elements/EditableMenu";
import { getAggregationPrefix } from "../Layout/Column/RegularColumn";
import TooltipAdditionalSettings from "./TooltipAdditionalSettings";
import { getBlendedQueryFieldLableWithAlias } from "../Layout/helper";

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  margin: 20px 0px;
  align-items: end;
  & > svg {
    cursor: pointer;
    font-size: 22px;
    color: ${theme.primary};
    margin: 0px 5px 10px 10px;
  }
`
);

export default function TooltipDependencySettings({
  tooltipConfig,
  setChartState,
  chart,
  fields,
  rows,
  parentIndex,
}) {
  const { showOnColumn, showIfColumnValueExists, background } = tooltipConfig;

  const value = fields.find(
    (field) => field.name === getRidOfAggregation(showOnColumn, chart.overrides)
  );

  /**
   * Adds a new tooltip row item to the chart state.
   */
  function addTooltipRowItem() {
    const row = { key: "", type: "", label: "" };

    setChartState({
      ...chart,
      tooltipConfig: chart.tooltipConfig.map((conf, index) => {
        if (index === parentIndex) {
          return { ...conf, tooltipRows: [...rows, row] };
        }

        return conf;
      }),
    });
  }

  function changeShowOnColumnn(option) {
    const { name } = option;

    // Find the override object in the overrides array where the name matches the option name
    const { aggregation } =
      (chart.overrides ?? []).find((override) => override.name === name) ?? {};

    // Get the aggregation prefix using the option name and the aggregation from the found override
    const prefix = getAggregationPrefix(aggregation);

    setChartState({
      ...chart,
      tooltipConfig: chart.tooltipConfig.map((conf, index) => {
        if (index === parentIndex) {
          return { ...conf, showOnColumn: prefix + name };
        }

        return conf;
      }),
    });
  }

  function removeTooltipItem() {
    setChartState({
      ...chart,
      tooltipConfig: chart.tooltipConfig.filter(
        (_, index) => index !== parentIndex
      ),
    });
  }

  return (
    <>
      <Container>
        <Select
          options={fields}
          getOptionLabel={(option) =>
            getBlendedQueryFieldLableWithAlias(option)
          }
          getOptionValue={(option) => option.name}
          value={value}
          onChange={changeShowOnColumnn}
          label="Show on column"
        />

        <FontAwesomeIcon
          icon={["fal", "plus-circle"]}
          onClick={addTooltipRowItem}
          title="Add field"
        />

        <FontAwesomeIcon
          icon={["fal", "times-circle"]}
          style={{
            color: "orangered",
            cursor: "pointer",
            margin: "0px 5px 10px 5px",
          }}
          onClick={removeTooltipItem}
          title="Remove Tooltip Item"
        />
      </Container>

      <TooltipAdditionalSettings
        showIfColumnValueExists={showIfColumnValueExists}
        background={background}
        parentIndex={parentIndex}
        setChartState={setChartState}
        chart={chart}
        fields={fields}
      />
    </>
  );
}
