import React, { useState } from "react";
import IOButton from "../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import ButtonGroup from "../UI/ButtonGroup/ButtonGroup";

const TintWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: ${(props) => props.zIndexOverride ?? 10000};
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background: ${({ theme }) =>
    theme.name === "light" ? "white" : theme.background.primary};
  width: 550px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: ${({ rounded }) => (rounded ? 20 : 2)}px;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.text.secondary};
  text-align: center;
  margin-top: 18px;
  width: 100%;
  margin-bottom: 28px;
`;

/**
 * Confirmation hook.
 *
 * This hook accepts a confirmHandlerFn function of the action to take if the action is confirmed.
 * "confirmHandlerFn" may accept a value. When calling the setConfirming(...args) to bring up the confirmation overlay,
 * the args passed to it gets passed to "confirmHandlerFn" if the action is confirmed.
 *
 * @param confirmHandlerFn
 * @param title
 */
export default function useConfirmDelete(
  confirmHandlerFn,
  title = "Are you sure?",
  message,
  rounded,
  zIndexOverride
) {
  const display = (...args) => (
    <TintWrapper zIndexOverride={zIndexOverride}>
      <Modal rounded={rounded}>
        <div>
          <Title>{title}</Title>
          {message && (
            <div style={{ marginBottom: 20, padding: "0px 10px" }}>
              {message}
            </div>
          )}
          <ButtonGroup spacing={20} position="center">
            <IOButton
              cy="confirm-cancel"
              onClick={() => setConfirmingState(null)}
              cancel
            >
              Cancel
            </IOButton>
            <IOButton
              type="button"
              cy="confirm-confirm"
              onClick={() => handleConfirm(...args)}
            >
              Confirm
            </IOButton>
          </ButtonGroup>
        </div>
      </Modal>
    </TintWrapper>
  );

  const [confirming, setConfirmingState] = useState(null);

  const handleConfirm = (...args) => {
    confirmHandlerFn && confirmHandlerFn(...args);
    setConfirmingState(null);
  };

  const setConfirming = (...args) => {
    return setConfirmingState(display(...args));
  };

  return { confirming, setConfirming };
}
