import React, { useMemo, useEffect, useState } from "react";
import FilterEditorBox from "./FilterEditorBox";
import FilterContainer from "./FilterContainer";
import { func, number, object } from "prop-types";
import FieldAction from "../DataExplorerUI/FieldAction";
import FieldOperatorList from "../DataExplorerUI/ExplorerInputs/FieldOperatorList";
import ShowHide from "../../../../UI/IconButtons/ShowHide";
import styled from "@emotion/styled";
import FilterEditorValue from "./FilterEditorValue";
import fetchFieldValues from "./fetchFieldValues";
import { buckets } from "../../../../utils/constants/dataTypes";
import { useValueOptionsByFieldNameFromMenuFilters } from "../../../../hooks/menuFilterHooks";
import Flex from "../../../../UI/Flex/Flex";
import AggregationButton from "../../../../UI/IconButtons/AggregationButton";

const RowWrapper = styled.div(
  ({ hasSubQuery }) => `
  margin-bottom: ${hasSubQuery ? 50 : 28}px;
  box-sizing: border-box;
  opacity: ${(props) => (props.isMuted ? 0.4 : 1)};
`
);

const RowMainSettings = styled.div`
  display: flex;
  align-items: flex-start;
`;

export function FilterEditorRow(props) {
  const {
    filter: filterProp,
    index,
    fields,
    handleSetField,
    setOperator,
    setValues,
    filterApi,
    queryId,
    axios,
    isParameterized,
  } = props;

  const [uniques, setUniques] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fieldOptions = useMemo(() => {
    return (fields ?? []).map((v) => ({
      label: `${v.mapping.displayName} (${v.alias})`,
      value: v.name,
      alias: v.alias,
    }));
  }, [fields]);

  const filter = filterProp?.subQueryValues ?? filterProp;
  const name = filter.name;

  const fieldName = (fields ?? []).find((f) => f.name === filter.name);
  const alias = fieldName?.alias;

  const isMuted = filterApi.isMuted(index);
  const valueType = fieldName?.mapping?.type && buckets[fieldName.mapping.type];

  const valueOptionsByFieldName = useValueOptionsByFieldNameFromMenuFilters();

  useEffect(() => {
    if (valueOptionsByFieldName && valueOptionsByFieldName[name]) {
      setUniques(
        valueOptionsByFieldName[name].map(({ value }) => ({
          [name]: value,
        }))
      );
      return;
    }

    if (name && queryId && valueType) {
      fetchFieldValues(
        axios,
        valueType,
        name,
        queryId,
        setUniques,
        setLoading,
        setError,
        alias
      );
    }
  }, [axios, name, queryId, valueOptionsByFieldName, valueType, alias]);

  const handleRemoveValue = (valueIndex) => {
    setValues(
      index,
      filter.values.filter((v, i) => i !== valueIndex)
    );
  };

  function handleMuting() {
    filterApi.setMuted(index);
  }

  return (
    <RowWrapper
      data-cy="filter-row"
      isMuted={isMuted}
      hasSubQuery={filterProp?.subQueryValues}
    >
      <RowMainSettings>
        <Flex alignItems={"center"}>
          <AggregationButton
            isParameterized={isParameterized}
            valueType={valueType}
            fieldOptions={fieldOptions}
            filterApi={filterApi}
            index={index}
            filter={filterProp}
          />
          <ShowHide onClick={handleMuting} isMuted={isMuted} />
          {/*Column 1*/}
          <FilterEditorBox
            i={index}
            value={filter?.name || ""}
            handleSetField={handleSetField}
            options={fieldOptions}
            placeholder="Set field..."
            cy="filter-field-selector"
          />
        </Flex>

        {/*Column 2*/}
        {filter.name ? (
          <FilterContainer width={38}>
            <FieldOperatorList
              value={filter.operator}
              setValue={(val) => setOperator(index, val)}
              type={fieldName?.mapping.type}
            />
          </FilterContainer>
        ) : null}

        {/*Column 3*/}
        <FilterEditorValue
          filter={filter}
          setValues={setValues}
          uniques={uniques}
          handleRemoveValue={handleRemoveValue}
          filterIndex={index}
          dataType={valueType}
          loading={loading}
          error={error}
          setError={setError}
          setLoading={setLoading}
          isParameterized={isParameterized}
        />

        <FilterContainer width={20}>
          <FieldAction
            filter={filter}
            removeRow={() => filterApi.removeRow(index)}
          />
        </FilterContainer>
      </RowMainSettings>
    </RowWrapper>
  );
}

FilterEditorRow.propTypes = {
  filter: object.isRequired,
  index: number,
  handleSetField: func.isRequired,
  setOperator: func,
  setValues: func,
};
