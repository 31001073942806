import React, { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import Flex from "../../../UI/Flex/Flex";
import Select from "../../../UI/Form/Select/Select";
import IOButton from "../../../UI/Form/Button/IOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import CustomOrderValues from "./CustomOrderValues";

const ORDER_OPTIONS = [
  { value: "ASC", label: "Ascending" },
  { value: "DESC", label: "Descending" },
  { value: "CUSTOM", label: "Custom" },
];

export default function SortingField({
  keyPrefix,
  control,
  index,
  orders,
  remove,
  applySorting,
  filteredFieldOptions,
  handleChangeValues,
  getOptions,
  setValue,
  dragHandleProps,
  isDragDisabled,
}) {
  const [isCustomOrderExpanded, setIsCustomOrderExpanded] = useState(true);

  const order = orders[index];
  const customOrderProps = useMemo(() => {
    return {
      name: order?.name,
      values: order?.values,
    };
  }, [order?.name, order?.values]);

  return (
    <Flex direction="column" style={{ marginBottom: 18 }}>
      <Flex gap="0.6rem">
        {/* Field Select */}
        <Controller
          name={`${keyPrefix}.${index}.name`}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              label="Field"
              simpleValue={value}
              options={filteredFieldOptions(value)}
              cy={`orders-name-${index}`}
              data-cy="orders-name"
              onBlur={onBlur}
              onChange={(option) => {
                onChange(option.value);
                applySorting();
              }}
            />
          )}
        />

        {/* Order Direction Select */}
        <Controller
          name={`${keyPrefix}.${index}.sort`}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              label="Order"
              simpleValue={value}
              options={ORDER_OPTIONS}
              cy={`orders-sort-${index}`}
              onBlur={onBlur}
              onChange={(option) => {
                onChange(option.value);
                const customMode = option.value === "CUSTOM";
                setValue(`${keyPrefix}.${index}.customSortOrder`, customMode);
                applySorting();
              }}
            />
          )}
        />

        {/* Drag Handle Icon */}
        {
          <IOButton
            outline
            type="button"
            style={{
              padding: 10,
              alignSelf: "flex-end",
              marginBottom: 2,
              cursor: "grab", // Optional: Change cursor to indicate dragging handle
              display: isDragDisabled ? "none" : undefined,
            }}
            {...dragHandleProps} // Apply the drag handle props here
            title="Drag to reorder sorting rule"
            data-cy="sorting-drag-handle"
          >
            <FontAwesomeIcon icon={faGripLines} />
          </IOButton>
        }

        {/* Remove Button */}
        <IOButton
          style={{
            padding: 10,
            alignSelf: "flex-end",
            marginBottom: 2,
          }}
          type="button"
          outline
          cy="delete-sorting"
          onClick={() => {
            remove(index);
            applySorting();
          }}
          title="Remove this sorting rule"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </IOButton>
      </Flex>

      {/* Custom Sort Order Values */}
      {order?.customSortOrder && (
        <CustomOrderValues
          index={index}
          handleChangeValues={handleChangeValues}
          getOptions={getOptions}
          isExpanded={isCustomOrderExpanded}
          toggleExpand={() => setIsCustomOrderExpanded((prev) => !prev)}
          {...customOrderProps}
        />
      )}
    </Flex>
  );
}
