import React, { useState } from "react";
import styled from "@emotion/styled";
import { themeGrayIf } from "../../../styles/styledSnippets";
import IOButton from "../../../UI/Form/Button/IOButton";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { faUserAlt } from "@fortawesome/pro-light-svg-icons";

import ViewModeButton from "./ViewModeButton";
import ColumnVisibilityDetails from "./ColumnVisibilityDetails";
import ViewTabs from "./ViewTabs";
import ActiveTableViewFilterEditor from "./ActiveTableViewFilterEditor";
import ActiveTableViewSettings from "./ActiveTableViewSettings";
import ActiveTableViewsDetailContainer from "./ActiveTableViewsDetailContainer";
import ActiveTableSettings from "./ActiveTableSettings";
import ActiveTableViewSortingEditor from "./ActiveTableViewSortingEditor";
import Flex from "../../../UI/Flex/Flex";

const ActionButtons = styled.div`
  button {
    margin-right: 8px;
  }
`;

const ModeButtons = styled.div`
  display: flex;
  & > div {
    margin-right: 12px;
  }
`;

const Hr = styled.div`
  border-top: 1px solid ${themeGrayIf(700, 300)};
`;

const Close = styled.div`
  position: absolute;
  right: -12px;
  top: -24px;
  color: ${(props) => props.theme.text.primary};
  font-size: 12px;
  cursor: pointer;
`;

export default function ActiveTableViewsDetail(props) {
  const {
    viewForm,
    handleClose,
    columns = [], //nextColumns from useActiveTableViewManager
    setNextColumns,
    visibleColumns,
    changeValue,
    setVisible,
    setInvisible,
    setAllVisible,
    setAllInvisible,
    selectedView,
    queryId,
    name,
    setName,
    setVisibilityViewUser,
    handleSaveView,
    disabled,
    processing,
    activeTab,
    setActiveTab,
    filterApi,
    isDirty,
    displaySettings,
    setDefaultViewFlag,
    views,
    setNextView,
    setFilters,
    setDisplaySettings,
    setViewSettingsByKey,
    joinColumn,
  } = props;

  const [store, setStore] = useState({
    draftFilters: filterApi?.draftFilters,
    columns,
    displaySettings,
    name,
  });

  const [activeColumn, setActiveColumn] = useState(null);

  const [hoverCol, setHoverCol] = useState(null);
  // whitelist and view mapping to go here
  function handleSetActive(uuid) {
    setActiveColumn(activeColumn === uuid ? null : uuid);
  }

  function active(col) {
    return col.colId === activeColumn;
  }

  // on cancel revert changes if user do not saved them
  function onCancel() {
    handleClose();
    filterApi.setDraftFilters(store.draftFilters);
    setDisplaySettings(store.displaySettings);
    setNextColumns(store.columns);
    setName(store.name);
  }

  function onSave() {
    // after save we need to update props
    setStore({
      draftFilters: filterApi?.draftFilters,
      columns,
      displaySettings,
      name,
    });
    handleSaveView();
  }

  const components = {
    columns: (
      <ColumnVisibilityDetails
        viewColumns={columns}
        setNextColumns={setNextColumns}
        handleSetActive={handleSetActive}
        setHoverCol={setHoverCol}
        activeColumn={activeColumn}
        hoverCol={hoverCol}
        active={active}
        visibleColumns={visibleColumns}
        changeValue={changeValue}
        setVisible={setVisible}
        setInvisible={setInvisible}
        setAllVisible={setAllVisible}
        setAllInvisible={setAllInvisible}
        joinColumn={joinColumn}
      />
    ),
    filters: (
      <ActiveTableViewFilterEditor
        columns={columns}
        queryId={queryId}
        filterApi={filterApi}
      />
    ),
    sorting: (
      <ActiveTableViewSortingEditor viewForm={viewForm} queryUuid={queryId} />
    ),
    settings: (
      <ActiveTableSettings
        name={name}
        setName={setName}
        displaySettings={displaySettings}
        setDefaultViewFlag={setDefaultViewFlag}
        columns={columns}
        setViewSettingsByKey={setViewSettingsByKey}
      />
    ),
  };

  const { viewVisibilityUserUuid } = displaySettings;

  return (
    <ActiveTableViewsDetailContainer handleClose={onCancel}>
      <Flex
        direction="column"
        gap="1.5rem"
        style={{ height: "100%" }}
        data-cy="active-table-views-detail"
      >
        <Close onClick={onCancel}>Close</Close>
        <ViewTabs
          tabView={activeTab}
          setTabView={setActiveTab}
          isDraft={selectedView === "new"}
        />
        <div
          style={{
            flex: 1,
            overflow: "auto",
            // Padding and negative margin to make the auto scrollbar
            // on the right not be adjacent to any element to its left.
            paddingRight: "1rem",
            marginRight: "-1rem",
          }}
        >
          {selectedView === "new" ? (
            <ActiveTableViewSettings
              name={name}
              setName={setName}
              displaySettings={displaySettings}
              setDefaultViewFlag={setDefaultViewFlag}
              views={views}
              setNextView={setNextView}
              setNextColumns={setNextColumns}
              setFilters={setFilters}
            />
          ) : (
            components[activeTab]
          )}
        </div>
        <Hr />
        <ModeButtons>
          <ViewModeButton
            icon={faUserAlt}
            description="Everyone can see this view"
            title="Public"
            setMode={() => setVisibilityViewUser(false)}
            active={!viewVisibilityUserUuid}
          />
          <ViewModeButton
            icon={faUsers}
            description="Only you will see this view"
            title="Private"
            setMode={() => {
              setVisibilityViewUser(true);
            }}
            active={viewVisibilityUserUuid}
          />
        </ModeButtons>
        <ActionButtons>
          <IOButton cancel onClick={onCancel} cy="cancel-view-details">
            Cancel
          </IOButton>
          <IOButton
            standard
            onClick={onSave}
            cy="save-view"
            disabled={!processing && (disabled || !isDirty)}
            processing={processing}
          >
            Save view
          </IOButton>
        </ActionButtons>
      </Flex>
    </ActiveTableViewsDetailContainer>
  );
}
