import styled from "@emotion/styled";
import Flex from "../../../../../UI/Flex/Flex";
import RadioButton from "../../../../../UI/Form/RadioButton/RadioButton";
import RegularColumn from "./RegularColumn";
import OpenMathFormulaColumn from "./OpenMathFormulaColumn";
import SeparateNewFormulaColumn from "./SeparateNewFormulaColumn";
import { useEffect, useState } from "react";

const RadioButtonContainer = styled.div`
  margin: 10px 0px;
`;

const RadioButtonLabel = styled.label`
  margin-top: 5px;
  cursor: pointer;
`;

export default function Settings(props) {
  const isFormulaPresetType = props.formulaType || props.separateNewFormula;
  const isFormula = isFormulaPresetType || props.openMathFormulaType;
  const [deprecated, setDeprecated] = useState(props.formulaType);

  // Effect hook that runs when 'deprecated'.
  // If 'deprecated' is true and 'formulaType' exists in props,
  // it triggers 'switchToSeparateNewColumnType' to change it to new formula type.
  useEffect(() => {
    if (props.formulaType) {
      props.switchToSeparateNewColumnType();
      setDeprecated(true);
    }
  }, [deprecated, props]);

  return (
    <>
      <span>Column mode</span>
      <RadioButtonContainer>
        <Flex>
          <RadioButton
            checked={!isFormula}
            onChange={props.switchColumnType}
            cy="option-column-normal"
          />
          <RadioButtonLabel>Normal</RadioButtonLabel>
        </Flex>
        <Flex>
          <RadioButton
            checked={isFormulaPresetType}
            onChange={props.switchToSeparateNewColumnType}
            cy="option-column-formula"
          />
          <RadioButtonLabel>Formula</RadioButtonLabel>
        </Flex>
        <Flex>
          <RadioButton
            checked={props.openMathFormulaType}
            onChange={props.switchToOpenMathColumnType}
            cy="option-column-open-math"
          />
          <RadioButtonLabel>Open Math Formula</RadioButtonLabel>
        </Flex>
      </RadioButtonContainer>

      {props.openMathFormulaType ? (
        <OpenMathFormulaColumn {...props} />
      ) : isFormulaPresetType ? (
        <SeparateNewFormulaColumn {...props} deprecated={deprecated} />
      ) : (
        <RegularColumn {...props} />
      )}

      <hr
        color={props.theme.background.secondary}
        style={{ margin: "10px 0px" }}
      />
    </>
  );
}
