import React, { useEffect, useMemo, useRef } from "react";
import { select } from "d3-selection";
import { isEqual } from "lodash-es";
import { curveCatmullRom, curveLinear, line } from "d3-shape";

export default function SpringLineLine(props) {
  const {
    remaining,
    zeroValues,
    filteredValues,
    curved,
    width,
    xOffset,
    bandwidth,
    xKey,
    yKey,
    x,
    y,
  } = props;
  const lineRef = useRef(null);
  const lastDValueRef = useRef(null);
  const l = useMemo(() => {
    return (curved) =>
      line()
        .x((d) => {
          const pos = x(d[xKey || "xValue"]) + xOffset + bandwidth;
          return isNaN(pos) ? 0 : pos;
        })
        .y(getYPos)
        .curve(curved ? curveCatmullRom : curveLinear);
  }, [bandwidth, x, xOffset, y, xKey, yKey]);

  useEffect(() => {
    const selected = select(lineRef.current);

    if (!lastDValueRef.current) {
      const startingDValue = l(curved)(zeroValues);
      selected.attr("d", () => startingDValue);
    }
    const targetDValue = l(curved)(filteredValues);

    if (isEqual(targetDValue, lastDValueRef.current)) {
      // No change. Do not animate.
      return;
    }

    selected.transition().attr("d", () => targetDValue);
    lastDValueRef.current = targetDValue;
  }, [curved, l, filteredValues, width, xOffset, zeroValues]);

  const getYPos = (d) => {
    const initialY = y(+d[yKey || "value"]);
    return !isNaN(initialY) ? initialY : y(0);
  };

  return <path ref={lineRef} {...remaining} />;
}
