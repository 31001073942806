import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { components } from "react-select";
import Checkbox from "../../Checkbox/Checkbox";

const OptionContainer = styled.div`
  & > div {
    cursor: pointer;
    padding: 4px;
  }
  &:hover div {
    color: white;
  }
`;

const OptionLabel = styled.div(
  ({ theme, selected }) => `
  color: ${selected ? "white" : theme.text.secondary};
  width: fit-content;
  margin-left: 40px;
  margin-top: -6px;
  font-size: 12px;
  padding-bottom: 6px;
  &:hover {
    color: white;
  }
`
);

export default function Option(props) {
  const theme = useTheme();

  return (
    <OptionContainer active={props.isSelected} data-cy="select-custom-option">
      <components.Option {...props}>
        <Checkbox
          checked={!!props.isSelected}
          onChange={() => null}
          height="16px"
          width="16px"
          top="0px"
          left="4px"
          overPrimary={theme.menuPrimary}
        />
        {/* using checkox label closing menu on select on label (no idea why) */}
        <OptionLabel selected={!!props.isSelected}>{props.label}</OptionLabel>
      </components.Option>
    </OptionContainer>
  );
}
