import React, { useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { exportXlsx } from "../../utils/exportTableToExcel";
import Modal from "../../UI/Modal/Modal";
import ExportAllDrillDown from "../../charts/TableView/ExportAllDrillDown/ExportAllDrillDown";
import { useDispatch, useSelector } from "react-redux";
import {
  apiExport,
  clearExportAllObject,
} from "../../store/actions/dashboard/dashboard";
import { css } from "../../utils/ide";
import useVisualizationNavigation from "../../hooks/useVisualizationNavigation";
import Loading from "../../UI/Loading/Loading";
import Export from "./Export";

const LinksContainer = styled.div(
  ({ theme, marginTop = 0, isTableVisualization }) => css`
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin-bottom: 8px;
    position: relative;
    // this need for hideDetailLink: true
    min-height: 14.4px;
    // this z-index need to make clickable full details for charts with legend
    // but for table charts it will break expand all modal
    z-index: ${isTableVisualization ? 0 : 1};
    svg {
      margin-left: 4px;
    }

    & a,
    & > span,
    & > div span {
      font-size: 12px;
      text-decoration: none;
      color: ${theme.text.secondary};
      font-style: italic;
      &:hover {
        color: ${theme.text.primary};
      }
    }

    & > span,
    & > div span {
      cursor: pointer;
    }

    :not(:hover) .hide-unless-hover-over-container {
      display: none;
    }
  `
);

const ActionsContainer = styled(LinksContainer)(
  ({ theme, marginTop = 0, isTableVisualization }) => css`
    margin-top: ${marginTop || 18}px;
  `
);

const LoadMore = styled.span(
  ({ theme, isDrillable, resetOffset }) => `
  color: ${theme.notification.warningMain};
  font-size: 12px;
  text-decoration: none;
  font-style: italic;
  &:hover {
    color: ${theme.text.primary};
  }
  cursor: pointer;
  position: absolute;
  left: ${isDrillable ? 100 + resetOffset : 5 + resetOffset}px;
  z-index: 2;
`
);

export default function Details({
  dashboardName,
  visualizationId,
  exportId,
  hideDetailLink,
  rowIndexes,
  setRowIndexes,
  nonConvertPercentsBack,
  title,
  marginTop,
  chart,
  activeTab,
  menuFilters,
  dateFilters,
  isTableVisualization,
  domain,
  loadMoreConfig,
  isDrillable,
}) {
  const { linksNode } = useVisualizationNavigation();

  const [showModal, setShowModal] = useState(false);
  const downloading = useSelector((state) => state.dashboard.downloading);

  const dispatch = useDispatch();
  const { exportType = "FE", exportFormat } = chart ?? {};
  const isApiExort = exportType === "API";
  const [exporting, setExporting] = useState(false);

  async function handleExport(exportId, convertPercentsBack, title) {
    if (isApiExort) {
      return exportFromApi(false);
    }

    if (rowIndexes.length) {
      await setRowIndexes([]);
    }

    exportXlsx(
      exportId,
      convertPercentsBack,
      title,
      activeTab,
      dateFilters,
      menuFilters,
      domain
    );
  }

  /**
   * Exports data from API using the apiExport function with the specified chart type and format.
   *
   * @param {Boolean} allRecords
   */
  async function exportFromApi(allRecords) {
    setExporting(true);
    try {
      await dispatch(apiExport(chart, allRecords, exportFormat));
    } finally {
      setExporting(false);
    }
  }

  function closeModal() {
    dispatch(clearExportAllObject());
    setShowModal(null);
  }

  return (
    <div style={{ position: "relative" }} data-cy="details-container">
      {loadMoreConfig && (
        <>
          <LoadMore
            data-cy="load-more-link"
            onClick={() => loadMoreConfig.loadMore()}
            isDrillable={isDrillable}
            resetOffset={0}
          >
            Load {loadMoreConfig.loadMoreSettings.rows} More...
          </LoadMore>

          {!!loadMoreConfig.loadMoreQuantity && (
            <LoadMore
              data-cy="load-more-reset"
              onClick={() => loadMoreConfig.loadMore(true)} // reset
              isDrillable={isDrillable}
              resetOffset={100}
            >
              Reset
            </LoadMore>
          )}
        </>
      )}
      <ActionsContainer
        className="details"
        data-cy="full-details-link"
        marginTop={marginTop}
        isTableVisualization={isTableVisualization}
      >
        {linksNode}

        {chart?.rowExpandVisualizationParams?.expandAllconfig && (
          <span onClick={() => setShowModal(true)}>Complete List</span>
        )}

        <div>
          {exporting || downloading?.loading ? (
            <div style={{ width: "2rem", margin: "auto", marginTop: 3 }}>
              <Loading size="small" />
            </div>
          ) : (
            <LinksContainer
              style={{ visibility: exporting ? "hidden" : undefined }}
            >
              <Export
                handleExport={handleExport}
                exportId={exportId}
                nonConvertPercentsBack={nonConvertPercentsBack}
                title={title}
                isDrillable={isDrillable}
                dispatch={dispatch}
                chart={chart}
                setExporting={setExporting}
              />

              {isApiExort && (
                <span onClick={() => exportFromApi(true)}>
                  Export with all columns
                </span>
              )}
            </LinksContainer>
          )}
        </div>

        {!hideDetailLink && (
          <Link to={`${dashboardName}/details/${visualizationId}`}>
            Full Details <FontAwesomeIcon icon={["fas", "caret-right"]} />
          </Link>
        )}

        {showModal && (
          <Modal close={closeModal} title={`${title}`} fullScreen>
            <ExportAllDrillDown
              chart={chart}
              handleExport={handleExport}
              onClose={closeModal}
              title={title}
            />
          </Modal>
        )}
      </ActionsContainer>
    </div>
  );
}
