import React from "react";

import styled from "@emotion/styled";

const TabContainer = styled.div`
  margin-right: 32px;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.9;
  padding-bottom: 4px;
  border-bottom: 2px solid
    ${(props) => (props.active ? props.theme.notification.infoMain : "none")};
  &:hover {
    opacity: 1;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  margin-bottom: 28px;
  font-weight: 300;
`;
export default function ViewTabs(props) {
  const { tabView, setTabView, isDraft } = props;
  const availableTabs = isDraft
    ? ["settings"]
    : ["columns", "filters", "sorting", "settings"];
  return (
    <TabWrapper>
      {availableTabs.map((tab) => (
        <TabContainer
          key={tab}
          onClick={() => setTabView(tab)}
          active={tabView === tab}
          data-cy="view-detail-tab"
        >
          {labels[tab]}
        </TabContainer>
      ))}
    </TabWrapper>
  );
}

const labels = {
  columns: "Column visibility",
  filters: "Filtering",
  settings: "Settings",
  sorting: "Sorting",
};
