import { useSelector } from "react-redux";
import { useMemo } from "react";
import { uniqBy } from "lodash-es";

export function useValueOptionsByFieldNameFromMenuFilters() {
  const menuFilters = useSelector((state) => state.layout.menuFilters);

  return useMemo(() => {
    return getValueOptionsByFieldNameFromMenuFilters(menuFilters);
  }, [menuFilters]);
}

function getValueOptionsByFieldNameFromMenuFilters(menuFilters) {
  const ret = {};

  const namesWithTooManyTotalValues = new Set();

  for (const menuFilter of menuFilters) {
    if (namesWithTooManyTotalValues.has(menuFilter.name)) {
      continue;
    }
    if (menuFilter.values.length < menuFilter.totalCount) {
      // The cache doesn't cover all possible values of the menu filter,
      // so its cache cannot be used.

      // Also, we want to skip all menu filters with the same name, otherwise
      // other menu filters with the same name may have a smaller length
      // result set, resulting in its cache being used, and not the application
      // not ending up using the API to fetch all the results.
      namesWithTooManyTotalValues.add(menuFilter.name);
      delete ret[menuFilter.name];

      continue;
    }

    const fieldName = menuFilter.name;

    const valueOptions = menuFilter.values;

    if (!(fieldName in ret)) {
      ret[fieldName] = valueOptions;
    } else {
      ret[fieldName] = uniqBy(
        [...ret[fieldName], ...valueOptions],
        (option) => option.value
      );
    }
  }

  return Object.keys(ret).length ? ret : null;
}

export const exportedForTesting = {
  getValueOptionsByFieldNameFromMenuFilters,
};
