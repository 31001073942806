import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Map } from "react-leaflet";
import Popup from "./components/Popup";
import TileLayer from "./components/TileLayer";

import "leaflet/dist/leaflet.css";
import { scaleSqrt } from "d3-scale";
import { extent } from "d3-array";
import CustomMarkerContainer from "./components/CustomMarkerContainer";
import MarkerClusterGroup from "./MarkerClusterGroup";
import { v4 as uuid } from "uuid";
import ChoroplethGeoJson from "./components/ChoroplethGeoJson";

export default function GeoMap(props) {
  const {
    data = [],
    height,
    sizeKey,
    colorKey,
    colorLabel,
    colorBand,
    mode,
    tooltip,
    meta,
    viewport,
    rMultiplier,
    maxCircleRadius,
    triangleShapeOptions,
    legendItems,
    isChoropleth,
    colorsConfig,
    dropMaxBounds,
    choroplethValueKey,
    countyKey,
    stateKey,
    geoJson,
    choroplethType,
    zipCodeKey,
    exponentMultiplier,
  } = props;

  const [maxBounds, setMaxBounds] = useState(null);
  const [vPort] = useState(viewport);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [activePopup, setActivePopup] = useState(null);
  const [visibleMarkers, setVisibleMarkers] = useState(
    isChoropleth ? [] : data
  );
  const ref = useRef(null);

  const minMaxValues = extent(visibleMarkers.map((d) => d[sizeKey]));
  const rScale = scaleSqrt().domain(minMaxValues).range([0.2, 40]);
  const pinData = mode === "pin" ? visibleMarkers : [];

  useEffect(() => {
    if (dropMaxBounds) {
      return setMaxBounds(null);
    }

    if (ref.current) {
      const bounds = ref.current.leafletElement.getBounds();
      const northEast = [bounds._northEast.lat, bounds._northEast.lng];
      const southWest = [bounds._southWest.lat, bounds._southWest.lng];
      setMaxBounds([northEast, southWest]); // dynamically set bounds which related to center of viewport
    }
  }, [dropMaxBounds]);

  const setMarkers = useCallback(() => {
    const markers = data.filter(({ lat, lng }) =>
      ref.current.leafletElement.getBounds().contains({ lat, lng })
    );
    setVisibleMarkers(markers);
  }, [data]);

  useEffect(() => {
    if (!isChoropleth) {
      setMarkers();
    }
  }, [data, isChoropleth, setMarkers]);

  const handleZoomEnd = () => {
    const zoom = ref.current.leafletElement.getZoom();
    setZoomLevel(zoom - 3);

    if (!isChoropleth) {
      setMarkers();
    }
  };

  const handleMoveEnd = () => {
    if (!isChoropleth) {
      setMarkers();
    }
  };

  const getRadius = useCallback(
    (circle) => {
      if (!sizeKey) {
        return rMultiplier;
      }

      if (!isNaN(+rMultiplier)) {
        const scale =
          (rScale(circle[sizeKey]) * (zoomLevel || 1)) & rMultiplier;
        return scale;
      }

      const scale = rScale(circle[sizeKey]) * (zoomLevel || 1);
      return scale > maxCircleRadius ? maxCircleRadius : scale;
    },
    [maxCircleRadius, rMultiplier, rScale, sizeKey, zoomLevel]
  );

  const MapCustomMarkers = visibleMarkers.map((d) => (
    <CustomMarkerContainer
      key={uuid()}
      d={d}
      radius={getRadius(d)}
      colorBand={colorBand}
      colorKey={colorKey}
      colorLabel={colorLabel}
      setActivePopup={setActivePopup}
      zoomLevel={zoomLevel}
      labeledColumns={tooltip?.labeledColumns}
      triangleShapeOptions={triangleShapeOptions}
      markersLength={visibleMarkers.length}
      legendItems={legendItems}
      colorsConfig={colorsConfig}
    />
  ));

  return (
    <Map
      ref={ref}
      minZoom={vPort.minZoom}
      viewport={vPort}
      maxBounds={maxBounds}
      style={{ height }}
      onzoomend={handleZoomEnd}
      onmoveend={handleMoveEnd}
    >
      <TileLayer noWrap={true} />

      {mode === "pin" && (
        <MarkerClusterGroup pinData={pinData} setActivePopup={setActivePopup} />
      )}

      {mode === "circle" && MapCustomMarkers}

      {!!(activePopup && tooltip) && (
        <Popup
          key={uuid()}
          activePopup={activePopup}
          tooltip={tooltip}
          closePopup={() => setActivePopup(null)}
          meta={meta}
          pinMode={mode === "pin"}
        />
      )}

      {isChoropleth && (
        <ChoroplethGeoJson
          data={data}
          setActivePopup={setActivePopup}
          colorBand={colorBand}
          choroplethValueKey={choroplethValueKey}
          countyKey={countyKey}
          stateKey={stateKey}
          geoJson={geoJson}
          choroplethType={choroplethType}
          zipCodeKey={zipCodeKey}
          exponentMultiplier={exponentMultiplier}
        />
      )}
    </Map>
  );
}

GeoMap.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
