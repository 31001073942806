import React, { useEffect, useState, useMemo } from "react";
import InputSet from "../../../UI/Form/InputSet/InputSet";
import { FormLabel } from "../../../UI/Form/LabelWrapper/LabelWrapper";
import usePrevious from "../../../utils/usePrevious";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { emptyArray } from "../../../utils/constants/constants";

export default function CustomOrderValues(props) {
  const {
    handleChangeValues,
    index,
    getOptions,
    name,
    values = emptyArray,
    isExpanded,
    toggleExpand,
  } = props;

  const [options, setOptions] = useState([]); // Initial empty array
  const [loading, setLoading] = useState(true);
  const prevName = usePrevious(name);

  // Fetch options when the 'name' field changes
  useEffect(() => {
    if (name === prevName) {
      return;
    }

    async function fetchOptions() {
      setLoading(true); // Start loading
      try {
        const dataOptions = await getOptions(index); // Fetch data using getOptions
        const fetchedOptions = dataOptions.map((d) => d[name]);
        setOptions(fetchedOptions); // Update options state
      } finally {
        setLoading(false); // Stop loading
      }
    }

    fetchOptions();
  }, [name, prevName, index, getOptions]);

  const optionValues = (values ?? []).map((v) => ({ label: v, value: v }));

  const availableOptions = useMemo(() => {
    return options.filter((option) => !values.includes(option));
  }, [options, values]);

  return (
    <div style={{ marginTop: 16 }}>
      {/* Header with label and toggle button */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={toggleExpand}
      >
        <FormLabel>
          Set Custom Order
          <FontAwesomeIcon
            style={{ fontSize: 14, opacity: 0.6, marginLeft: "0.5rem" }}
            icon={isExpanded ? faChevronUp : faChevronDown}
            title={`${
              isExpanded ? "Collapse" : "Expand"
            }  Custom Sorting Value Options`}
          />
        </FormLabel>
      </div>

      {/* Content to expand/collapse */}
      {isExpanded && (
        <InputSet
          cy="custom-sorting-values"
          listMode
          options={availableOptions}
          loading={loading}
          values={optionValues}
          onChange={(nextValues) => handleChangeValues(nextValues, index)}
        />
      )}
    </div>
  );
}
