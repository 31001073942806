import React, { useState } from "react";
import styled from "@emotion/styled";
import AddDot from "./AddDot";
import Flex from "../../Flex/Flex";
import PropTypes from "prop-types";
import produce from "immer";
import { isNumber } from "lodash-es";
import InputSetRow from "./InputSetRow";
import DraggableContainer from "./DraggableContainer";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: 14px;
`;

export default function InputSet(props) {
  const [adding, setAdding] = useState(!props.values.length);
  const [tempValue, setTempValue] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const { showLabels, options, listMode, loading, drag } = props;
  const [globalDragging, setGlobalDragging] = useState(false);
  const [globalEdit, setGlobalEdit] = useState(false);

  function handleChange(e, key) {
    setTempValue({ ...tempValue, [key]: e.target.value });
  }

  function commitChange(index, key, value) {
    const hasNextArray = shouldSendNewArray(index, key, value);
    if (hasNextArray) {
      const nextArray = assembleNextArray(value);

      props.onChange(nextArray);
    }
    reset();

    function assembleNextArray(value) {
      const v = value !== undefined ? value : tempValue;
      const nextArray = produce(props.values, (draft) => {
        if (isNumber(index)) {
          if (v[key] === "") {
            delete draft[index][key];
          } else {
            draft[index][key] = v[key];
          }
        } else {
          draft.push(v);
        }
      });
      return nextArray.filter((v) => v !== undefined);
    }
  }
  function shouldSendNewArray(index, key, value) {
    const v = value ?? tempValue;
    const isExistingValue = isNumber(index);

    if (isExistingValue && props.values[index][key] === v[key]) {
      return false;
    } else if (!isExistingValue && !v[key]) {
      return false;
    } else return true;
  }

  function reset() {
    setTempValue({});
    setEditingIndex(null);
    return setAdding(false);
  }

  function handleRemove(index) {
    const nextArray = props.values.filter((v, i) => i !== index);
    return props.onChange(nextArray);
  }

  function startEdit(index) {
    const initiationValue = index === undefined ? "" : props.values[index];
    setTempValue(initiationValue);
    setEditingIndex(index);
    setGlobalEdit(true);
  }

  function handleEnter(e, index, key) {
    if (e.key === "Enter" || e.code === "13") {
      e.preventDefault();
      setGlobalEdit(false);
      return commitChange(index, key);
    }
  }

  function handleAddRow() {
    setAdding(true);
  }

  function onItemsReordered(result) {
    const { source, destination } = result;
    setGlobalDragging(false);

    // If dropped outside the list or no movement detected
    if (!destination || source.index === destination.index) {
      return;
    }

    // Reorder the values
    const items = Array.from(props.values);
    const [movedItem] = items.splice(source.index, 1); // Remove the dragged item
    items.splice(destination.index, 0, movedItem); // Insert at the new position
    props.onChange(items);
  }

  const isDragDisabled = !drag;

  return (
    <Container data-cy={props.cy ?? "input-set"}>
      <div style={{ width: "100%" }}>
        <DraggableContainer
          items={props.values}
          isDragDisabled={isDragDisabled}
          onDragEnd={onItemsReordered}
          onDragStart={() => {
            setGlobalDragging(true);
          }}
          renderItem={(item, index, dragHandleProps, snapshot) => (
            <InputSetRow
              handleRemove={handleRemove}
              editingIndex={editingIndex}
              commitChange={commitChange}
              startEdit={startEdit}
              showLabels={showLabels}
              item={item}
              tempValue={tempValue}
              i={index}
              handleChange={handleChange}
              handleEnter={handleEnter}
              listMode={listMode}
              reset={reset}
              options={options}
              setTempValue={setTempValue}
              loading={loading}
              isDragDisabled={isDragDisabled}
              dragHandleProps={dragHandleProps}
              isDragging={snapshot.isDragging}
              globalDragging={globalDragging}
              globalEdit={globalEdit}
              setGlobalEdit={setGlobalEdit}
            />
          )}
        />
        {adding ? (
          <Flex
            style={{ marginBottom: 8, paddingLeft: 39, marginTop: 8 }}
            justifyContent="flex-start"
          >
            <InputSetRow
              tempValue={tempValue}
              item={null}
              addingMode
              showLabels={showLabels}
              handleChange={handleChange}
              listMode={listMode}
              options={options}
              handleEnter={handleEnter}
              setTempValue={setTempValue}
              commitChange={commitChange}
              startEdit={startEdit}
              setGlobalEdit={setGlobalEdit}
              loading={loading}
              cy="input-set-value-new"
            />
          </Flex>
        ) : null}
      </div>
      {!adding ? (
        <div style={{ marginLeft: 120 }}>
          <AddDot cy={"input-set-add"} relative onClick={handleAddRow} />
        </div>
      ) : null}
    </Container>
  );
}

InputSet.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.array,
  cy: PropTypes.string,
  drag: PropTypes.bool,
};

InputSet.defaultProps = {
  values: [],
};
