import styled from "@emotion/styled";
import TextArea from "../../../../UI/Form/Textarea/Textarea";
import Modal from "../../../../UI/Modal/Modal";
import CommentBlock from "./CommentBlock";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import { useEffect, useState } from "react";
import CommentActions from "./CommentActions";
import { useDispatch } from "react-redux";
import {
  createUpdateComment,
  deleteComment,
  getCommentsQueryTable,
} from "../../../../store/actions/dashboard/comments";
import Loading from "../../../../UI/Loading/Loading";
import { loadUserList } from "../../../../store/actions";
import { useShallowEqualSelector } from "../../../../store";
import useConfirmDelete from "../../../../hooks/useConfirmDelete";

const Container = styled.div(
  ({ theme }) => `
  width: 98%;
  color: ${theme.text.secondary};
`
);

const Comments = styled.div(
  ({ theme }) => `
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    ${scrollbarDe(theme)};
`
);

const Loader = styled.div`
  min-height: 200px;
  display: flex;
`;

export default function CommentCellModal(props) {
  const {
    commentCellData,
    setCommentCellData,
    commentCellConfiguration,
    chart,
  } = props;

  const { comments, commentActionLoading, loading, users, currentUserUuid } =
    useShallowEqualSelector((state) => ({
      users: state.userManagement.users,
      comments: state.comments.comments,
      commentActionLoading: state.comments.commentActionLoading,
      loading: state.comments.loading,
      currentUserUuid: state.auth.uuid,
    }));

  const dispatch = useDispatch();
  const [commentItem, setCommentItem] = useState(null);
  const reloadTableOnAdd = !comments.length;
  const reloadTableOnDelete = comments.length === 1;

  const confirm = useConfirmDelete(
    (uuid) => onDelete(uuid),
    "Do you want to delete this comment ?",
    null,
    null,
    8000000
  );

  useEffect(() => {
    dispatch(loadUserList());
  }, [dispatch]);

  useEffect(() => {
    if (commentCellData) {
      dispatch(getCommentsQueryTable(commentCellData));
    }
  }, [dispatch, commentCellData]);

  if (!commentCellData || !commentCellConfiguration) {
    return null;
  }

  function onCancel() {
    if (commentItem) {
      return setCommentItem(null);
    }

    setCommentCellData(null);
  }

  function onClose() {
    setCommentItem(null);
    setCommentCellData(null);
  }

  function onSave() {
    dispatch(
      createUpdateComment({
        comment: commentItem.comment,
        rowUuid: commentCellData.io_row_uuid,
        tbl: commentCellConfiguration.tbl,
        field: commentCellData.cellKey,
        commentUuid: commentItem.uuid,
        chart,
        reloadTable: reloadTableOnAdd, // only when add comment and there is no comments before
        closeModal: onClose,
      })
    );
  }

  function onDelete(uuid) {
    dispatch(deleteComment(uuid, setCommentItem, chart, reloadTableOnDelete));
  }

  const cellIndicators = commentCellConfiguration?.cellIndicators ?? [];

  return (
    <>
      <Modal close={() => setCommentCellData(null)} showClose title="Comments">
        <Container>
          {cellIndicators.map((indicator) => (
            <div style={{ fontSize: 12 }} key={indicator}>
              {(commentCellData ?? {})[indicator]}
            </div>
          ))}
          <br />
          {loading ? (
            <Loader>
              <Loading />
            </Loader>
          ) : (
            <>
              {!comments.length ? <span>No comments provided.</span> : null}

              {commentItem ? (
                <TextArea
                  label={`${commentItem.uuid ? "Edit" : "Add"} Comment`}
                  value={commentItem.comment}
                  onChange={(e) =>
                    setCommentItem({ ...commentItem, comment: e.target.value })
                  }
                />
              ) : (
                <Comments>
                  {comments.map((item) => (
                    <CommentBlock
                      block={item}
                      key={item.uuid}
                      setCommentItem={setCommentItem}
                      users={users}
                      currentUserUuid={currentUserUuid}
                      onDelete={confirm.setConfirming}
                    />
                  ))}
                </Comments>
              )}

              <br />
              <CommentActions
                onCancel={onCancel}
                commentItem={commentItem}
                setCommentItem={setCommentItem}
                onSave={onSave}
                loading={commentActionLoading}
              />
            </>
          )}
        </Container>
      </Modal>
      {confirm.confirming}
    </>
  );
}
