import { saveAs } from "file-saver";
import html2canvas from "html2canvas-pro";
import mobileBreakpoints from "../../styles/mobileBreakpoints";

const margin = 36;
const oldPosition = "absolute";
const newPosition = "relative";

const changeColumnPosition = (elements) => {
  const indexes = [];

  elements.forEach((td, index) => {
    if (window.getComputedStyle(td).position === oldPosition) {
      td.style.position = newPosition;
      indexes.push(index);
    }
  });

  return indexes;
};

const revertColumnPosition = (elements, indexes) => {
  elements.forEach((td, index) => {
    if (indexes.includes(index)) {
      td.style.position = oldPosition;
    }
  });
};

const fixTableElementsPosition = (wrapper, tagName) => {
  const elements = Array.from(wrapper.getElementsByTagName(tagName));

  return {
    elements,
    indexes: changeColumnPosition(elements),
  };
};

export const takeFullPageScreenshot = async (
  theme,
  siteName,
  pageName,
  setScreenshotMode
) => {
  const el = document.getElementById("wrapper");

  if (!el) return;

  const details = Array.from(document.getElementsByClassName("details"));

  el.style = "background:" + theme.background.primary;
  window.scrollTo(0, 0);

  details.forEach((detail) => (detail.style.display = "none"));

  const { elements: ths, indexes: thIndexes } = fixTableElementsPosition(
    el,
    "th"
  );
  const { elements: tds, indexes: tdIndexes } = fixTableElementsPosition(
    el,
    "td"
  );

  await html2canvas(el).then(function (canvas) {
    const data = canvas.toDataURL("image/png");
    save(data, siteName, pageName);

    revertColumnPosition(ths, thIndexes);
    revertColumnPosition(tds, tdIndexes);

    details.forEach((detail) => (detail.style.display = "flex"));
  });

  if (setScreenshotMode) {
    setScreenshotMode(false);
  }
};

export default async function captureElementScreenshot(id) {
  const element = document.getElementById(id)?.parentNode;
  if (!element) return;

  const blockElement = element.closest('[data-cy="block"]');
  if (!blockElement) return;

  // Clone the block title if it exists
  const blockTitle = blockElement
    .querySelector('[data-cy="block-title"]')
    ?.cloneNode(true);

  if (blockTitle) {
    element.prepend(blockTitle);
  }

  // Handle details container visibility
  const detailsContainer = blockElement.querySelector(
    '[data-cy="details-container"]'
  );

  const originalDisplay = detailsContainer?.style.display;

  if (detailsContainer) {
    detailsContainer.style.display = "none"; // Temporarily hide details
  }

  window.scrollTo(0, 0);

  // Adjust SVG height if applicable
  const svg = element.getAttribute("offset-height")
    ? element.querySelector('[data-attr="svg"]')
    : null;

  if (svg) {
    svg.style.height = mobileBreakpoints.isMobile
      ? svg.clientHeight - 40
      : svg.clientHeight - 20;
  }

  try {
    const canvas = await html2canvas(element);
    const ctx = canvas.getContext("2d");

    const width = canvas.width;
    const height = canvas.height;

    // Store image data before resizing canvas
    const imageData = ctx.getImageData(0, 0, width, height);
    ctx.clearRect(0, 0, width, height);

    // Resize canvas with margin
    canvas.width = width + margin * 2;
    canvas.height = height + margin * 2;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(imageData, margin, margin);

    const dataUrl = canvas.toDataURL("image/png");
    save(dataUrl);
  } catch (error) {
    console.error("Error capturing screenshot:", error);
  } finally {
    // Restore SVG height
    if (svg) {
      svg.style.height = mobileBreakpoints.isMobile
        ? svg.clientHeight + 40
        : svg.clientHeight + 20;
    }

    // Restore details container visibility
    if (detailsContainer) {
      detailsContainer.style.display = originalDisplay;
    }

    // Remove the cloned title from the element
    if (blockTitle) {
      element.removeChild(blockTitle);
    }
  }
}

function save(dataBlob, siteName, pageName) {
  const name =
    !siteName || !pageName
      ? "insight_out_visualization.png"
      : `${siteName} - ${pageName}.png`;

  saveAs(dataBlob, name); // FileSaver.js function
}
