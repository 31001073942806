export default function (
  colorRange,
  bar,
  color2,
  reverseBarColors,
  valueKey1,
  valueKey2
) {
  if (!colorRange) {
    return color2;
  }

  const { short, success, almost, tolerance, toleranceIsPercent } = colorRange;
  const target = +bar[valueKey1]; // Target value
  const value = +bar[valueKey2]; // Actual value

  // Calculate tolerance dynamically
  const effectiveTolerance = toleranceIsPercent
    ? (target * tolerance) / 100 // Convert percentage tolerance to absolute value
    : tolerance; // Use the provided tolerance as-is

  const condition = reverseBarColors ? target < value : target <= value;

  if (condition) {
    // Bar values are within the success range
    return reverseBarColors ? short : success;
  }

  if (reverseBarColors) {
    // For reversed bar colors, handle success vs short conditions
    return success;
  }

  // Adjust the almost range to use the effective tolerance
  if (target - effectiveTolerance < value) {
    return almost; // Close enough (within tolerance range)
  }

  // Return short when it's outside the tolerance range
  return short;
}
