import ButtonGroup from "../../../../UI/ButtonGroup/ButtonGroup";
import IOButton from "../../../../UI/Form/Button/IOButton";

export default function CommentActions(props) {
  const { onCancel, commentItem, setCommentItem, onSave, loading } = props;

  return (
    <ButtonGroup spacing={10} position="flex-end">
      <IOButton cancel smallPadding type="button" onClick={onCancel}>
        Cancel
      </IOButton>
      {!commentItem && (
        <IOButton
          smallPadding
          type="button"
          add
          outline
          onClick={() => setCommentItem({ text: "" })}
        >
          Add New
        </IOButton>
      )}
      {commentItem && (
        <IOButton
          smallPadding
          type="button"
          onClick={onSave}
          disabled={!commentItem.comment}
          processing={loading}
        >
          Save
        </IOButton>
      )}
    </ButtonGroup>
  );
}
