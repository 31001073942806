import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import MobileLogoutBlock from "./MobileLogoutBlock";
import HomeLogo, { defaultLogoSrc } from "./HomeLogo";
import { useSelector } from "react-redux";
import LeftMenuWrapper from "./LeftMenuWrapper";
import FilterSection from "./FilterSection";
import ResetFilters from "../Filters/ResetFilters";
import CustomDownloads from "./CustomDownloads";
import interruptDashboard from "../Block/interruptDashboard";
import { useShallowEqualSelector } from "../../store";
import SaveFiltersToApi from "../Filters/RememberSettings/SaveFiltersToApi";
import Flex from "../../UI/Flex/Flex";
import { useIsSingleQueryFiltersEnabledOnThisPage } from "./useSingleDataSourceFilters";

const Home = styled.div(
  ({ theme }) => `
    color: ${theme.text.secondary};
    padding: 12px 28px;
    text-align: center;
    cursor: pointer;
    @media (max-width: 800px) {
        position: relative;
        top: -30px;
        margin-bottom: -28px;
        pointer-events: none;
    }
`
);

export default function LeftMenu(props) {
  const {
    filters = [],
    logo,
    domain,
    booleanFilters,
    currentTabId,
    hidden,
    dualPage,
    dualType,
    select,
    handleLogout,
    setShow,
    selectBoolean,
    recentMonthsChange,
    dateConfig,
    selectPowerEditorModeFilter,
    userSettings,
  } = props;

  const [showDateFilters] = useState(true);

  const {
    dataSourceAccessConfig,
    additionalTheme,
    comparisonMode,
    activeTab,
    searchFilterLoading,
  } = useShallowEqualSelector((state) => state.layout);

  const menuFilterShortList = useSelector(
    (state) => state.layout.menuFilterValueShortlists
  );

  const comparisonModeOnPage = interruptDashboard(
    comparisonMode,
    activeTab?.uuid,
    true
  );

  const logoSrc = logo ?? defaultLogoSrc;

  const filtersOnPage = (filters || []).filter(
    (filter) => !(filter.hide || []).includes(activeTab?.uuid)
  );

  const currentPageUuid = currentTabId ?? activeTab?.uuid;
  const isSingleQueryFiltersEnabledOnThisPage =
    useIsSingleQueryFiltersEnabledOnThisPage(currentPageUuid);

  return (
    <LeftMenuWrapper>
      {!selectPowerEditorModeFilter && (
        <>
          <MobileLogoutBlock handleLogout={handleLogout} setShow={setShow} />
          <Home data-cy="site-logo-container" data-login-result>
            <NavLink to="/">
              <HomeLogo src={logoSrc} />
            </NavLink>
          </Home>
        </>
      )}

      {!hidden && (
        <>
          <FilterSection
            filters={filters}
            currentTabId={currentPageUuid}
            dataSourceAccessConfig={dataSourceAccessConfig}
            menuFilterShortList={menuFilterShortList}
            showDateFilters={showDateFilters}
            dateConfig={dateConfig}
            comparisonMode={comparisonModeOnPage ? comparisonMode : null}
            domain={domain}
            dualPage={dualPage}
            dualType={dualType}
            recentMonthsChange={recentMonthsChange}
            select={select}
            booleanFilters={booleanFilters}
            selectBoolean={selectBoolean}
            additionalTheme={additionalTheme}
            userSettings={userSettings}
            selectPowerEditorModeFilter={selectPowerEditorModeFilter}
            searchFilterLoading={searchFilterLoading}
          />

          {filtersOnPage.length > 0 && (
            <Flex
              direction="column"
              justifyContent="space-between"
              flexGrow={1}
            >
              <ResetFilters
                selectPowerEditorModeFilter={selectPowerEditorModeFilter}
              />
              {!isSingleQueryFiltersEnabledOnThisPage ? (
                <SaveFiltersToApi filters={filters} activeTab={activeTab} />
              ) : null}
            </Flex>
          )}

          <CustomDownloads domain={domain} />
        </>
      )}
    </LeftMenuWrapper>
  );
}
