import React from "react";
import Switcher from "../../../../UI/Switcher/Switcher";
import ActiveTableOptionsSettings from "./ActiveTableOptionsSettings";
import ActiveTableDropdown from "../../Grid/ActiveTableDropdown";
import ActiveTableLoadFromDataSettings from "./ActiveTableLoadFromDataSettings";

export default function ActiveTableOptions({
  colDef,
  setOptions,
  produceColumn,
  columnErrorsObject,
  setColumnError,
}) {
  const userDefined = !colDef.cellEditorParams?.loadFromData;

  return (
    <div>
      <div style={{ padding: "0.5rem 0" }}>
        <Switcher
          leftLabel="Load from data"
          rightLabel="User defined"
          handleSwitchChange={() =>
            produceColumn((column) => {
              if (!column.cellEditorParams.loadFromData) {
                column.cellEditorParams.loadFromData = true;
                column.cellEditor = ActiveTableDropdown;
              } else {
                delete column.cellEditorParams.loadFromData;
                column.cellEditor = "agSelectCellEditor";
              }
            })
          }
          checked={userDefined}
        />
      </div>

      {userDefined ? (
        <ActiveTableOptionsSettings colDef={colDef} setOptions={setOptions} />
      ) : (
        <ActiveTableLoadFromDataSettings
          colDef={colDef}
          produceColumn={produceColumn}
          columnErrorsObject={columnErrorsObject}
          setColumnError={setColumnError}
        />
      )}
    </div>
  );
}
