import { aggPrefixByNumber, aggregationNumber } from "./helpers";

export default function (valueKeys, overrides = [], meta) {
  if (!Array.isArray(valueKeys) || valueKeys.length === 0)
    return [{ name: null, alias: null }];

  // Prepare overrides with properly computed aliases
  const prepareOverrideAliases = (overrides) =>
    overrides.map((override) => ({
      ...override,
      alias: override.aggregation
        ? makeAliasFromName(override.name, override.aggregation)
        : override.ops?.alias || override.name,
    }));

  const overridesWithAliases = prepareOverrideAliases(overrides ?? []);
  const yKeys = valueKeys.map(
    ({ alias, name, valueKey }) => alias || name || valueKey
  ); // `valueKey` to be deprecated

  // Main mapping logic for attributes
  return yKeys.map((key, index) => {
    const overrideMatch = overridesWithAliases.find(
      (o) => o.alias === key || o.name === key
    );

    // Find metadata field match
    const metaField = meta?.fields?.find(
      (field) => overrideMatch?.alias === field.alias
    );

    const { valueKey, title, numberFormat, valueColor, ...restAttrs } =
      valueKeys[index];
    const overrideFormat = numberFormat || overrideMatch?.mapping?.type;
    const overrideLabel = overrideMatch?.mapping?.displayName;

    // Construct the next attribute object with prioritized values
    const nextAttr = {
      ...restAttrs,
      ...(valueColor && { extras: { valueColor } }),
      alias: overrideMatch?.alias || metaField?.alias || key,
      name: metaField?.name || overrideMatch?.name,
      aggregation: overrideMatch?.aggregation,
      label: title || metaField?.label || overrideLabel,
      format: overrideFormat || metaField?.type,
      overrideLabel: !!overrideLabel,
      overrideFormat: !!overrideFormat,
    };

    // Handle operational formulas if present
    if (overrideMatch?.ops) {
      const { fields, type, aggregation } = overrideMatch.ops;
      nextAttr.opsFormula = true;
      nextAttr.formulaField = fields?.[0];
      nextAttr.formulaOperator = type;
      nextAttr.aggregation = aggregation;
    }

    return nextAttr;
  });
}

export function makeAliasFromName(name, aggregation) {
  const aggNumber = aggregationNumber(aggregation);
  return (aggPrefixByNumber[aggregationNumber(aggNumber)] || "") + name;
}
