import Select from "../../../../UI/Form/Select/Select";
import Switcher from "../../../../UI/Switcher/Switcher";
import { getBlendedQueryFieldLableWithAlias } from "../Layout/helper";
import { PinRowContainer } from "./PinnedRows/PinnedRowItem";

export function ExcludeFromDrilldown({ chart, setChartState, fields, data }) {
  const { excludeFromDrilldownSettings } = chart;

  const { key, values } = excludeFromDrilldownSettings ?? {};

  const dataOptions = data.map((row) => ({ value: row[key], label: row[key] }));

  const valueOptions = dataOptions.filter((option) =>
    (values ?? []).includes(option.value)
  );

  function onKeyChange(option) {
    setChartState({
      ...chart,
      excludeFromDrilldownSettings: {
        ...excludeFromDrilldownSettings,
        key: option.name,
      },
    });
  }

  function onValuesChange(options) {
    setChartState({
      ...chart,
      excludeFromDrilldownSettings: {
        ...excludeFromDrilldownSettings,
        values: (options ?? []).map((option) => option.value),
      },
    });
  }

  return (
    <>
      <Switcher
        rightLabel="Exclude rows from Drilldown"
        checked={!!excludeFromDrilldownSettings}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            excludeFromDrilldownSettings: !excludeFromDrilldownSettings
              ? {}
              : null,
          })
        }
      />

      <br />

      {excludeFromDrilldownSettings && (
        <PinRowContainer>
          <Select
            options={fields}
            getOptionLabel={(option) =>
              getBlendedQueryFieldLableWithAlias(option)
            }
            getOptionValue={(option) => option.name}
            simpleValue={key}
            label="Key"
            onChange={onKeyChange}
          />

          <br />

          {key && (
            <Select
              options={dataOptions}
              value={valueOptions}
              label="Values"
              isMulti
              isClearable={false}
              onChange={onValuesChange}
            />
          )}
          <br />
        </PinRowContainer>
      )}
    </>
  );
}
