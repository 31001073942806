import { useCallback } from "react";

/**
 * Adapts a react-hook-form form to a plain React useState() pair.
 */
export default function ({ getValues, reset, watch }) {
  const formState = watch();

  const setFormState = useCallback(
    (valueOrCallback) => {
      const value =
        valueOrCallback instanceof Function
          ? valueOrCallback(getValues())
          : valueOrCallback;
      reset(value);
    },
    [getValues, reset]
  );

  return [formState, setFormState];
}
