import formatter from "../../../utils/formatters/formatter";
import getVisualizationLabel from "../../../utils/getVisualizationLabel";
import Target from "../../utils/Target";

export default function tableNewFormulaFunctionConvertor(
  str = "",
  row,
  meta,
  periodCalculationSettings
) {
  const [, formula, format, A, B, C, D, E, F] = str
    .replace("ReverseColor::", "")
    .replace("ROUNDED::", "")
    .replace("BORDERED::", "")
    .split("::");
  const fields = meta?.fields ?? [];

  switch (formula) {
    // [A + B]
    case "ADDITION": {
      const val = +row[A] + +row[B];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `${definition(A)} + ${definition(B)}`,
      };
    }

    // [A - B]
    case "DIFFERENCE": {
      const val = row[A] - row[B];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `${definition(A)} - ${definition(B)}`,
      };
    }

    // [A / B]
    case "DIVISION": {
      const val = row[A] / row[B];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `${definition(A)} / ${definition(B)}`,
      };
    }

    // [(B - A) / A]
    case "PERCENT_DIFFERENCE": {
      const val = (row[B] - row[A]) / row[A];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `(${definition(B)} - ${definition(A)}) / ${definition(A)}`,
      };
    }

    // [A / B - 1]
    case "RELATIVE_CHANGE": {
      const val = row[A] / row[B] - 1;
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `${definition(A)} / ${definition(B)} - 1`,
      };
    }

    // [1 - A / B]
    case "PROPORTIONAL_DECREASE": {
      const val = 1 - row[A] / row[B];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `1 - ${definition(A)} / ${definition(B)} - 1`,
      };
    }

    // [(A - B) / C]
    case "NORMALIZED_DIFFERENCE": {
      const val = (row[A] - row[B]) / row[C];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `(${definition(A)} - ${definition(B)}) / ${definition(C)}`,
      };
    }

    // [(A / B) - (C / D)]
    case "DIFFERENCE_OF_RATIOS": {
      const val = row[A] / row[B] - row[C] / row[D];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `(${definition(A)} / ${definition(B)}) - (${definition(
          C
        )} / ${definition(D)})`,
      };
    }

    // [(A / (A + B)) - (C / (C + D))]
    case "DIFFERENCE_OF_PROPORTIONS": {
      const val = row[A] / (row[A] + row[B]) - row[C] / (row[C] + row[D]);
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `(${definition(A)} / (${definition(A)} + ${definition(
          B
        )})) - (${definition(C)} / (${definition(C)} + ${definition(D)}))`,
      };
    }

    // [A - (B / C)]
    case "ABSOLUTE_DIFFERENCE": {
      const val = row[A] - row[B] / row[C];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `${definition(A)} - (${definition(B)} / ${definition(C)})`,
      };
    }

    // Ratio [A / (B + C)]
    case "RATIO": {
      const val = row[A] / (row[B] + row[C]);
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `${definition(A)} / (${definition(B)} + ${definition(C)})`,
      };
    }

    // [(A / (B + C)) - (D / (E + F))]
    case "ABSOLUTE_DIFFERENCE_BETWEEN_RATIOS": {
      const val = row[A] / (row[B] + row[C]) - row[D] / (row[E] + row[F]);
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `(${definition(A)} / (${definition(B)} + ${definition(
          C
        )})) - (${definition(D)} / (${definition(E)} + ${definition(F)}))`,
      };
    }

    // [A / (B - C)]
    case "RATIO_TO_DIFFERENCE": {
      const val = row[A] / (row[B] - row[C]);
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `${definition(A)} / (${definition(B)} - ${definition(C)})`,
      };
    }

    // [(A / B) / (C / D)]
    case "RELATIVE_RATIO": {
      const val = row[A] / row[B] / row[C] / row[D];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `(${definition(A)} / (${definition(B)}) / (${definition(
          C
        )} / ${definition(D)})`,
      };
    }

    // [((A / B) - (C / D)) / (C / D)]
    case "RELATIVE_DIFFERENCE": {
      const val = (row[A] / row[B] - row[C] / row[D]) / (row[C] / row[D]);
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `((${definition(A)} / (${definition(B)}) - (${definition(
          C
        )} / ${definition(D)})) / (${definition(C)}) / ${definition(D)})`,
      };
    }

    // [(A - B) / B - (C - D) / D]
    case "DIFFERENCE_OF_RELATIVE_CHANGES": {
      const val = (row[A] - row[B]) / row[B] - (row[C] - row[D]) / row[D];
      const notNumber = isNaN(val) || !isFinite(val);
      const formatted = notNumber ? "--" : formatter(val, format);

      return {
        formatted,
        value: notNumber ? "--" : val,
        align: "right",
        description: `((${definition(A)} - (${definition(B)}) / ${definition(
          B
        )}) - ((${definition(C)} - ${definition(D)}) / ${definition(D)})`,
      };
    }

    // (Current Month A / Current Month B - Previous Month A / Previous Month B)
    case "PERIOD_DIFFERENCE": {
      const { groupingKey, rowValues = [] } = periodCalculationSettings;

      const desc1 = getDescriptionWithPrefix(currPeriodPrefix, A);
      const desc2 = getDescriptionWithPrefix(currPeriodPrefix, B);
      const desc3 = getDescriptionWithPrefix(prevPeriodPrefix, A);
      const desc4 = getDescriptionWithPrefix(prevPeriodPrefix, B);

      const description = `${desc1} / ${desc2} - ${desc3} / ${desc4}`;

      // set description anyway
      const withDescription = {
        ...initialEmptyDataObject,
        description,
      };

      // Find the index of the current row in rowValues based on the groupingKey
      const currentIndex = rowValues.findIndex(
        (item) => item[groupingKey] === row[groupingKey]
      );

      // If the current row is the first one, return initialEmptyDataObject
      if (!currentIndex || currentIndex === -1) {
        return withDescription;
      }

      // Get the previous row
      const prevRow = rowValues[currentIndex - 1];

      // Calculate the value based on the specified formula
      const value = row[A] / row[B] - prevRow[A] / prevRow[B];

      if (isNaN(value) || !isFinite(value)) {
        return withDescription;
      }

      return {
        formatted: formatter(value, format),
        value,
        align: "right",
        description,
      };
    }

    case "BAD_DEBT": {
      const metTarget = +row[A] >= +row[B];

      if (metTarget) {
        return {
          formatted: "100%",
          value: 1,
          align: "right",
        };
      } else {
        const target = new Target(row[A], row[B], "%", 1);
        target.setInvertSuccess();
        target.setNewValue(1 - target.value);

        return {
          formatted: target.formatted,
          value: target.value,
          align: "right",
          description: `If ${A} great or equal to ${B} then 100%, else ${B} / ${A}`,
        };
      }
    }

    case "GROUPED_MONTHS": {
      return {
        value: row[A],
        formatted:
          row[A] <= 6
            ? "0-6 Months"
            : row[A] <= 12
            ? "6-12 Months"
            : "12+ Months",
        align: "left",
        description: null,
      };
    }

    default:
      return {
        formatted: formatter(A, format),
        value: row[A] || "--",
        align: "right",
        description: `${definition(A)}`,
      };
  }

  function definition(column) {
    const label = getVisualizationLabel(fields, column);
    if (!label) {
      return column;
    }

    return label
      .replace(/^(Sum)/, "")
      .replace(/^(Avg)/, "")
      .trim();
  }

  function getDescriptionWithPrefix(prefix, column) {
    return prefix + definition(column);
  }
}

const initialEmptyDataObject = {
  formatted: "--",
  value: null,
  align: "right",
  description: "",
};

// Prefix for labeling the current period, e.g., "Current Month" or "Current Year"
const currPeriodPrefix = "Current ";

// Prefix for labeling the previous period, e.g., "Previous Month" or "Previous Year"
const prevPeriodPrefix = "Previous ";
