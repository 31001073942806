import React, { useMemo, useState } from "react";
import StackedBarTwo from "./StackedBarTwo";
import VisualizationBase from "../BaseChart/VisualizationBase";
import Details from "../../Layout/Details/Details";
import { unique } from "../../utils/func";
import Legend from "../Legend/Legend";
import xKeyParser, { getXAxisFormat } from "../../utils/charts/xKeyParser";

import ChartActionsToggle from "../ChartActions/ChartActionsToggle";
import prepareStackedData from "./prepareStackedData";
import styled from "@emotion/styled";
import { getDataReducer } from "./getDataReducer";
import conformStackData from "./conformStackData";
import mapColorConfig from "../BaseChart/mapColorConfig";
import mapAxisSettings from "../BaseChart/mapAxisSettings";
// import { getRidOfAggregation } from "../TableView/Elements/EditableMenu";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 *
 * @param props
 * @param props.yValueKeys This is an array of keys going into the Y values and determines what the stack components
 *  should be made of. Each item in this array can either be a string or an array.
 *  - If an item is a string
 *  then each row has a values for a single stack component at a time (but possibly for multiple groups), and the
 *  string acts as the filter/discriminator that tells you if that row of data belongs to that component stack.
 *  - If an item is an array, then that means that all stack components for a group appear in a single row.
 *    This array can in turn consist of either:
 *      1. an object with {"source": string, "target": string} where "source" is the key to take the data from and
 *          "target" is what stack component should be mapped to
 *      2. a simple string as a shorthand for if "source" and "target" would be the same.
 *      props.yValueKeys[0] is the main stack key
 *      props.yValueKeys[1] is the secondary stack key (last year, etc)
 * @returns {JSX.Element}
 * @constructor
 */
export default function StackedBarTwoVisualization(props) {
  const { chart, details, dashboardId, term } = props;
  const {
    data: rawData,
    xKey,
    xAxisDate,
    xKeyFormat,
    breakdownKey: propsBreakdownKey,
    yValueKeys: staticValueKeys, // Grouping / Bar sections
    overValue,
    overValueValues,
    visualizationId,
    ignoreDateTerm,
    xSort,
    xSortKey,
    yLabelFormat,
    yFormat,
    yAxisFormat,
    ySortReverse,
    meta,
    legendItems,
    orderByLegendNames,
    hideBarLabels,
    variableValueKeys, // how do these work? Need Storybook entry
    yKeys,
    legendHeight,
    hideLegend,
    lineYKeys: propsLineYKeys,
    circleYKeys,
    dashedLineYKeys,
    negativeYKeys: propsNegativeYKeys,
    valueKeys,
    yKey: propsYKey,
    groupBy,
    maxYScale,
    useSameYAxisScale,
    forceMultiColor,
    colors,
    yRightAxisFormat,
    relativeY,
    yAxisTypeLabel,
  } = chart;

  const margin = {
    top: 30,
    left: 52,
    bottom: 62,
    right: useSameYAxisScale ? 20 : 50,
  };

  const [activeValueKey, setActiveValueKey] = useState(
    variableValueKeys?.length && variableValueKeys[0]
  );

  const { yKey, yValueKeys, data, breakdownKey, lineYKeys, negativeYKeys } =
    conformStackData(
      rawData,
      staticValueKeys,
      valueKeys,
      activeValueKey,
      propsYKey,
      groupBy,
      propsBreakdownKey,
      propsLineYKeys,
      yKeys,
      propsNegativeYKeys,
      xKey
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lineKeys = lineYKeys ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const circleKeys = circleYKeys ?? [];
  const dashedKeys = dashedLineYKeys ?? [];

  const mappedXKey = xKeyParser(term, xKey, ignoreDateTerm);

  const sortedData = data.filter((d) => (!yKey ? d : d[yKey]));

  if (xSort) {
    const keyToSortWith = xSortKey || mappedXKey;
    sortedData.sort((a, b) =>
      a[keyToSortWith] < b[keyToSortWith]
        ? -1
        : a[keyToSortWith] > b[keyToSortWith]
        ? 1
        : 0
    );
  }

  const yValueKeyForTotals = yValueKeys ? yValueKeys[0] : null;

  const allKeysWithTotals = useMemo(() => {
    return sortedData.reduce(
      getDataReducer(yValueKeyForTotals, breakdownKey, null),
      {}
    );
  }, [breakdownKey, sortedData, yValueKeyForTotals]);

  const mappedWithTotals = Object.entries(allKeysWithTotals).map((e) => ({
    segmentKey: e[0],
    value: e[1],
  }));

  mappedWithTotals.sort((a, b) => {
    if (a.value === b.value) {
      return 0;
    }

    if (a.value < b.value) {
      return ySortReverse ? -1 : 1;
    }

    return ySortReverse ? 1 : -1;
  });

  //@todo this can be removed
  if (legendItems) {
    const legendKeys = legendItems.map((v) => v.name);
    mappedWithTotals.sort((a, b) => {
      const indexA = legendKeys.indexOf(a.segmentKey);
      const indexB = legendKeys.indexOf(b.segmentKey);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // These need to happen for both versions
  const allXKeys = unique(sortedData.map((d) => d[mappedXKey])).filter(
    (v) => v !== undefined
  );

  // this need tp keep order like in legend config
  const names = orderByLegendNames ? legendItems.map((li) => li.name) : null;

  const { keys, convertedArray, totals } = prepareStackedData(
    names,
    mappedWithTotals,
    allXKeys,
    sortedData,
    mappedXKey,
    yValueKeys,
    breakdownKey,
    yKeys
  );

  // @todo refactor this out
  const tempKeys = valueKeys?.length
    ? valueKeys
    : yValueKeys.map((v) => ({ alias: v }));

  const colorKeys = [
    ...yValueKeys,
    ...lineYKeys.map((l) => l.alias),
    ...negativeYKeys,
  ];

  if (!groupBy) {
    colorKeys.sort((a, b) => {
      // Find the indices of `a` and `b` in `valueKeys` based on the `alias` property
      const indexA = (valueKeys ?? []).findIndex((item) => item.alias === a);
      const indexB = (valueKeys ?? []).findIndex((item) => item.alias === b);

      return indexA - indexB; // Sort based on their indices
    });
  }

  const singleBarColorMode =
    yValueKeys.length === 1 && !groupBy && forceMultiColor;
  const colorArray = mapColorConfig(
    colorKeys,
    legendItems,
    singleBarColorMode,
    allXKeys,
    colors
  );

  const { yLabelText } = mapAxisSettings(
    groupBy,
    valueKeys,
    yAxisTypeLabel,
    null,
    meta
  );

  const xAxisFormat = getXAxisFormat(xKeyFormat, xKey, meta);

  return (
    <>
      <Container id={visualizationId}>
        <VisualizationBase
          {...{ ...props, margin }} // we should be able to add this directly to VisualizationBase
          tooltipConfig={chart.tooltipConfig}
        >
          <StackedBarTwo
            {...chart}
            data={convertedArray}
            keys={keys}
            xKeys={allXKeys}
            xAxisDate={xAxisDate}
            xAxisFormat={xAxisFormat}
            yAxisFormat={yAxisFormat}
            yRightAxisFormat={yRightAxisFormat}
            xKeyFormat={xKeyFormat}
            colors={colorArray}
            yFormat={yFormat}
            summedValues={totals}
            overValue={overValue}
            overValueValues={overValueValues}
            yLabelFormat={yLabelFormat}
            legendItems={legendItems}
            hideBarLabels={hideBarLabels}
            initialData={data}
            circleKeys={circleKeys}
            lineKeys={lineKeys}
            dashedKeys={dashedKeys}
            yValueKeys={yValueKeys}
            negativeYKeys={negativeYKeys}
            maxYScale={maxYScale}
            valueKeys={tempKeys}
            meta={meta}
            breakdownKey={breakdownKey}
            relativeY={relativeY && !groupBy}
            yAxisTypeLabel={yLabelText}
          />
        </VisualizationBase>
        <Legend
          colors={colorArray}
          horizontal
          sections={colorKeys}
          wrapping
          meta={meta}
          legendHeight={legendHeight}
          hide={hideLegend || singleBarColorMode}
        />
        <ChartActionsToggle
          variableValueKeys={variableValueKeys}
          setVariableValueKey={setActiveValueKey} // what does this do?
          activeValueKey={activeValueKey} // what does this do?
        />
      </Container>
      {details && chart.data.length > 0 ? (
        <Details
          dashboardName={dashboardId}
          visualizationId={visualizationId}
          chart={chart}
        />
      ) : null}
    </>
  );
}
