import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import QuarterRange from "./QuarterRange";
import SingleDay from "./SingleDay";
import CustomRange from "./CustomRange";
import FutureDaysAndEarlier from "./FutureDaysAndEarlier";
import RollingDates from "./RollingDates";

const Container = styled.div(
  ({ theme }) => `
    margin-top: 24px;
    left: 6px;
    & > span {
        color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.primary};
        padding: 0px 12px;
        font-size: 0.7em;
        margin-left: 10px;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`
);

export default function CustomDateFilters(props) {
  const theme = useTheme();
  const rollingDates = props.dateFiltersConfig?.rollingDates ?? {};

  // Helper function to create reusable date filter components
  const createRollingDateElement = ({ type, toDateType, label }) => (
    <RollingDates
      selectProps={type ? props.selectProps : null}
      type={type}
      recentMonthsChange={props.recentMonthsChange}
      toDateType={toDateType}
      label={label}
      defaultRollingDate={rollingDates[toDateType]}
      defaultRollingDateCount={rollingDates[toDateType]?.count}
      {...props}
    />
  );

  // Predefined date filter components
  const defaultDateFilters = {
    sd: <SingleDay Container={Container} theme={theme} {...props} />,
    cr: (
      <Container>
        <CustomRange {...props} />
      </Container>
    ),
    fiscalQuarterRange: <QuarterRange {...props} />,
    past3YearsToNextDays: (
      <Container>
        <FutureDaysAndEarlier {...props} />
      </Container>
    ),
  };

  // Adding rolling list items dynamically
  const customElements = rollingList.reduce((acc, curr) => {
    acc[curr.key] = createRollingDateElement(curr);
    return acc;
  }, defaultDateFilters);

  // Return the matching date filter component or null if not found
  return customElements[props.dateType] ?? null;
}

export const rollingList = [
  { key: "rollingDays", toDateType: "ROLLING_DAYS", label: "Day" },
  {
    key: "rollingDaysToDate",
    toDateType: "ROLLING_DAYS_TO_DATE",
    label: "Day",
  },
  {
    key: "rollingWeeks",
    toDateType: "ROLLING_WEEKS",
    type: "rw",
    label: "Week",
  },
  {
    key: "rollingWeeksToDate",
    toDateType: "ROLLING_WEEKS_TO_DATE",
    label: "Week",
  },
  {
    key: "rollingMonths",
    toDateType: "ROLLING_MONTHS",
    type: "rm",
    label: "Month",
  },
  {
    key: "rollingMonthsToDate",
    toDateType: "ROLLING_MONTHS_TO_DATE",
    label: "Month",
  },
  {
    key: "rollingQuarters",
    toDateType: "ROLLING_QUARTERS",
    type: "rq",
    label: "Quarter",
  },
  {
    key: "rollingQuartersToDate",
    toDateType: "ROLLING_QUARTERS_TO_DATE",
    label: "Quarter",
  },
  {
    key: "rollingYears",
    toDateType: "ROLLING_YEARS",
    type: "ry",
    label: "Year",
  },
  {
    key: "rollingYearsToDate",
    toDateType: "ROLLING_YEARS_TO_DATE",
    label: "Year",
  },
];
