import { warehouseImagesBaseUrl } from "../../../../utils/env";
import React from "react";
import FileViewerModal from "./FileViewerModal";
import { contentHeight } from "./common";

export default function WordExcelViewer({
  filePath,
  close,
  edit,
  download,
  downloading,
}) {
  const fileUrl = `${warehouseImagesBaseUrl}/${filePath}`;

  const src = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
    fileUrl
  )}`;

  return (
    <div>
      <FileViewerModal
        close={close}
        edit={edit}
        download={download}
        downloading={downloading}
      >
        <iframe
          title="Document viewer"
          style={{ height: contentHeight }}
          width="100%"
          src={src}
        />
      </FileViewerModal>
    </div>
  );
}
