import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import tableFunctionConvertor from "../TableView/functions/tableFunctionConvertor";
import { buildCardBasedOnFormula } from "../../utils/resultsMapper";
import { last } from "../../utils/func";
import trendFormula from "./trendFormula";
import formatter from "../../utils/formatters/formatter";
import getVisualizationLabel, {
  getMappingType,
} from "../../utils/getVisualizationLabel";

const Container = styled.div(
  ({
    theme,
    isNegative,
    colored,
    chipStyle,
    hasLabel,
    isVertical,
    sidePosition,
  }) => `
  display: flex;
  color: ${
    chipStyle
      ? "rgba(255, 255, 255, 0.9)"
      : isNegative
      ? theme.notification.errorMain
      : theme.notification.successMain
  };
  background: ${
    chipStyle
      ? isNegative
        ? theme.notification.errorMain
        : theme.notification.successMain
      : "none"
  };
  margin-top: ${chipStyle ? "10px" : 0};
  color: ${!colored && theme.text.secondary};
  justify-content: ${hasLabel ? "space-between" : "center"};
  font-size: 12px;
  padding:${chipStyle ? 4 : 2}px;
  width: ${sidePosition ? "80px" : hasLabel ? "130px" : "auto"};
  border-radius: 4px;
  svg {
    margin: 0px 5px;
  }
  ${
    sidePosition &&
    `
    position: relative;
    top: -80px;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    line-height: 150%;
    margin-bottom: -32px;
 
  `
  }
    
    ${
      isVertical &&
      `
      flex-direction: column;
      justify-content: center;
      width: 100%;
  `
    }
`
);

const Label = styled.span(
  ({ theme, isVertical }) => `
 color: ${theme.text.secondary};
 margin: ${isVertical ? "5px 0px" : "0px"};
`
);

export default function Trend({
  trendValues,
  data = [],
  valueKey,
  arrayFormula,
  fields,
}) {
  const {
    formulas,
    withIcon,
    colored,
    chipStyle,
    specialConfig,
    verticalDirection,
    sidePosition,
    formulaConfig,
    format,
    emptyLabel,
  } = trendValues;

  if (!data[0]) {
    return null;
  }

  function renderContainer(label, value, formatted, key = "") {
    const isNegative = value < 0;
    const icon = isNegative ? "arrow-alt-down" : "arrow-alt-up";

    return (
      <Container
        isNegative={isNegative}
        colored={colored}
        chipStyle={chipStyle}
        hasLabel={!!label}
        isVertical={verticalDirection}
        sidePosition={sidePosition}
        key={key}
      >
        {label && <Label isVertical={verticalDirection}>{label}</Label>}
        <div>
          {!!(withIcon && value !== null) && (
            <FontAwesomeIcon icon={["fas", icon]} />
          )}
          {formatted}
        </div>
      </Container>
    );
  }

  if (formulaConfig) {
    const value = trendFormula(data, formulaConfig);
    const label = formulaConfig.label || "Change";
    const formatted = formatter(value, format);
    return renderContainer(label, value, formatted);
  }

  function configureNonFormulaTrendContainer(row, formula, key) {
    const label = getVisualizationLabel(fields, formula);
    const type = getMappingType(fields, formula);
    const formatted = formatter(row[formula], type);

    return renderContainer(
      emptyLabel ? "" : label,
      row[formula],
      formatted,
      key
    );
  }

  return (
    <>
      {formulas.map((formula, i) => {
        const row = buildCardBasedOnFormula(
          data,
          specialConfig,
          valueKey,
          i,
          arrayFormula
        );

        const key = formula + i;

        if (!String(formula).includes("::")) {
          return configureNonFormulaTrendContainer(row, formula, key);
        }

        const tfc = tableFunctionConvertor(formula, row);
        const label = last(formula.split("::"));

        return renderContainer(label, tfc.value, tfc.formatted, key);
      })}
    </>
  );
}
