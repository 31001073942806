import styled from "@emotion/styled";
import UserChip from "../../../../UI/UserChip/UserChip";
import Flex from "../../../../UI/Flex/Flex";
import { format } from "date-fns";
import ButtonLink from "../../../../UI/ButtonLink/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div(
  ({ theme }) => `
  border: 1px solid ${theme.divider};
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 20px;
  color: ${theme.text.secondary};
`
);

const InlineText = styled.span(
  ({ fontStyle }) => `
  margin-left: 10px;
  font-style: ${fontStyle};
`
);

const Header = styled.div(
  ({ theme }) => `
  background: ${theme.blueGray.blueGray600};
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -10px;
  padding: 5px 10px 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  svg {
    color: ${theme.notification.errorMain};
    cursor: pointer;
    margin-left: 5px;
    &:hover {
      color: ${theme.notification.errorLight};
    }
  }
`
);

const CommentDiv = styled.div`
  margin-top: 5px;
`;

export default function CommentBlock({
  block,
  setCommentItem,
  users,
  currentUserUuid,
  onDelete,
}) {
  const { io_user_uuid, io_created_at, io_updated_at, comment } = block;

  const { email } = users.find((user) => user.uuid === io_user_uuid) ?? {
    email: "System Admin",
  };

  return (
    <Container>
      <Header>
        <Flex alignItems="center">
          <UserChip name={email} fontSize={14} width={22} height={22} />
          <InlineText>{email}</InlineText>
          <InlineText fontStyle="italic">
            created at:&nbsp; {format(io_created_at, "MMM dd, yyyy")}
          </InlineText>
          <InlineText fontStyle="italic">
            updated at:&nbsp; {format(io_updated_at, "MMM dd, yyyy")}
          </InlineText>
        </Flex>
        {/* Only the user who created the comment can update or delete it. */}
        {currentUserUuid === io_user_uuid ? (
          <Flex alignItems="center">
            <ButtonLink onClick={() => setCommentItem(block)}>EDIT</ButtonLink>
            <FontAwesomeIcon
              icon={["fas", "times-circle"]}
              onClick={() => onDelete(block.uuid)}
            />
          </Flex>
        ) : null}
      </Header>
      <br />
      <CommentDiv>{comment}</CommentDiv>
    </Container>
  );
}
