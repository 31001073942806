import styled from "@emotion/styled";
import Select from "../../../../../../UI/Form/Select/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../../../../UI/Flex/Flex";
import ColorPicker from "../../../../../../UI/ColorPicker/ColorPicker";

const Container = styled.div(
  ({ theme }) => `
    border: 2px solid ${theme.primary};
    padding: 8px;
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
`
);

const Block = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: space-between;
  & > div {
    width: fit-content;
  }

  & > svg {
    cursor: pointer;
    color: ${(props) => props.theme.notification.errorDark};
  }
`;

export default function ValueColorPair({
  options = [],
  item,
  index,
  onRemove,
  onSelectChange,
  onInputChange,
  selectKey,
  inputKey,
  colorOnly,
}) {
  return (
    <Container>
      <Block align="baseline">
        <div style={{ width: "90%" }}>
          <Select
            label="Value"
            options={options}
            value={options.find(
              (dvo) => dvo.value === item.value || dvo.value === +item.value
            )}
            onChange={(option) =>
              onSelectChange(option.value, index, selectKey)
            }
          />
        </div>
        <FontAwesomeIcon
          icon={["fas", "times-circle"]}
          onClick={() => onRemove(index)}
        />
      </Block>

      <Flex alignItems="center" justifyContent="space-between">
        <span style={{ marginRight: 10 }}>Color:</span>
        <ColorPicker
          initialColor={item.color}
          onChangeComplete={(color) => onInputChange(color, index, inputKey)}
          offsetLeft={-170}
        />
      </Flex>

      {!colorOnly && (
        <Flex alignItems="center" justifyContent="space-between">
          <span style={{ marginRight: 10 }}>Background:</span>
          <ColorPicker
            initialColor={item.background}
            onChangeComplete={(color) =>
              onInputChange(color, index, "background")
            }
            offsetLeft={-170}
          />
        </Flex>
      )}
    </Container>
  );
}
