import { useState } from "react";
import DatePickerContainer from "../../../UI/DatePicker/DatePickerContainer";
import { getPastYearsToNextDaysDateRange } from "../../../utils/formatters/dateFormatter";
import useDebounceChange from "../../../hooks/useDebounceChange";
import Input from "../../../UI/Form/Input/Input";
import { getUserSelectedDateOption } from "./customDateUtils";

export default function FutureDaysAndEarlier(props) {
  const {
    recentMonthsChange,
    activeTabUuid,
    selectedDateType,
    saveStickyDatesToStore,
    dateFiltersConfig,
  } = props;

  const { days } =
    getUserSelectedDateOption(dateFiltersConfig, activeTabUuid) ?? {};

  const [validationError, setValidationError] = useState(false);
  const { debounce } = useDebounceChange();

  function onDaysChange(days) {
    if (!days || !Number.isInteger(+days) || +days < 0) {
      return setValidationError("This value must be an positive integer.");
    }

    const { start, end } = getPastYearsToNextDaysDateRange(days, 3);
    debounce(() => recentMonthsChange({ from: start, to: end }), 1000);
    setValidationError(false);
    saveStickyDatesToStore(selectedDateType, { from: start, to: end, days });
  }

  return (
    <DatePickerContainer>
      <Input
        onChange={(e) => onDaysChange(e.target.value)}
        value={days}
        invalid={validationError}
        errorMessage={validationError}
        data-cy="future-days-input"
      />
    </DatePickerContainer>
  );
}

export const defaultDaysQuantityByDomain = {
  clife: 20,
};
