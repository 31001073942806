import ColumnsSelectionSidebar from "./ColumnsSelectionSidebar";
import ColumnSelectionListItem from "./ColumnSelectionListItem";
import { ColumnsList } from "./styles";
import { seveExpandedRowHiddenColumns } from "../../../store/actions/dashboard/dashboard";

export default function SimpleColumnsSelection({
  isOpened,
  allColumns,
  hiddenColumns,
  meta,
  onClose,
  visualizationId,
  dispatch,
}) {
  const columnsList = allColumns.flat().map((column) => ({
    hidden: hiddenColumns.includes(column),
    name: column,
  }));

  function getHiddenArray(column, hidden) {
    return hidden
      ? [...hiddenColumns, column.name]
      : hiddenColumns.filter((hiddenColumn) => hiddenColumn !== column.name);
  }

  const onSelectColumn = (column, hidden) => {
    const hiddenArray = getHiddenArray(column, hidden);

    dispatch(
      seveExpandedRowHiddenColumns(
        visualizationId,
        ["hiddenColumns"],
        [hiddenArray]
      )
    );
  };

  const onClearColumns = () => {
    dispatch(
      seveExpandedRowHiddenColumns(
        visualizationId,
        ["hiddenColumns"],
        [allColumns.flat()]
      )
    );
  };

  const onFullQueryColumns = () => {
    dispatch(
      seveExpandedRowHiddenColumns(visualizationId, ["hiddenColumns"], [[]])
    );
  };

  return (
    <ColumnsSelectionSidebar
      isOpened={isOpened}
      onClose={onClose}
      onClearClick={onClearColumns}
      onFullQueryClick={onFullQueryColumns}
    >
      <ColumnsList>
        {columnsList.map((column, index) => (
          <ColumnSelectionListItem
            key={column.name + `-${index}`}
            column={column}
            metaFields={meta?.fields}
            onItemClick={onSelectColumn}
          />
        ))}
      </ColumnsList>
    </ColumnsSelectionSidebar>
  );
}
