import { useCallback, useMemo, useState } from "react";
import Input from "../../../../../UI/Form/Input/Input";
import Select from "../../../../../UI/Form/Select/Select";
import { debounce } from "lodash-es";
import {
  aggregations,
  columnFormats,
  separateNewFormulaOptions,
} from "../../../../../utils/constants/constants";
import {
  getBlendedQueryFieldLableWithAlias,
  getColumn,
  getCurrentAggregation,
  getOverridesWithNewAdded,
  getUpdatedDynamicSubTitleKeys,
  getUpdatedSubTitles,
} from "../helper";
import { FormulaItem } from "./FormulaColumn";
import { Note } from "../../General/LimitRows/LimitRows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";
import HideNullableColumns from "./HideNullableColumns";
import { getRidOfAggregation } from "../../../../../charts/TableView/Elements/EditableMenu";

export default function SeparateNewFormulaColumn(props) {
  const {
    selectedColumn,
    isParameterized,
    navigationState,
    chart,
    overrides,
    setChartState,
    setSelectedColumn,
    fields,
    dynamicSubTitleKeys,
    deprecated,
    theme,
  } = props;

  const { column } = selectedColumn;
  // clear column from additional props
  const array = column
    .replace("ReverseColor::", "")
    .replace("ROUNDED::", "")
    .replace("BORDERED::", "")
    .split("::");

  const [snf, formula, format, A, B, C, D, E, F] = array;
  const dynamicLabelLetter = ["A", "B", "C", "D", "E", "F"];

  const option =
    separateNewFormulaOptions.find((o) => o.value === formula) ?? {};
  const { count } = option;

  const values = [A, B, C, D, E, F].filter((_, i) => i < count);

  const updateChartState = useCallback(
    (val, newOverrides = overrides) => {
      setChartState({
        ...chart,
        overrides: newOverrides,
        ...(chart.subTitles && {
          subTitles: getUpdatedSubTitles(chart, selectedColumn, val),
        }),
        coloredColumns: chart.coloredColumns?.map((cc) =>
          cc === column ? val : cc
        ),
        ...(chart.dynamicSubTitleKeys && {
          dynamicSubTitleKeys: getUpdatedDynamicSubTitleKeys(
            chart,
            column,
            val
          ),
        }),
      });

      setSelectedColumn({ ...selectedColumn, column: val });
    },
    [overrides, setChartState, chart, setSelectedColumn, selectedColumn, column]
  );

  const label = array[array.length - 1];
  const [header, setHeader] = useState(label);

  const debounced = useMemo(
    () => debounce((val) => updateChartState(val), 500),
    [updateChartState]
  );

  function onHeaderChange(e) {
    const coppied = [...array];
    coppied[coppied.length - 1] = e.target.value;
    const val = coppied.join("::");
    setHeader(e.target.value);

    if (navigationState === "Preview") {
      debounced(val);
      return;
    }
    updateChartState(val);
  }

  function changeFormula(option) {
    updateChartState(
      [
        snf,
        option.value,
        format,
        ...values.filter((_, i) => i < option.count),
        label,
      ].join("::")
    );
  }

  function changeColumnAggregation(option, prev) {
    const name = getRidOfAggregation(prev, overrides);
    // set new aggregation instead of old one
    const newAggregated = option.prefix + name;

    // add aggregation to override
    const newOverrides = overrides.map((override) =>
      override.name === name
        ? { ...override, aggregation: option.value }
        : override
    );

    updateChartState(
      column.replace(`::${prev}::`, `::${newAggregated}::`),
      newOverrides
    );
  }

  function changeFormat(option) {
    updateChartState(
      [
        snf,
        formula,
        option.value,
        ...values.filter((_, i) => i < count),
        label,
      ].join("::")
    );
  }

  function replaceExistingColumn(prev, newColumn) {
    return column.replace(prev, newColumn);
  }

  function addNewColumn(newColumn, index) {
    const newValues = values.map((col, i) => (i === index ? newColumn : col));

    return [snf, formula, format, ...newValues, label].join("::");
  }

  function changeColumnSource(option, prev, index) {
    const aggregation = getCurrentAggregation(prev, overrides)?.prefix;
    const aggregaedColumn = option.ops?.alias ?? aggregation + option.name;

    const val = prev
      ? replaceExistingColumn(prev, aggregaedColumn)
      : addNewColumn(aggregaedColumn, index);

    // for static headers
    const subTitles = getUpdatedSubTitles(chart, selectedColumn, val);

    // for dynamic headers
    const dynamicSubTitles = dynamicSubTitleKeys
      ? getUpdatedDynamicSubTitleKeys(chart, column, val)
      : null;

    const updatedOverrides = getOverridesWithNewAdded(
      overrides,
      option,
      dynamicSubTitles ?? subTitles,
      prev
    );

    updateChartState(val, updatedOverrides);
  }

  // to make it work for formula all columns which we using in formula should be in this array
  function includeAllInNoTotal() {
    return values.every((value) =>
      (chart.noTotalCalcKeys ?? []).includes(value)
    );
  }

  // if user manually added not all columns from formula checkbox should be unchecked
  function includeAnyInNoTotal() {
    return (chart.noTotalCalcKeys ?? []).some((key) => values.includes(key));
  }

  /**
   * Updates the 'noTotalCalcKeys' in the 'chart' state based on whether all values
   * should be included or excluded from the calculation.
   */
  const excludeFromTotals = () => {
    // Check if all values should be included
    const includeAll = includeAllInNoTotal();

    // Determine the updated 'noTotalCalcKeys' based on inclusion or exclusion
    const updatedKeys = includeAll
      ? chart.noTotalCalcKeys.filter((key) => !values.includes(key))
      : [...(chart.noTotalCalcKeys ?? []), ...values];

    // Update the 'chart' state with the new 'noTotalCalcKeys' array
    setChartState({
      ...chart,
      noTotalCalcKeys: [...new Set(updatedKeys)],
    });
  };

  const partialInclude = !includeAllInNoTotal() && includeAnyInNoTotal();

  return (
    <div>
      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />
      <Select
        options={separateNewFormulaOptions}
        simpleValue={formula}
        onChange={changeFormula}
      />

      <br />

      <Select
        options={columnFormats}
        simpleValue={format}
        onChange={changeFormat}
        label="Format"
      />

      <br />
      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />

      {values.map((col, i) => (
        <FormulaItem key={col + i} isParameterized={isParameterized}>
          <Select
            options={fields}
            getOptionLabel={(option) =>
              getBlendedQueryFieldLableWithAlias(option)
            }
            getOptionValue={(option) => option.name}
            value={getColumn(overrides, col)}
            onChange={(o) => changeColumnSource(o, col, i)}
            label={`Value ${dynamicLabelLetter[i]}`}
          />
          {!isParameterized && (
            <Select
              options={[{ label: "None", prefix: "" }, ...aggregations]}
              value={getCurrentAggregation(col, overrides)}
              label="Aggregate"
              onChange={(o) => changeColumnAggregation(o, col)}
              isDisabled={!getColumn(overrides, col)}
            />
          )}
        </FormulaItem>
      ))}

      <br />
      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />

      <Input
        label="Column Header Label"
        inPlaceEdit
        value={header || label}
        hideEditIcon
        onChange={onHeaderChange}
      />

      <br />

      {deprecated && (
        <Note color="warningDark">
          You are using a deprecated formula. It will continue to work, but to
          update these settings, please select from the Formula list.
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
        </Note>
      )}

      <br />
      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />

      <Checkbox
        label="Exclude from Totals"
        checked={includeAllInNoTotal()}
        onChange={excludeFromTotals}
      />

      {partialInclude && (
        <Note color="warningDark">
          Some columns from formula are not in 'Exclude from Totals' list.
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
        </Note>
      )}

      <HideNullableColumns
        setChartState={setChartState}
        chart={chart}
        column={column}
      />
    </div>
  );
}
