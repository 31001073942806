import { scaleOrdinal } from "d3-scale";
import { schemeTableau10 } from "d3-scale-chromatic";

export default function (
  valueKeys,
  legendItems,
  singleBarColorMode,
  allXKeys,
  colors = []
) {
  if (!valueKeys) {
    return null;
  }

  const colorScale = scaleOrdinal()
    .domain([...Array(10).keys()])
    .range(schemeTableau10);
  function getColor(alias, i) {
    const hasOverrideMatch = legendItems?.find((l) => l.name === alias);
    if (hasOverrideMatch?.color) {
      return hasOverrideMatch.color;
    } else {
      const overrideMatchTaken = legendItems?.[i]?.name;
      if (!overrideMatchTaken && colors?.[i]) {
        return colors[i];
      } else {
        return colorScale(i);
      }
    }
  }

  if (singleBarColorMode) {
    return allXKeys.map((v, i) => ({
      xMatch: v,
      color: getColor(null, i),
    }));
  }

  return valueKeys.map((k, i) => ({
    alias: k,
    color: getColor(k, i),
  }));
}

export function getColor(colors, yValue, xValue) {
  return colors[
    colors.findIndex((c) => c.alias === yValue || c.xMatch === xValue)
  ].color;
}
