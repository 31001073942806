import React from "react";
import PieValueObject from "./PieValueObject";
import SummaryDetailPie from "./SummaryDetailPie";
import { pipe, groupByKey, assignAsKey, zeroPadValues } from "../../utils/func";
// import { schemeTableau10 } from "d3-scale-chromatic";
// import { scaleOrdinal } from "d3-scale";
import Legend from "../Legend/Legend";
import VisualizationBase from "../BaseChart/VisualizationBase";
import Details from "../../Layout/Details/Details";
import formatter from "../../utils/formatters/formatter";
import conformLegacyPie from "./conformLegacyPie";
import mapColorConfig from "../BaseChart/mapColorConfig";

export default function SummaryDetailPieVisualization(props) {
  const { chart, width, details, dashboardId } = props;
  const {
    data,
    labelKey: propsLabelKey,
    valueKey: propsValueKey,
    groupKey: propsGroupKey,
    valueKeys: propsValueKeys,
    groupBy,
    height,
    meta,
    hidePieValues,
    legendConfig,
    legendItems,
    absoluteValues,
    showPercentsInTooltip,
    includeCategoryInTooltip,
    tooltipConfig,
    legendHeight,
    legendTitle,
  } = chart;

  const { valueKey, valueKeys, labelKey } = conformLegacyPie(
    propsValueKey,
    propsValueKeys,
    groupBy,
    propsLabelKey,
    propsGroupKey
  );
  const groupKey = propsGroupKey || groupBy;

  const aPie = new PieValueObject(
    data.filter((d) => (valueKey ? d[valueKey] : true)),
    groupKey,
    valueKey,
    null,
    legendItems,
    absoluteValues,
    valueKeys
  );
  const showDetailCharts = checkDetailChartVisibility();

  const detailCharts = showDetailCharts ? getDetailCharts() : null;

  function checkDetailChartVisibility() {
    return !!labelKey;
  }

  function getDetailCharts() {
    const valueKeysGroupedMode = groupKey && valueKeys?.length > 1;
    const splitData = valueKeysGroupedMode
      ? data.reduce((acc, curr) => {
          const mapped = valueKeys.map((v) => ({
            ...curr,
            value: curr[v],
            group: v,
          }));
          return [...acc, ...mapped];
        }, [])
      : data;
    const vKey = valueKeysGroupedMode ? "value" : valueKey;
    const gKey = valueKeysGroupedMode ? "group" : groupKey;

    const detailChartTemp = pipe(
      assignAsKey(vKey, "value"),
      groupByKey(labelKey),
      zeroPadValues(aPie.getSliceNames(), "values", gKey, { value: 0 })
    )(splitData);

    const colorDetailValues = detailChartTemp.map(mapToDetailValues);

    function mapToDetailValues(value) {
      return value.values.map((v) => ({
        ...v,
        color: mapColorToDetailValue(v),
      }));
      function mapColorToDetailValue(v) {
        return aPie.getChart().find((s) => {
          if (valueKeysGroupedMode) {
            return s.name === v.group;
          } else {
            return s.name === v[groupKey];
          }
        })?.color;
      }
    }
    return detailChartTemp.map(assignDetailValues);
    function assignDetailValues(d, i) {
      return { ...d, values: colorDetailValues[i] };
    }
  }

  const legendValues = buildLegendBasedOnValues(legendConfig)(
    aPie.getChart(),
    data,
    meta
  );

  const colorArray = mapColorConfig(legendValues, legendItems);

  return (
    <>
      <div
        id={chart.visualizationId}
        style={{ position: "relative" }}
        data-cy="outer-pie"
      >
        <VisualizationBase
          {...{ ...props, margin }}
          tooltipConfig={tooltipConfig}
        >
          <SummaryDetailPie
            summaryChart={aPie.getChart().slice(0, 10)}
            detailCharts={detailCharts}
            width={width}
            height={height}
            meta={meta}
            valueKey={valueKey}
            hidePieValues={hidePieValues}
            valueKeys={valueKeys}
            showPercentsInTooltip={showPercentsInTooltip}
            includeCategoryInTooltip={includeCategoryInTooltip}
            groupKey={groupKey}
            sourceData={data}
            colors={colorArray}
          />
        </VisualizationBase>

        <Legend
          colors={colorArray}
          meta={meta}
          sortOrder={legendItems}
          highTop
          foreignObject
          sections={legendValues}
          wrapping
          horizontal
          legendHeight={legendHeight}
          legendTitle={legendTitle}
        />
      </div>
      {details && chart.data.length > 0 && (
        <Details
          dashboardName={dashboardId}
          visualizationId={chart.visualizationId}
          chart={chart}
        />
      )}
    </>
  );
}

const margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 18,
};

const buildLegendBasedOnValues = (legendConfig) => (data, sourceData, meta) => {
  const mappedData = data.map((d) => {
    const metaMatch = (meta?.fields || []).find((f) => f.alias === d.name);

    return { ...d, label: metaMatch?.label || d.name };
  });
  if (!legendConfig) return mappedData.map((d) => d.label);
  const { labelKey, valueFormat, valueKey } = legendConfig;

  return data.reduce((acc, curr) => {
    acc.push(
      `${labelKey ? curr.name + ":" : ""} ${formatter(
        sourceData.find((d) => d[labelKey] === curr.name)?.[valueKey] ||
          curr.value,
        valueFormat
      )}`
    );
    return acc;
  }, []);
};
