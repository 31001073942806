import React, { useMemo, useState } from "react";
import showFilter from "./showFilter";
import MenuFilter from "./MenuFilter";
import ComparisonTypeSelector from "./ComparisonTypeSelector";
import { getComparisonModeItem } from "../Block/interruptDashboard";

export default React.memo(function MenuFiltersList(props) {
  const {
    filters,
    currentTabId,
    dataSourceAccessConfig,
    menuFilterShortList,
    additionalTheme,
    select,
    comparisonMode,
    selectPowerEditorModeFilter,
    searchFilterLoading,
  } = props;
  const [selectedComparisonMode, setSelectedComparisonMode] = useState(null);

  const comparisonModeItem = getComparisonModeItem(
    comparisonMode,
    currentTabId
  );

  const displayedFilters = filters
    .filter((filter) => !filter.extended)
    .filter((filter) =>
      showFilter(filter, currentTabId, dataSourceAccessConfig)
    )
    .map((filter) =>
      menuFilterShortList[filter.uuid] === "loading"
        ? { ...filter, loading: true }
        : filter
    )
    .filter(comparisonModeVisibility);

  function comparisonModeVisibility(filter) {
    if (!comparisonModeItem) {
      return true;
    }

    // we have other filters which should show on comparison page
    const others = !comparisonModeItem.options.find((o) => o === filter.name);

    return selectedComparisonMode === filter.name || others;
  }

  const displayFilters = useMemo(() => {
    const filters = sortByComparisonModeItem(
      displayedFilters,
      comparisonModeItem
    );

    function getIsOpenFilterProperty(item) {
      return (
        selectedComparisonMode === item.name ||
        (additionalTheme?.openDefault ?? {})[item.uuid]
      );
    }

    return filters.map((item, i) => {
      // dependency total count if exist
      const dependencyCount = menuFilterShortList[item.uuid]?.length;

      // replace with dependency total count to get rid of pagination if qunatity less then menuFilterDefaultPerPageValues
      const filter = {
        ...item,
        totalCount: dependencyCount || item.totalCount,
        realTotalCount: item.totalCount,
      };

      return (
        <MenuFilter
          filterType={filter}
          key={filter.uuid ?? i}
          open={getIsOpenFilterProperty(item)}
          select={select}
          menuFilterShortList={menuFilterShortList[item.uuid]}
          dataSourceAccessConfig={dataSourceAccessConfig}
          additionalTheme={additionalTheme}
          comparisonModeItem={comparisonModeItem}
          selectPowerEditorModeFilter={selectPowerEditorModeFilter}
          searchFilterLoading={searchFilterLoading}
        />
      );
    });
  }, [
    displayedFilters,
    selectedComparisonMode,
    additionalTheme,
    select,
    menuFilterShortList,
    dataSourceAccessConfig,
    comparisonModeItem,
    selectPowerEditorModeFilter,
    searchFilterLoading,
  ]);

  return (
    <>
      {comparisonMode ? (
        <ComparisonTypeSelector
          comparisonMode={comparisonModeItem}
          selectedComparisonMode={selectedComparisonMode}
          setSelectedComparisonMode={setSelectedComparisonMode}
          filters={filters}
        />
      ) : null}
      {displayFilters}
    </>
  );
});

function sortByComparisonModeItem(filters, comparisonModeItem) {
  if (!comparisonModeItem) {
    return filters;
  }

  const { options } = comparisonModeItem;

  const comparisonFilters = filters.filter((filter) =>
    (options ?? []).includes(filter.name)
  );

  const otherFilters = filters.filter(
    (filter) => !(options ?? []).includes(filter.name)
  );

  return [...comparisonFilters, ...otherFilters];
}
