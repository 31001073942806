import React from "react";
import Select from "../../../UI/Form/Select/Select";
import styled from "@emotion/styled";

const Container = styled.div`
  svg {
    margin-right: 12px;
  }
  path {
    fill: ${(props) => props.theme.gray.gray400} !important;
  }

  width: ${(props) => props.width ?? 160}px;
  margin-right: 12px;
`;

// const Notice = styled.span(
//   ({ theme }) => `
//   color: ${theme.notification.warningMain};
//   font-size: 10px;
// `
// );

export default function ActiveTableDisplayFormat(props) {
  const {
    updateColumn,
    options,
    cy,
    value,
    addMissingOptionOnTheFly,
    label,
    isDisabled,
    width,
    title,
  } = props;

  return (
    <Container
      style={{ opacity: isDisabled ? 0.4 : 1 }}
      width={width}
      title={title}
    >
      <Select
        cy={cy}
        label={label}
        options={options}
        simpleValue={value}
        addMissingOptionOnTheFly={addMissingOptionOnTheFly}
        onChange={updateColumn}
        isDisabled={isDisabled}
      />
      {/*The message on field name is sufficient here*/}
      {/*{isDisabled && (*/}
      {/*  <Notice>Please provide a valid column name to continue editing.</Notice>*/}
      {/*)}*/}
    </Container>
  );
}
