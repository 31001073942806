import axios from "axios";
import * as actionTypes from "./actionTypes";
import * as queryString from "qs";
import queryBuilder from "./queryBuilder/queryBuilder";

export const loadCountiesCoordinates = () => async (dispatch) => {
  dispatch({ type: actionTypes.LOAD_COUNTIES_START });

  try {
    const res = await axios.get(
      "https://d3gppnp7ccufr1.cloudfront.net/io/us_counties.json"
    );

    dispatch({ type: actionTypes.LOAD_COUNTIES_SUCCESS, results: res });
  } catch (err) {
    dispatch({ type: actionTypes.LOAD_STATES_FAIL });
  }
};

export const loadStatesCoordinates = () => async (dispatch) => {
  dispatch({ type: actionTypes.LOAD_STATES_START });

  try {
    const res = await axios.get(
      "https://d3gppnp7ccufr1.cloudfront.net/io/us_states.json"
    );

    dispatch({ type: actionTypes.LOAD_STATES_SUCCESS, results: res });
  } catch (err) {
    dispatch({ type: actionTypes.LOAD_STATES_FAIL });
  }
};

export const loadZipCodesCoordinates = () => async (dispatch) => {
  dispatch({ type: actionTypes.LOAD_ZIPCODES_START });

  try {
    const res = await axios.get(
      "https://d3gppnp7ccufr1.cloudfront.net/io/ma_zipcodes.json"
    );

    dispatch({ type: actionTypes.LOAD_ZIPCODES_SUCCESS, results: res });
  } catch (err) {
    dispatch({ type: actionTypes.LOAD_ZIPCODES_FAIL });
  }
};

export const loadDrillDownDetails =
  (filters, query) => async (dispatch, getState) => {
    const qs = queryBuilder({ ...query, filters: filters || [] }, getState);

    const apiPrimary = {
      public: false,
      method: "POST",
      endpoint: `api/v1/queries/${query.queryId}/exec`,
      reportAllErrors: true,
      payload: queryString.parse(qs, { depth: 10 }),
    };

    dispatch({
      type: actionTypes.LOAD_GEO_MAP_DETAILS_START,
      meta: {
        api: apiPrimary,
      },
    });
  };
