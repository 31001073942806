import styled from "@emotion/styled";
import { themeColor } from "../../../styles/styledSnippets";

export const PanelTitle = styled.div`
  color: ${(props) => props.theme.text.primary};
  margin-bottom: 18px;
`;

export const PanelMessage = styled.div`
  border: 1px solid ${themeColor("warningMain")};
  padding: 24px;
  border-radius: 4px;
  //color: ${themeColor("warningMain")};
  background: ${themeColor("warningLight")};
`;
