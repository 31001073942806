import React, { useCallback } from "react";
import styled from "@emotion/styled";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import PropTypes from "prop-types";
import { schemeTableau10 } from "d3-scale-chromatic";
import { scaleOrdinal } from "d3-scale";

import Flex from "../../UI/Flex/Flex";
import { TREND_LINE_COLOR } from "./MultipleLineChart";

const LineContainer = styled.div`
  display: flex;
  margin-top: 2px;
  margin-left: 10px;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.verticalLegend ? "column" : "row")};
  align-items: center;
  position: relative;

  @media (max-width: ${mobileBreakpoints.mobile}) {
    justify-content: center;
  }
`;

const LineElement = styled.div`
  font-size: 12px;
  display: flex;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    font-size: 10px;
  }
`;

const CircleElement = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  @media (max-width: ${mobileBreakpoints.mobile}) {
    font-size: 10px;
  }
`;

const Line = styled.div(
  ({ dashed, color, chartColor, legendSize }) => `
    border-bottom: 3px ${dashed ? "dashed" : "solid"} ${color || chartColor};
    width: ${legendSize ? 18 : 50}px;
    height: 1px;
    position: relative;
    top: -1px;
    @media (max-width: ${mobileBreakpoints.mobile}) {
      width: 30px;
    }
`
);

const Circle = styled.div(
  ({ color }) => `
  width: 0.25rem;
  height: 0.25rem;
  background-color: ${color};
  border-radius: 50%;
`
);

const commonStyles = `
    margin: 0 18px 0 8px;
    white-space: nowrap;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    @media (max-width: ${mobileBreakpoints.mobile}) {
      margin: 2px 5px;
      max-width: 140px;
    }
`;

const Text = styled.div`
  ${(props) =>
    props.legendSize
      ? `
    font-size: ${props.legendSize ? "10px" : "inherit"};
    position: relative; 
    top: 2px;
    left: -3px;
  `
      : ""}
  ${commonStyles}
`;

const Link = styled.a`
  ${commonStyles};
  color: ${(props) => props.theme.text.secondary};
  &:hover {
    text-decoration: underline;
  }
`;

export default function LineLegend(props) {
  const {
    lineGroups,
    chartColors = [],
    fieldMapping, //meta
    legendItems,
    nullLegend,
    verticalLegend,
    defaultColors,
    hide,
    legendSize,
    setDetailsMarginTop,
    legendOnly,
    QO,
    trendLine,
  } = props;

  const ref = useCallback(
    (legend) => {
      if (legend && !legendOnly) {
        setDetailsMarginTop(legend.clientHeight);
      }
    },
    [setDetailsMarginTop, legendOnly]
  );

  if (hide) return null;
  const dColors = scaleOrdinal(schemeTableau10);

  if (!legendItems && !lineGroups) return null;

  function assignMapping(key) {
    const sourceKey = QO ? QO.sourceKey(key) : key;
    return fieldMapping.find(
      (m) => m.alias === sourceKey || m.name === sourceKey
    );
  }

  function getLineGroupsLegend() {
    return lineGroups.map(({ curved, dashed, key }) => {
      const mappedItem = {
        ...assignMapping(key),
        key,
        curved,
        dashed,
      };

      if (!mappedItem.label && !mappedItem.name && !mappedItem.key) {
        mappedItem.label = nullLegend;
      }

      return mappedItem;
    });
  }

  function getLegendItems() {
    if (!legendItems) {
      return getLineGroupsLegend();
    }

    const existingKeys = lineGroups.map((line) => line.key);

    return legendItems.filter((item) => existingKeys.includes(item.name));
  }

  const items = getLegendItems();

  return (
    <LineContainer verticalLegend={verticalLegend} ref={ref}>
      {items.map((lg, i) => (
        <LineElement key={i} data-cy="legend-item">
          <Line
            dashed={lg.dashed}
            color={lg.color}
            legendSize={legendSize}
            chartColor={
              defaultColors || !chartColors ? dColors(i) : chartColors[i]
            }
            style={{
              borderBottom: `3px ${lg.dashed ? "dashed" : "solid"} ${
                defaultColors ? dColors(i) : lg.color || chartColors[i]
              }`,
              height: 3,
              marginTop: 5,
            }}
          />

          {lg.link ? (
            <Link href={lg.link}>
              {lg.override || lg.label || lg.name || lg.key}
            </Link>
          ) : (
            <Text
              title={lg.override || lg.label || lg.name || lg.key}
              data-cy="line-legend-item"
              legendSize={legendSize}
            >
              {lg.override || lg.label || lg.name || lg.key}
            </Text>
          )}
        </LineElement>
      ))}
      {trendLine && (
        <CircleElement>
          <Flex gap="0.25rem">
            <Circle color={TREND_LINE_COLOR} />
            <Circle color={TREND_LINE_COLOR} />
            <Circle color={TREND_LINE_COLOR} />
          </Flex>
          <Text
            title="Trend Line"
            data-cy="line-legend-item-trend"
            legendSize={legendSize}
          >
            Trend Line
          </Text>
        </CircleElement>
      )}
    </LineContainer>
  );
}

LineLegend.defaultProps = {
  fieldMapping: [],
};

LineLegend.propTypes = {
  lineGroups: PropTypes.array,
  chartColors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fieldMapping: PropTypes.array,
  legendItems: PropTypes.array,
  nullLegend: PropTypes.string,
};
