import { orderBy } from "lodash-es";
import { unique } from "../../utils/func";
import { addMonths, parseISO } from "date-fns";

export default function (lineGroups, yearOverYear) {
  return lineGroups.reduce(
    (acc, curr) => {
      return {
        ...acc,
        dates: dates(acc, curr, yearOverYear),
        values: [...acc.values, ...curr.values.map((v) => v.value)],
        rightAxisValues: [
          acc.rightAxisValues,
          ...(curr.rightAxisValues || []).map((v) => v.value),
        ],
      };
    },
    { dates: [], values: [], rightAxisValues: [] }
  );
}

function dates(acc, curr, yearOverYear) {
  return [
    ...acc.dates,
    ...curr.values.map((v) => v[yearOverYear ? "scaleDate" : "xValue"]),
  ];
}

export function months(xKeyDomain) {
  return (
    xKeyDomain && [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
  );
}

export function getSortedXAxisValues(
  xSortSettings,
  xAxisItems,
  initialData,
  xKey,
  meta,
  dateFilters
) {
  if (xSortSettings === true) {
    return unique(xAxisItems).sort((a, b) => a.localeCompare(b));
  } else if (xSortSettings) {
    const { key, direction } = xSortSettings;

    const xKeys = unique(xAxisItems).map((xAxisItem) => ({
      [xKey]: xAxisItem,
      [key]: getOrderNumber(initialData, xAxisItem, key, xKey),
    }));

    return orderBy(xKeys, key, direction).map((item) => item[xKey]);
  }
  const metaMatch = (meta?.fields ?? []).find((f) => f.alias === xKey);
  if (metaMatch?.aggregateTypeId === 8)
    return fillMissingMonths(xAxisItems, dateFilters);
  if (metaMatch?.aggregateTypeId === 6) return fillMissingYears(xAxisItems);
  return unique(xAxisItems);
  // sort shouldn't be handled here
  // return unique(xAxisItems).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
}

function getOrderNumber(initialData, xAxisItem, key, xKey) {
  return initialData.find(
    (initialDataItem) => initialDataItem[xKey] === xAxisItem
  )[key];
}

function fillMissingMonths(xAxisItems, dateFilters) {
  // Transform "YYYY MMM" into "YYYY-MM-DD"
  const parseDate = (item) => {
    const [year, month] = item.split(" ");
    const isoDate = `${year}-${month.replace("M", "").padStart(2, "0")}-01`;
    return parseISO(isoDate); // Convert to ISO date
  };

  // Parse xAxisItems and determine the earliest and latest dates
  const dates = xAxisItems.map(parseDate).sort((a, b) => a - b);
  const xAxisStart = dates[0]; // Earliest date from xAxisItems
  const xAxisEnd = dates[dates.length - 1]; // Latest date from xAxisItems

  // Extract start and end dates from dateFilters if they exist
  const startDate = dateFilters?.start
    ? parseISO(dateFilters.start.value)
    : xAxisStart;
  const endDate = dateFilters?.end ? parseISO(dateFilters.end.value) : xAxisEnd;

  // Determine the overall range (including optional dateFilters)
  const overallStart = startDate < xAxisStart ? startDate : xAxisStart;
  const overallEnd = endDate > xAxisEnd ? endDate : xAxisEnd;

  // Generate the complete list of months between overallStart and overallEnd
  const result = [];
  let currentDate = new Date(overallStart);

  while (currentDate <= overallEnd) {
    const year = currentDate.getFullYear();
    const month = `M${String(currentDate.getMonth() + 1).padStart(2, "0")}`;
    result.push(`${year} ${month}`);
    currentDate = addMonths(currentDate, 1); // Increment by one month
  }

  return result; // Returns all padded months
}

function fillMissingYears(xAxisItems) {
  // Convert the input years to numbers (just in case they are strings)
  const years = xAxisItems.map(Number);

  // Sort the years in ascending order
  years.sort((a, b) => a - b);

  // Generate a range of years from the start to the end year
  const result = [];
  for (let year = years[0]; year <= years[years.length - 1]; year++) {
    result.push(String(year)); // Convert back to string to match the format
  }

  return result;
}
