import React from "react";
import DatePickerContainer from "../../../UI/DatePicker/DatePickerContainer";
import { useTheme } from "emotion-theming";
import { getUserSelectedDateOption } from "./customDateUtils";

export default function CustomRange(props) {
  const {
    dateConfig,
    activeTabUuid,
    selectedDateType,
    saveStickyDatesToStore,
  } = props;

  const theme = useTheme();

  const { from, to } =
    getUserSelectedDateOption(dateConfig.dateFiltersConfig, activeTabUuid) ??
    {};

  function setStartDate(e) {
    if (e.target.value) {
      dateConfig.setCustomDateRange(e.target.value, to);
      saveStickyDatesToStore(selectedDateType, { from: e.target.value, to });
    }
  }

  function setEndDate(e) {
    if (e.target.value) {
      dateConfig.setCustomDateRange(from, e.target.value);
      saveStickyDatesToStore(selectedDateType, { from, to: e.target.value });
    }
  }

  return (
    <>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          value={from || ""}
          onChange={setStartDate}
          data-cy="date-range-start-input"
          max={to}
        />
      </DatePickerContainer>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          value={to || ""}
          onChange={setEndDate}
          data-cy="date-range-end-input"
          min={from}
        />
      </DatePickerContainer>
    </>
  );
}
