import { useEffect } from "react";
import { useSelector } from "react-redux";
import ButtonLink from "../../../../UI/ButtonLink/ButtonLink";
import IncompatibleFilters from "./IncompatibleFilters";

// The IncompatibleFiltersFactory component is responsible for handling and displaying errors related to incompatible filters in a visualization.
// It conditionally renders either a modal (IncompatibleFilters) or a button link (ButtonLink) based on the presence of errors.
export default function IncompatibleFiltersFactory({
  visualizationId,
  renderOpenModalLink,
  queryUuid,
  forceUpdatePreview,
  showErrorModal,
  setShowErrorModal,
}) {
  const errors = useSelector((state) => state.charts.errors[visualizationId]);
  const runSiteConfig = errors && errors.filterIndexes?.length;

  useEffect(() => {
    if (runSiteConfig) {
      setShowErrorModal(true);
    }
  }, [runSiteConfig, setShowErrorModal]);

  if (renderOpenModalLink) {
    return runSiteConfig ? (
      <ButtonLink
        style={{ fontSize: 12 }}
        onClick={() => setShowErrorModal(true)}
      >
        Open 422 Filters Error Modal
      </ButtonLink>
    ) : null;
  }

  return showErrorModal ? (
    <IncompatibleFilters
      show={showErrorModal}
      errors={errors}
      setShowErrorModal={setShowErrorModal}
      queryId={queryUuid}
      forceUpdatePreview={forceUpdatePreview}
      visualizationId={visualizationId}
    />
  ) : null;
}
