import React, { useEffect } from "react";
import FilterItem from "../Filters/FilterItem";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { changeComparisonType } from "../../store/actions";
import { isLight } from "../../styles/colorConvertor";
import { setSelectedComparisonModeFilterName } from "../../store/actions/layout";

const colorSecondary = (props) => {
  if (props.theme.menuPrimary) {
    return isLight(props.theme.primary)
      ? props.theme.gray.gray800
      : "rgba(255, 255, 255, 0.65)";
  } else {
    return props.theme.text.secondary;
  }
};
const Container = styled.div`
  label {
    color: ${colorSecondary};
  }
  margin-bottom: 18px;
  margin-left: 12px;
`;

const color = (props) => {
  if (props.theme.menuPrimary) {
    return isLight(props.theme.primary)
      ? props.theme.gray.gray900
      : props.theme.gray.gray100;
  } else {
    return props.theme.text.primary;
  }
};

const CompareTitle = styled.div`
  text-transform: uppercase;
  color: ${color};
  text-align: left;
  font-size: 0.8em;
  font-weight: bold;
  padding: 8px 0;
`;

export default function ComparisonTypeSelector(props) {
  const {
    comparisonMode,
    setSelectedComparisonMode,
    selectedComparisonMode,
    filters,
  } = props;

  const dispatch = useDispatch();

  const comparisonModeFilterName = useSelector(
    (state) => state.layout.comparisonModeFilterName
  );

  useEffect(() => {
    // in power table editor on switching tabs, the local state is lost
    // wee need to restore it from store
    if (!selectedComparisonMode && comparisonModeFilterName) {
      setSelectedComparisonMode(comparisonModeFilterName);
    }
  }, [
    comparisonModeFilterName,
    selectedComparisonMode,
    setSelectedComparisonMode,
  ]);

  function handleChangeComparisonType(option) {
    setSelectedComparisonMode(option);
    dispatch(changeComparisonType(comparisonMode));

    // save selected comparison filter name to store
    dispatch(setSelectedComparisonModeFilterName(option));
  }

  function label(option) {
    return filters.find((f) => f.name === option)?.displayName;
  }

  return (
    <Container data-cy="comparison-type-selector">
      <CompareTitle>Compare Mode</CompareTitle>
      {comparisonMode?.options.map((option) => (
        <FilterItem
          key={option}
          onChange={() => handleChangeComparisonType(option)}
          cy="filter-compare-input"
          checked={selectedComparisonMode === option}
          name={label(option)}
        />
      ))}
    </Container>
  );
}
