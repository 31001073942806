import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import RadioButton from "../../UI/RadioButton/RadioButton";

const FilterContainer = styled.div`
  display: flex;
  padding: 0.375rem 0;
`;

const Label = styled.label(
  ({ theme }) => `
    font-size: 0.9em;
    padding-top: 2px;
    padding-left: 34px;
    margin-bottom: 12px;
    cursor: pointer;
    display: block;
    position: relative;
    user-select: none;
    text-align: left;
    overflow-wrap: anywhere;
    &:hover span {
      background: ${theme.background.primary};
    }
    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 21px;
        width: 21px;
        border-radius: 2px;
        border: 1px solid ${theme.divider};
        
        background-color: ${theme.background.secondary};
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid ${theme.primary || theme.buttons.active};
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
`
);

const Input = styled.input(
  ({ theme }) => `
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span {
    background-color: ${theme.primary ? "none" : theme.buttons.selected};
    border-color: ${theme.primary || theme.divider};
    &:after {
      display: block;
    }
  }
`
);

function FilterItem(props) {
  return (
    <FilterContainer data-cy="filter-item" data-name={props.name}>
      {props.type === "radio" ? (
        <RadioButton {...props} />
      ) : (
        <Label data-cy={`filter-select-box`} title={props.title}>
          <Input
            type="checkbox"
            checked={props.checked}
            onChange={props.onChange}
            data-cy={props.cy}
          />
          <span />
          {props.label ?? props.name}
          <EnsureEmptyStringDoesNotMessUpDesign />
        </Label>
      )}
    </FilterContainer>
  );
}

FilterItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  cy: PropTypes.string,
  label: PropTypes.node,
};

export default React.memo(FilterItem);

/**
 * https://stackoverflow.com/a/2789503/1381550
 */
function EnsureEmptyStringDoesNotMessUpDesign() {
  return <br style={{ visibility: "hidden" }} />;
}
