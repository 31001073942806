import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";
import selectStyles from "../../../../styles/selectStyles";
import useLabel from "../../LabelWrapper/useLabel";
import Select from "../Select";
import Option from "./Option";
import ValueContainer from "./ValueContainer";
import MultiValue from "./MultiValue";
import {
  filterOptionCallback,
  getActiveFiltersQuantity,
  getCurrentFiltersAfterSearch,
  getOptionValue,
} from "./helper";

export const allOption = {
  value: "*",
};

export default function SelectWithCheckboxes(props) {
  const {
    range,
    belowValue,
    bgPrimary,
    options,
    value,
    isLoading,
    searchValue,
    onSelectInputChange,
    menuIsOpen,
  } = props;

  const theme = useTheme();
  const withLabel = useLabel(props.label);

  // recreate this object on each filter which using select with checkboxes
  const allOption = useMemo(
    () => ({
      value: "*",
    }),
    []
  );

  const active = getActiveFiltersQuantity(value, allOption);
  const currentFiltersAfterSearch = getCurrentFiltersAfterSearch(
    options,
    searchValue
  );
  const currentFiltersQuantity = currentFiltersAfterSearch.length;
  const showSelectAll =
    currentFiltersQuantity <= 100 && currentFiltersQuantity > 2;

  if (showSelectAll) {
    allOption.label = `Select all (${active}/${currentFiltersQuantity})`;
  }

  const onChange = useMemo(() => {
    if (!showSelectAll) {
      return props.onChange;
    }

    return (selected, actionObject) => {
      const { action, option } = actionObject;

      if (selected !== null && selected.length > 0) {
        if (selected[selected.length - 1].value === allOption.value) {
          // The "Select all" option was selected/deselected.
          if (action === "select-option" && !options.includes(option)) {
            return props.onChange([allOption, ...currentFiltersAfterSearch], {
              action: "select-all",
            });
          }
        }

        let result = [];

        if (selected.length === currentFiltersQuantity) {
          if (selected.includes(allOption)) {
            result = selected.filter(
              (option) => option.value !== allOption.value
            );
          } else if (action === "select-option") {
            result = [allOption, ...options];
          }

          return props.onChange(result);
        }
      }

      return props.onChange(selected, actionObject);
    };
  }, [
    allOption,
    currentFiltersAfterSearch,
    currentFiltersQuantity,
    options,
    props,
    showSelectAll,
  ]);

  const finalOptions = useMemo(() => {
    return showSelectAll ? [allOption, ...options] : options;
  }, [allOption, options, showSelectAll]);

  const el = (
    <Select
      styles={selectStyles(
        theme,
        range,
        belowValue || theme.primary,
        bgPrimary
      )}
      options={finalOptions}
      filterOptions={false}
      onChange={onChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, MultiValue, ValueContainer, ...props.components }}
      isMulti
      isClearable={false}
      value={getOptionValue(
        currentFiltersAfterSearch,
        value,
        allOption,
        showSelectAll
      )}
      isLoading={isLoading}
      inputValue={searchValue}
      onInputChange={onSelectInputChange}
      menuIsOpen={menuIsOpen}
      filterOption={filterOptionCallback}
      cy="select-with-checkboxes"
    />
  );

  return withLabel(el);
}

SelectWithCheckboxes.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
};
