import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { showToastWithTimeout } from "../../store/actions/message";
import { normalizeError, handleError } from "../../utils/errorHandling";
import axios, { HEADER_CONVERT_EMPTY_STRINGS_TO_NULL } from "../../axios";
import { chartTypeKeyMap } from "../../utils/constants/chartConstants";
import {
  getSelectorForCurrentPageByUuid,
  getFirstVisualizationForPage,
  getSingleDataSourceFiltersQueryUuidByPage,
} from "../../utils/pages";
import { assembleEmptySingleQueryFiltersFromActiveTableStore } from "../../utils/menuFilters";
import { useShallowEqualSelector } from "../../store";
import produce from "immer";
import { getActiveTableAndViewUuidFromState } from "../../utils/activeTable";
import { convertToWithNull } from "../../store/actions/dataSettings/prepareSettings";
import { getQuery, resetQuery } from "../../store/actions";
import { getVisibleSingleQueryFilterFields } from "../../store/actions/queryBuilder/queryBuilder";

export default function (pageUuid) {
  const dispatch = useDispatch();
  const { page, queryUuid, isEnabledForThisPage } =
    useSingleQueryFiltersBasic(pageUuid);

  const visualization = getFirstVisualizationForPage(page);
  const isActiveTable =
    visualization?.settings.type === chartTypeKeyMap.ActiveTable;
  const isNotActiveTable =
    visualization?.settings.type !== chartTypeKeyMap.ActiveTable;
  const queryFields = useQueryFields(pageUuid, isNotActiveTable);

  const currentViewUuid = useSelector(
    (state) => state.activeTable.currentViewUuid
  );
  const { currentTable, currentActiveTableUuid } = useShallowEqualSelector(
    getActiveTableAndViewUuidFromState
  );
  const currentActiveTable = isActiveTable ? currentTable : null;
  const firstVisualizationActiveTableUuid =
    visualization?.settings.activeTableUuid;
  const currentViewFilters = currentActiveTable?.views?.find(
    (v) => v.uuid === currentViewUuid
  )?.filters;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const abortControllersRef = useRef(new Set());

  const loadSingleFieldByName = useCallback(
    async (fieldName, otherFieldValues) => {
      const abortController = new AbortController();
      try {
        abortControllersRef.current.add(abortController);

        setData((data) =>
          produce(data, (draft) => {
            for (const row of draft) {
              if (row.fieldName !== fieldName) {
                continue;
              }

              row.loading = true;
            }
          })
        );

        const promise = axios.post(
          `/api/v1/queries/${queryUuid}/exec`,
          {
            per_page: 100_000,
            order: {
              [fieldName]: "ASC",
            },
            overrides: {
              other: {
                isDistinct: true,
              },
              fields: [
                {
                  name: fieldName,
                },
              ],
            },
            filters: convertToWithNull([
              ...otherFieldValues?.map(({ key, selectedValues }) => ({
                name: key,
                values: selectedValues,
              })),
              ...(currentViewFilters ?? []),
            ]),
          },
          {
            signal: abortController.signal,
            headers: { [HEADER_CONVERT_EMPTY_STRINGS_TO_NULL]: "0" },
          }
        );

        const results = (await promise).data.data;

        setData((data) =>
          produce(data, (draft) => {
            for (const row of draft) {
              if (row.fieldName !== fieldName) {
                continue;
              }

              row.values = results.map((v) => v[fieldName]);
              row.loading = false;
            }
          })
        );
      } catch (e) {
        handleError(e);
      } finally {
        abortControllersRef.current.delete(abortController);
      }
    },
    [currentViewFilters, queryUuid]
  );

  const fields = useMemo(() => {
    return (
      getVisibleSingleQueryFilterFields(
        currentActiveTable?.views,
        currentActiveTable?.columns,
        currentViewUuid,
        queryFields,
        visualization
      ) ?? queryFields?.map((v) => v.name)
    );
  }, [
    currentActiveTable?.columns,
    currentActiveTable?.views,
    currentViewUuid,
    queryFields,
    visualization,
  ]);

  const isEnabledAndActiveTableReady = useMemo(() => {
    if (!isEnabledForThisPage) {
      return false;
    }

    return !(
      isActiveTable &&
      (!currentActiveTableUuid ||
        currentActiveTableUuid !== firstVisualizationActiveTableUuid)
    );
  }, [
    currentActiveTableUuid,
    firstVisualizationActiveTableUuid,
    isActiveTable,
    isEnabledForThisPage,
  ]);

  useEffect(() => {
    if (isEnabledAndActiveTableReady && !queryUuid) {
      const errorMessage = "Query was not found.";
      showToastWithTimeout(errorMessage, "danger");
      setError(
        normalizeError({
          message: errorMessage,
        })
      );
    }
  }, [dispatch, isEnabledAndActiveTableReady, queryUuid]);

  useEffect(() => {
    if (!queryFields || !fields) {
      return;
    }

    setData(
      assembleEmptySingleQueryFiltersFromActiveTableStore(queryFields, fields)
    );

    const abortControllers = abortControllersRef.current;
    return () => {
      for (const controller of abortControllers) {
        controller.abort();
      }
      setLoading(false);
      setData(null);
      setError(null);
    };
  }, [fields, queryFields]);

  useEffect(() => {
    return dispatch(resetQuery());
  }, [dispatch]);

  return {
    isEnabledForThisPage,
    loading,
    data,
    error,
    queryUuid,
    loadSingleFieldByName,
  };
}

/**
 * Abstracts away data that are needed for both active tables and other charts,
 * they just need to be assembled in different ways.
 *
 * @param pageUuid
 * @param isNotActiveTable
 * @returns ?Array<object>
 */
function useQueryFields(pageUuid, isNotActiveTable) {
  const dispatch = useDispatch();

  const { queryUuid } = useSingleQueryFiltersBasic(pageUuid);

  const activeQuery = useSelector((state) => state.dataSettings.activeQuery);
  const activeTableQueryFields = useSelector(
    (state) => state.activeTable.queryFields
  );
  const notActiveTableQueryFields = useMemo(() => {
    return activeQuery?.dataSources[0].fields;
  }, [activeQuery?.dataSources]);

  const queryFields = activeTableQueryFields ?? notActiveTableQueryFields;

  useEffect(() => {
    if (isNotActiveTable && queryUuid && activeQuery?.uuid !== queryUuid) {
      dispatch(getQuery(queryUuid));
    }
  }, [activeQuery?.uuid, dispatch, isNotActiveTable, queryUuid]);

  return queryFields;
}

/**
 * Returns some basic data, the most important which being
 * whether single query filters are enabled for the page.
 */
export function useSingleQueryFiltersBasic(pageUuid) {
  const page = useSelector(getSelectorForCurrentPageByUuid(pageUuid));
  const queryUuid = getSingleDataSourceFiltersQueryUuidByPage(page);
  return {
    page,
    queryUuid,
    isSingleQueryFiltersEnabled: page?.singleDataSourceMenuFilters,
  };
}

export function useIsSingleQueryFiltersEnabledOnThisPage(pageUuid) {
  const page = useSelector(getSelectorForCurrentPageByUuid(pageUuid));
  return page?.singleDataSourceMenuFilters;
}
