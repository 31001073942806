import React from "react";
import PropTypes from "prop-types";
import PositionCircle from "./PositionCircle";
import { mapTooltipConfig } from "../Tooltip/mapTooltipConfig";

export default function PositionCirclesPlaced(props) {
  const {
    xScale,
    yScale,
    markerKey,
    xKey,
    yKey,
    radius,
    color,
    onZoom,
    setTooltip,
    borderSize,
    meta,
    xInner,
    stackedBarAllowance,
    groupBy,
    valueKeys,
    xKeys,
  } = props;

  const data = props.data.filter((d) => d[xKey]);

  const yPos = (d) => yScale(d[yKey]);

  const getXPos = (d) => {
    const pos = xScale(d[xKey]);
    let offset = xScale.bandwidth() / 2;
    if (stackedBarAllowance) {
      offset += xInner.bandwidth() / 2;
    }
    return isNaN(pos) ? 0 : pos + offset;
  };

  const getYPos = (d) => {
    const posY = yPos(d);

    return isNaN(posY) ? 0 : posY;
  };

  function handleTooltip(d, index) {
    const matchingRow = d.allData ?? d;
    const currentXKey = (xKeys ?? [])[index] ?? xKey;

    const tt = mapTooltipConfig(
      getXPos(d),
      getYPos(d) + 26,
      matchingRow,
      yKey,
      currentXKey,
      null,
      meta,
      d,
      groupBy,
      valueKeys
    );
    setTooltip(tt);
  }

  return data.map((d, i) => (
    <React.Fragment key={"circle-" + i}>
      {d[yKey] !== null && d[yKey] !== undefined ? (
        <PositionCircle
          cx={getXPos(d)}
          cy={getYPos(d)}
          radius={radius}
          color={color}
          mood={d[markerKey] && d[markerKey].mood}
          onClick={() =>
            onZoom && d[markerKey] && d[markerKey].mood ? onZoom(d) : null
          }
          onMouseOver={() => handleTooltip(d, i)}
          borderSize={borderSize}
          onMouseOut={() => setTooltip(null)}
        />
      ) : null}
    </React.Fragment>
  ));
}

PositionCirclesPlaced.propTypes = {
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  markerKey: PropTypes.string,
  xKey: PropTypes.string,
  yKey: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  bands: PropTypes.bool,
  onZoom: PropTypes.func,
};
