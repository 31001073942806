import styled from "@emotion/styled";
import RadioButton from "../../../../UI/Form/RadioButton/RadioButton";
import Select from "../../../../UI/Form/Select/Select";
import { toDateTypeOptions } from "../../../../utils/constants/constants";
import { FieldWrapper } from "./GlobalSettings";
import Input from "../../../../UI/Form/Input/Input";
import { getBlendedQueryFieldLableWithAlias } from "../Layout/helper";

const RadioBox = styled.div`
  display: flex;
  align-items: center;
  & > label:first-of-type {
    margin-bottom: 0px;
  }
`;

export default function Dates({
  chart,
  setChartState,
  isParameterized,
  immutableChart,
  fields,
  parameters = [],
  theme,
}) {
  const {
    allDates = false,
    dateKey,
    dateKeys,
    toDateType,
    toDateCount,
  } = chart;

  const changeDateOverride = () => {
    setChartState({
      ...chart,
      allDates: !chart.allDates,
      ...(isParameterized
        ? { dateKeys: !chart.allDates ? null : immutableChart.dateKeys }
        : { dateKey: !chart.allDates ? null : immutableChart.dateKey }),
    });
  };

  const changeDateKey = (o) => {
    setChartState({ ...chart, dateKey: o?.name });
  };

  const changeDateKeys = (option, name) => {
    setChartState({
      ...chart,
      dateKeys: { ...(chart.dateKeys || {}), [name]: option.name },
    });
  };

  const changeToDateType = (option) => {
    setChartState({ ...chart, toDateType: option?.value });
  };

  const changeToDateCount = (e) => {
    setChartState({ ...chart, toDateCount: +e.target.value });
  };

  const dateKeyElement = !allDates ? (
    <Select
      options={fields}
      getOptionLabel={(option) => getBlendedQueryFieldLableWithAlias(option)}
      getOptionValue={(option) => option.name}
      value={fields.find((f) => f.name === dateKey)}
      onChange={changeDateKey}
    />
  ) : null;

  const showRollingInput = (toDateType ?? "").includes("ROLLING");

  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      <br />
      <span>Date Overrides</span>
      <br />
      <RadioBox>
        <RadioButton checked={!allDates} onChange={changeDateOverride} />
        <label>Use Filters for Range</label>
      </RadioBox>
      <br />
      {!isParameterized ? (
        <FieldWrapper>{dateKeyElement}</FieldWrapper>
      ) : (
        <FieldWrapper>
          <Select
            options={parameters}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={parameters.find((f) => f.name === dateKeys?.start)}
            onChange={(option) => changeDateKeys(option, "start")}
          />
          <br />
          <Select
            options={parameters}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={parameters.find((f) => f.name === dateKeys?.end)}
            onChange={(option) => changeDateKeys(option, "end")}
          />
        </FieldWrapper>
      )}
      <br />
      <RadioBox>
        <RadioButton checked={allDates} onChange={changeDateOverride} />
        <label>Show all Dates</label>
      </RadioBox>

      <br />
      <Select
        options={toDateTypeOptions}
        label="Date Range Override"
        value={toDateTypeOptions.find((o) => o.value === toDateType)}
        onChange={changeToDateType}
        isClearable
      />
      <br />

      {showRollingInput && (
        <>
          <Input
            label={`${getRollingPlaceHolder(toDateType)} Count`}
            onChange={changeToDateCount}
            type="number"
            min="1"
            value={toDateCount ?? ""}
          />
          <br />
        </>
      )}
      <hr color={theme.background.secondary} style={{ width: "100%" }} />
    </div>
  );
}

export function getRollingPlaceHolder(toDateType) {
  const { label: placeholder } =
    toDateTypeOptions.find((item) => item.value === toDateType) ?? {};

  return `${(placeholder ?? "").replace("Rolling", "")} Count`;
}
