import Select from "../../../UI/Form/Select/Select";
import Input from "../../../UI/Form/Input/Input";
import { getToDateType } from "../../../store/actions/queryBuilder/getRange";
import useDebounceChange from "../../../hooks/useDebounceChange";
import DatePicker from "../../../UI/DatePicker/DatePicker";
import { useTheme } from "emotion-theming";
import { pageLoadDateString } from "../../../utils/formatters/dateFormatter";
import { getUserSelectedDateOption } from "./customDateUtils";

export default function RollingDates(props) {
  const {
    selectProps,
    type,
    recentMonthsChange,
    toDateType,
    label,
    defaultRollingDate = null,
    activeTabUuid,
    selectedDateType,
    saveStickyDatesToStore,
    dateFiltersConfig,
  } = props;

  // if there is no default count set in site config
  const defaultDefinedCount = 1;

  const rollingDate = {
    ...(defaultRollingDate ?? {}),
    ...(getUserSelectedDateOption(dateFiltersConfig, activeTabUuid) ?? {}),
  };

  const theme = useTheme();

  const isToDateType = (toDateType + "").includes("_TO_DATE");
  const showCountInput = isToDateType || rollingDate;

  const { debounce } = useDebounceChange();

  function getRollingRange(endDate, count) {
    if (!Number.isInteger(count)) {
      return;
    }

    const query = {
      toDateType,
      toDateCount: count,
    };

    // using same function for toDateType from chart config
    return getToDateType(query, { end: { value: endDate } }, true);
  }

  // Handle rolling date count change, debounced api call
  function applyRollingDateCount(e) {
    const endDate = isToDateType ? pageLoadDateString : rollingDate?.to;
    const range = getRollingRange(endDate, +e.target.value);

    const rolling = {
      ...rollingDate,
      from: range.start,
      to: range.end,
      count: +e.target.value,
    };

    debounce(() => recentMonthsChange(rolling));
    saveStickyDatesToStore(selectedDateType, rolling);
    e.preventDefault();
    e.stopPropagation();
  }

  // Handle change recent days/weeks/months/quarters/years
  function onRollingDateChange(option) {
    const count = rollingDate.count ?? defaultDefinedCount;
    const range = getRollingRange(option.to, count);

    // if rolling date count already set then api call on rolling type chage
    if (range) {
      const rolling = {
        ...option,
        from: range.start,
        to: range.end,
        count,
      };

      recentMonthsChange(rolling);
      saveStickyDatesToStore(selectedDateType, rolling);
    }
  }

  const options = (selectProps ?? {})[type]?.options ?? [];
  // reset old value when user change rolling type option
  function getOption() {
    return options.find((option) => option.label === rollingDate?.label);
  }

  function renderListOrPicker() {
    if (isToDateType) {
      return null;
    }

    return (
      <>
        <br />
        {selectProps ? (
          <Select
            options={options}
            onChange={onRollingDateChange}
            getOptionValue={(o) => o.from}
            placeholder={selectProps[type]?.placeholder}
            value={getOption()}
            label={`Most Recent ${label}`}
            overPrimary={theme.menuPrimary}
          />
        ) : (
          <DatePicker
            onChange={(e) => onRollingDateChange({ to: e.target.value })}
            value={rollingDate?.to}
          />
        )}
      </>
    );
  }

  return (
    <>
      {renderListOrPicker()}

      {showCountInput && (
        <>
          <br />
          <Input
            label={`${label}s count`}
            onChange={applyRollingDateCount}
            type="number"
            min="1"
            value={rollingDate.count ?? defaultDefinedCount}
            overPrimary={theme.menuPrimary}
          />
        </>
      )}
    </>
  );
}
