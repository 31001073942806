import React, { useState } from "react";
import GroupedBarTwo from "./GroupedBar/GroupedBarTwo";
import { useTheme } from "emotion-theming";
import { useDispatch, useSelector } from "react-redux";
import Details from "../Layout/Details/Details";
import VisualizationBase from "./BaseChart/VisualizationBase";
import convertDateAggStrings from "../utils/dates/convertDateAggStrings";
import {
  buildDrilldownDateFilters,
  mergeExpandedBars,
  buildDrilldownFilters,
} from "../utils/func";
import { loadRowExpandChart } from "../store/actions/dashboard/dashboard";
import Refreshing from "./BaseChart/Refreshing";
import Loading from "../UI/Loading/Loading";
import Legend from "./Legend/Legend";
import ChartActionsToggle from "./ChartActions/ChartActionsToggle";
import conformTargetBars from "./GroupedBar/conformTargetBars";

const defaultColorRange = {
  almost: "#ed8836",
  short: "#bc3339",
  success: "#5bb85d",
  tolerance: 4000,
};

export default function GroupedBarVisualization(props) {
  const { chart, details, dashboardId } = props;

  const [bar, setBar] = useState(null);
  const dispatch = useDispatch();
  const theme = useTheme();

  const margin = {
    top: 20,
    left: chart.yAxisTypeLabel ? 52 : 40,
    bottom: 32,
    right: 20,
  };

  const {
    barExpandVisualizationParams,
    xKey,
    hasBarExpand,
    tolerance,
    toleranceIsPercent,
    legendHeight,
    labelFormula,
    meta,
  } = chart;
  const {
    visualizationId,
    dynamicFilter,
    dynamicFilterKey,
    useNonDatesXkey,
    parameterizedFilterPrefix,
  } = barExpandVisualizationParams ?? {};
  const {
    legendSections,
    legendColors,
    sameBarWidth,
    variableValueKeys,
    valueKeys,
  } = chart;

  const { valueKey1, valueKey2 } = conformTargetBars(
    valueKeys,
    chart.valueKey1,
    chart.valueKey2
  );

  // For drill through charts
  const [activeValueKey, setActiveValueKey] = useState(
    variableValueKeys?.length && variableValueKeys[0]
  );

  const colorRange = {
    ...defaultColorRange,
    tolerance: tolerance || defaultColorRange.tolerance,
    toleranceIsPercent,
  };

  const rowExpandedCharts = useSelector(
    (state) => state.dashboard.rowExpandedCharts
  );

  const {
    additionalData = [],
    loading,
    refreshing,
    xKey: expandedXKey,
  } = rowExpandedCharts[visualizationId] ?? {};

  const d = convertDateAggStrings(chart.convertToDate)(
    chart.data.map((d) => (hasBarExpand ? { ...d, clicable: true } : d))
  );

  const data = chart.maxBars > 0 ? d.slice(0, chart.maxBars) : [...d];
  if (chart.sort) {
    data.sort((a, b) => +a[chart.sort] - +b[chart.sort]);
  }

  const expandBar = (barItem, color) => {
    if (!barItem.clicable) {
      return;
    }

    if (bar && bar[xKey] === barItem[xKey]) {
      setBar(null);
      return;
    }

    const filters = useNonDatesXkey
      ? buildDrilldownFilters({
          bar: barItem,
          xKey,
          dynamicFilter,
          parameterizedFilterPrefix,
        })
      : buildDrilldownDateFilters(dynamicFilterKey, barItem[dynamicFilter]);

    dispatch(
      loadRowExpandChart(filters, {
        ...chart,
        ...chart.barExpandVisualizationParams,
        visualizationId,
      })
    );
    const activeBar = { ...barItem, color };

    setBar(activeBar);
  };

  const withExpandedBars = mergeExpandedBars(
    data,
    additionalData,
    xKey,
    expandedXKey
  )(bar);

  return (
    <>
      <div id={chart.visualizationId} style={{ position: "relative" }}>
        <Refreshing show={loading || refreshing} comp={<Loading />} />
        <VisualizationBase
          {...{
            ...props,
            margin: {
              ...margin,
              bottom: chart.overrideMarginBottom || margin.bottom,
            },
          }}
        >
          <GroupedBarTwo
            {...chart}
            meta={chart.meta}
            bar2ColorRange={colorRange}
            bar2DefaultColor={colorRange.success}
            bar2Labels
            additionalXKeys={additionalData.map((v) => v[expandedXKey])}
            expandedXKey={expandedXKey}
            barWidth1={sameBarWidth ? 1 : 0.3}
            barWidth2={1}
            color1={(legendColors && legendColors[0]) || "#30607a"}
            color={(legendColors && legendColors[1]) || chart.color}
            data={withExpandedBars}
            intraGroupSpacing={10}
            labelColor={theme.text.secondary}
            labelFormat={chart.labelFormat || "$.2s"}
            labelSize={10}
            labelWeight="bold"
            valueKey1={valueKey1}
            valueKey2={activeValueKey?.valueKey || valueKey2}
            valuePadding={1.05}
            xKey={xKey}
            padZero={chart.padZero}
            xLabelKey={chart.xLabelKey}
            yAxisGrid
            yAxisGridColor={theme.divider}
            xTicksColor={theme.text.secondary}
            yTicksColor={theme.text.secondary}
            yTicksCount={5}
            maxBars={chart.maxBars}
            disableRounding={chart.disableRounding}
            hideYAxis={chart.hideYAxis}
            xLabelFormat={chart.xLabelFormat}
            yAxisFormat={chart.yAxisFormat}
            xAxisFormat={chart.xAxisFormat}
            expandBar={expandBar}
            setBar={setBar}
            bar={bar}
            expandedData={additionalData}
            xAxisDate={chart.xAxisDate}
            reverseBarColors={chart.reverseBarColors}
            targetLabels={chart.targetLabels}
            showLegend={chart.showLegend}
            labelFormula={labelFormula}
          />
        </VisualizationBase>
        <ChartActionsToggle
          variableValueKeys={chart.variableValueKeys}
          setVariableValueKey={setActiveValueKey}
          activeValueKey={activeValueKey}
        />
      </div>
      {!chart.hideLegend ? (
        <div style={{ marginLeft: margin.left }}>
          <Legend
            horizontal
            colors={
              legendColors || [
                { color: "#30607a" },
                { color: "#5bb85d" },
                { color: "#bc3339" },
                { color: "#e28936" },
              ]
            }
            sections={
              legendSections || [
                "Target",
                "Actual(Above)",
                "Actual(Below)",
                "Actual(Close)",
              ]
            }
            legendHeight={legendHeight}
            meta={meta}
          />
        </div>
      ) : null}
      {details && chart.data.length > 0 ? (
        <Details
          dashboardName={dashboardId}
          visualizationId={chart.visualizationId}
        />
      ) : null}
    </>
  );
}
