import * as actions from "../actions/actionTypes";

const initialState = {
  loading: false,
  printLoading: false,
  templates: null,
};

export default function htmlTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_QUERY_HTML_TEMPLATES_START:
    case actions.UPDATE_QUERY_HTML_TEMPLATES_START:
    case actions.GET_QUERY_HTML_TEMPLATES_START:
    case actions.DELETE_QUERY_HTML_TEMPLATES_START:
      return {
        ...state,
        loading: true,
      };

    case actions.CREATE_QUERY_HTML_TEMPLATES_FAIL:
    case actions.UPDATE_QUERY_HTML_TEMPLATES_FAIL:
    case actions.GET_QUERY_HTML_TEMPLATES_FAIL:
    case actions.DELETE_QUERY_HTML_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_QUERY_HTML_TEMPLATES_SUCCESS:
      return {
        state,
        templates: action.results.data,
        loading: false,
      };

    case actions.CREATE_QUERY_HTML_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: getUnionTemplates(state.templates, action.results.data),
        loading: false,
      };

    case actions.UPDATE_QUERY_HTML_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: getUpdatedTemplates(state.templates, action.results.data),
      };

    case actions.DELETE_QUERY_HTML_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: state.templates?.filter(
          (template) => template.uuid !== action.templateUuid
        ),
      };

    case actions.PRINT_QUERY_HTML_TEMPLATE_FORM_START:
      return {
        ...state,
        printLoading: true,
      };

    case actions.PRINT_QUERY_HTML_TEMPLATE_FORM_SUCCESS:
    case actions.PRINT_QUERY_HTML_TEMPLATE_FORM_FAIL:
      return {
        ...state,
        printLoading: false,
      };

    default:
      return state;
  }
}

function getUnionTemplates(templates, newTemplate) {
  return [
    ...(templates ?? []).map((template) => ({
      ...template,
      ...(newTemplate.isDefault && { isDefault: false }),
    })),
    newTemplate,
  ];
}

function getUpdatedTemplates(templates, updatedTemplate) {
  return templates?.map((template) => {
    if (template.uuid === updatedTemplate.uuid) {
      return updatedTemplate;
    }

    return {
      ...template,
      ...(updatedTemplate.isDefault && { isDefault: false }),
    };
  });
}
