import { useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import IOButton from "../../../UI/Form/Button/IOButton";
import styled from "@emotion/styled";
import { scrollbarDe } from "../../../styles/styledSnippets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../UI/Flex/Flex";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import Checkbox from "../../../UI/Form/Checkbox/Checkbox";

const Container = styled.div(
  ({ theme }) => `
  color: ${theme.text.secondary};
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbarDe(theme)};

  & > div {
    padding: 5px;
  }
`
);

const ValueContainer = styled.div`
  margin-left: 45px;
  font-style: italic;
  font-size: 12px;
`;

const MinusContainer = styled.span(
  ({ theme }) => `
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.notification.warningMain};
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
`
);

export default function ResetSavedSettings(props) {
  const {
    theme,
    selectedFilters,
    filters,
    updateSavedFilters,
    loading,
    savedFilters,
  } = props;
  const [show, setShow] = useState(false);
  const [resetDates, setResetDates] = useState(false);

  const uuids = Object.keys(selectedFilters ?? {}).filter((uuid) =>
    (savedFilters ?? []).some((sf) => sf.menuFilter?.uuid === uuid)
  );

  const checkboxItems = uuids.reduce((acc, uuid) => {
    const filter = (filters ?? []).find((filter) => filter.uuid === uuid);

    if (filter) {
      acc.push({
        displayName: filter.displayName,
        values: selectedFilters[uuid],
        uuid,
        name: filter.name,
      });
    }

    return acc;
  }, []);

  const [rememberedItems, setRememberedItems] = useState(checkboxItems);

  function removeFilterFromRememberedList(uuid) {
    const updated = rememberedItems.filter((filter) => filter.uuid !== uuid);

    setRememberedItems(updated);
  }

  function onClose() {
    setRememberedItems(checkboxItems);
    setResetDates(false);
    setShow(false);
  }

  async function onUpdate() {
    const filters = Object.fromEntries(
      rememberedItems.map((item) => [item.uuid, item.values])
    );

    await updateSavedFilters(filters, resetDates);
    onClose();
  }

  return (
    <>
      <IOButton
        onClick={() => setShow(true)}
        smallPadding
        cy="reset-filters-button"
        cancel
        bgPrimary={theme.menuPrimary}
        type="button"
        disabled={uuids.length === 0}
      >
        RESET SETTINGS
      </IOButton>

      {show ? (
        <Modal close={onClose} title="Reset Remembered Filters">
          <Container>
            {rememberedItems.map((filter, index) => (
              <div key={filter.uuid + index}>
                <Flex>
                  <MinusContainer>
                    <FontAwesomeIcon
                      icon={["fas", "minus"]}
                      onClick={() =>
                        removeFilterFromRememberedList(filter.uuid)
                      }
                    />
                  </MinusContainer>
                  <b>{filter.displayName}</b>&nbsp;[{filter.name}]:
                </Flex>
                {filter.values.map((val, index) => (
                  <ValueContainer key={`${val}-${index}`}>{val}</ValueContainer>
                ))}
              </div>
            ))}
          </Container>
          <br />
          <br />
          <ButtonGroup position="center" spacing={10}>
            <Checkbox
              label="Reset Saved Dates"
              checked={resetDates}
              onChange={() => setResetDates(!resetDates)}
            />
            <IOButton
              smallPadding
              cancel
              style={{ width: "fit-content" }}
              onClick={onClose}
            >
              Cancel
            </IOButton>
            <IOButton
              smallPadding
              style={{ width: "fit-content" }}
              disabled={uuids.length === rememberedItems.length && !resetDates}
              onClick={onUpdate}
              processing={loading}
              type="button"
            >
              Update
            </IOButton>
          </ButtonGroup>
        </Modal>
      ) : null}
    </>
  );
}
