import showFilter from "./showFilter";

class DateFilterConfig {
  constructor(
    dateFiltersConfig,
    onDateTermHandler,
    term,
    onDateChangeHandler,
    startDate,
    endDate,
    toDate,
    setSelectedDateType,
    selectedDateType,
    recentMonthsChange,
    execDateFilters,
    onDateTypeChangeHandler
  ) {
    this.dateFiltersConfig = dateFiltersConfig;
    this.onDateTermHandler = onDateTermHandler;
    this.term = term;
    this.onDateChangeHandler = onDateChangeHandler;
    this.startDate = startDate;
    this.endDate = endDate;
    this.toDate = toDate;
    this.setSelectedDateType = setSelectedDateType;
    this.selectedDateType = selectedDateType;
    this.recentMonthsChange = recentMonthsChange;
    this.execDateFilters = execDateFilters;
    this.onDateTypeChangeHandler = onDateTypeChangeHandler;
  }

  show(currentTabId, dataSourceAccessConfig) {
    return showFilter(
      { hide: this.dateFiltersConfig?.hideOnPages },
      currentTabId,
      dataSourceAccessConfig
    );
  }

  setCustomDateRange(from, to) {
    this.execDateFilters(from, to, {
      value: "cr",
      label: "Custom Range",
      period: null,
    });
  }
}

export default DateFilterConfig;
