import React from "react";
import * as actionTypes from "../../store/actions/actionTypes";
import axios from "../../axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../store/actions/message";
import ButtonLink from "../../UI/ButtonLink/ButtonLink";

export default function DownloadLink(props) {
  const { url, text } = props;
  const dispatch = useDispatch();

  const downloadFile = (endpoint) => async (dispatch) => {
    dispatch({ type: actionTypes.DOWNLOAD_FILE_START });

    try {
      const res = await axios.get("/api/v1" + endpoint, {
        responseType: "arraybuffer",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Flash_Governance_Report.xlsx");
      document.body.appendChild(link);
      link.click();

      showToast(
        actionTypes.DOWNLOAD_FILE_SUCCESS,
        ["Report successfully downloaded."],
        "success"
      );
    } catch (error) {
      const handleErrors = error.response.data.errors
        ? Object.values(error.response.data.errors).map((error) => error[0])
        : [error.response.data.message];
      showToast(actionTypes.DOWNLOAD_FILE_FAIL, handleErrors, "danger");
    }
  };

  const handleClick = (endpoint) => {
    dispatch(downloadFile(endpoint));
  };

  return (
    <div style={{ margin: "20px 0 0 0" }}>
      <ButtonLink onClick={() => handleClick(url)}>{text}</ButtonLink>
    </div>
  );
}
