import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import PageDisplayName from "../../UI/PageDisplayName";

const activeColor = (isCurrent, theme, sub) => {
  return sub
    ? "transparent"
    : isCurrent
    ? theme.primary
    : theme.type === "dark"
    ? theme.gray.gray400
    : theme.gray.gray600;
};

function calculateBackground(isFlat, isCurrent, theme, subPrimary, sub) {
  if (isCurrent && sub && !subPrimary) return "transparent";
  if (!isFlat && isCurrent) return theme.primary;
  return "transparent";
}

const Tab = styled.div(
  ({ theme, isCurrent, isFlat, sub, subPrimary }) => `
    margin-right: 12px;
    position: relative;
    font-size: 12px;
    // For subpages we want to see equal padding on both sides of each subtab
    // when the "active" square is not there.
    padding: ${sub ? "4px 22px 4px 6px" : isFlat ? "6px 70px" : "4px 18px"};
    white-space: nowrap;
    ${
      isFlat
        ? `border-bottom: 2px solid ${isCurrent ? "black" : "transparent"};`
        : `border: .05rem solid ${activeColor(isCurrent, theme, sub)};`
    }
    
    ${isCurrent && `font-weight: 500;`};
    color: ${
      isFlat
        ? theme.text.primary
        : isCurrent
        ? sub
          ? theme.text.primary
          : theme.text.textOnPrimary
        : activeColor(isCurrent, theme)
    };
    ${isFlat ? `font-weight: 700;` : ""}
    background: ${calculateBackground(
      isFlat,
      isCurrent,
      theme,
      subPrimary,
      sub
    )};
    cursor: pointer;
    &:hover {
      ${
        isFlat
          ? `
      border-bottom: 2px solid orange;
      `
          : `
        background: ${theme.primary};
        color: ${theme.text.textOnPrimary};
        border-color: ${theme.primary};
    `
      }
    }
    margin-bottom: ${isFlat ? 0 : 5}px;
`
);

const Square = styled.div`
  height: 11px;
  width: 11px;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  top: 1px;
  left: 0;
  border-radius: 2px;
  flex-grow: 0;
  flex-shrink: 0;
  background: ${(props) =>
    props.active ? props.theme.primary : "transparent"};
`;

export default function TabContent(props) {
  const { isFlat, sub, subPrimary, currentTab, onChange } = props;
  function active(item, currentTab) {
    if (!currentTab) return false;
    const isCurrentKey = props.current === item.key;
    const isCurrentSlug = props.current === item.slug;
    const isParentTab = currentTab.parent === item.displayName && !sub;
    return isCurrentKey || isCurrentSlug || isParentTab;
  }

  return props.list
    .filter((item) => !item.mobile)
    .map((item, i) => {
      const arrayKey = item.slug || item.key || i;
      const hasLink = props.baseUrl || item.link || item.slug;

      const tab = (
        <Tab
          key={arrayKey}
          data-cy={props.cy}
          isCurrent={active(item, currentTab)}
          onClick={() => {
            if (onChange && item.key) {
              onChange(item.key);
            }
          }}
          isFlat={isFlat}
          sub={sub}
          subPrimary={subPrimary}
        >
          {sub && !subPrimary ? (
            <Square active={props.current === item.slug} />
          ) : null}
          <PageDisplayName page={item} />
        </Tab>
      );

      if (hasLink) {
        return (
          <Link
            key={arrayKey}
            to={item.link || "/" + props.baseUrl + "/" + item.slug}
          >
            {tab}
          </Link>
        );
      }

      return tab;
    });
}
