import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  comments: [],
  loading: false,
  commentActionLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMMENTS_TABLE_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_COMMENTS_TABLE_SUCCESS:
      return {
        ...state,
        comments: action.data,
        loading: false,
      };

    case actionTypes.GET_COMMENTS_TABLE_FAIL:
    case actionTypes.CREATE_COMMENTS_FAIL:
    case actionTypes.UPDATE_COMMENTS_FAIL:
      return {
        ...state,
        loading: false,
        commentActionLoading: false,
      };

    case actionTypes.CREATE_COMMENTS_START:
    case actionTypes.UPDATE_COMMENTS_START:
      return {
        ...state,
        commentActionLoading: true,
      };

    case actionTypes.CREATE_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: [...state.comments, action.results.data],
        commentActionLoading: false,
      };

    case actionTypes.UPDATE_COMMENTS_SUCCESS: {
      const item = action.results.data;

      return {
        ...state,
        comments: state.comments.map((comment) =>
          comment.uuid === item.uuid ? item : comment
        ),
        commentActionLoading: false,
      };
    }

    case actionTypes.DELETE_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: state.comments.filter(
          (comment) => comment.uuid !== action.commentUuid
        ),
      };

    default:
      return state;
  }
};
