import { useCallback, useRef, useLayoutEffect, useEffect } from "react";
import axios from "../../../axios";
import { handleError } from "../../../utils/errorHandling";
import {
  FilterDisplayLabel,
  getSpecialLabel,
} from "../../../Layout/Filters/GeneralFilters";

export const MAX_ITEMS = 100;

export default function () {
  const selectRef = useRef(null);

  // This allows for being able to close the menu on blur when defaultMenuIsOpen
  useLayoutEffect(() => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  }, []);

  const refObjectsByIdRef = useRef({});

  const loadValuesSearch = useCallback((queryUuid, fieldName, search = "") => {
    const data = {
      perPage: MAX_ITEMS + 1,
      format: "chart",
      order: {
        [fieldName]: "ASC",
      },
      overrides: {
        other: {
          isDistinct: true,
        },
        fields: [
          {
            name: fieldName,
          },
        ],
      },
    };
    if (search) {
      data.filters = [{ name: fieldName, values: [search], operator: "like" }];
    }
    return axios
      .post(`/api/v1/queries/${queryUuid}/exec`, data)
      .then((response) => {
        const options = [];
        if (MAX_ITEMS < response.data.data.length) {
          options.push({
            label: <u>(Type to see more options)</u>,
            disabled: true,
          });
        }
        options.push({
          value: null,
          label: <em>(Empty)</em>,
          search: "(none) null empty",
        });
        for (const row of response.data.data) {
          const value = row[fieldName];
          if (value === null) {
            continue;
          }
          options.push({
            value: String(value),
            label: FilterDisplayLabel({
              label: value,
            }),
            search: String(getSpecialLabel(value) ?? value),
          });
        }
        return options;
      })
      .catch(handleError);
  }, []);

  const loadValues = useCallback(
    (queryUuid, fieldName) => {
      const refObjectsById = refObjectsByIdRef.current;
      const id = `${queryUuid}-${fieldName}`;
      if (!refObjectsById[id]) {
        const abortController = new AbortController();
        refObjectsById[id] = {
          id,
          abortController: abortController,
          refCount: 0,
          options: null,
        };
      }
      const refObject = refObjectsById[id];
      if (refObject.refCount === 0) {
        refObject.promise = loadValuesSearch(queryUuid, fieldName).then(
          (options) => {
            refObject.options = options;
            return options;
          }
        );
      }
      refObject.refCount++;
      return refObject.promise;
    },
    [loadValuesSearch]
  );

  useEffect(() => {
    const refObjectsById = refObjectsByIdRef.current;
    return () => {
      for (const refObject of Object.values(refObjectsById)) {
        refObject.abortController.abort();
      }
    };
  }, []);

  return { loadValues, loadValuesSearch };
}
