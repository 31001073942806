/**
 * Filters options based on the search value.
 *
 * @param {Object} props - The option object containing a value.
 * @param {string} searchValue - The value to filter options against.
 * @returns {Object|null} - Returns the option if it matches the search, otherwise null.
 */
export function filterOptionCallback(props, searchValue) {
  const value = String(props.value).toLowerCase();
  const search = searchValue.toLowerCase();

  // If the option value is "*", return it without filtering.
  if (value === "*") {
    return props;
  }

  return value.includes(search) ? props : null;
}

/**
 * Determines the selected options, including the "Select All" option if applicable.
 *
 * @param {Array} options - Available options.
 * @param {Array} value - Currently selected values.
 * @param {Object} allOption - Special option representing "Select All".
 * @param {boolean} showSelectAll - Whether to show the "Select All" option.
 * @returns {Array} - Updated selected values including "Select All" if all options are selected.
 */
export function getOptionValue(options, value, allOption, showSelectAll) {
  if (showSelectAll && value?.length === options.length && options.length) {
    return [allOption, ...value];
  }

  return value;
}

/**
 * Calculates the number of active filters excluding the "Select All" option.
 *
 * @param {Array} value - Selected filter values.
 * @param {Object} allOption - Special option representing "Select All".
 * @returns {number} - The count of selected filters excluding "Select All".
 */
export function getActiveFiltersQuantity(value, allOption) {
  return value ? value.filter((v) => v.value !== allOption.value).length : 0;
}

/**
 * Filters options based on a search value.
 *
 * @param {Array} options - List of options to search within.
 * @param {string} searchValue - Search input value.
 * @returns {Array} - Filtered options matching the search.
 */
export function getCurrentFiltersAfterSearch(options, searchValue) {
  return options.filter((option) => filterOptionCallback(option, searchValue));
}
