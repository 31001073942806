import { useState, useEffect } from "react";
import { chunk } from "lodash-es";

export default function BatchRenderer({ itemCallbacks, batchSize }) {
  const [itemsToRender, setItemsToRender] = useState([]);

  useEffect(() => {
    setItemsToRender([]);
    const abortController = new AbortController();
    (async () => {
      const chunks = chunk(itemCallbacks, batchSize);
      for (const itemCallbacksChunk of chunks) {
        if (abortController.signal.aborted) {
          return;
        }
        setItemsToRender((itemsToRender) => {
          return [...itemsToRender, ...itemCallbacksChunk.map((c) => c())];
        });
        await giveFrontendAChanceToBreath();
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [batchSize, itemCallbacks]);

  return itemsToRender;
}

async function giveFrontendAChanceToBreath() {
  return new Promise((resolve) => {
    idleTimeout(() => {
      resolve();
    }, 100);
  });
}

function idleTimeout(callback, timeout) {
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(callback, { timeout });
  } else {
    return setTimeout(callback, timeout);
  }
}
