import { format } from "date-fns";
import { absoluteDate, dateFromMonth, dateFromWeek } from "../dates/dateFunc";

export default (dynamicTerm, xKey, ignore) => {
  if (ignore) return xKey;
  return (termMap[dynamicTerm] || "") + xKey;
};

export const xKeyFormatted = (val, term) => {
  if (!val) return "";
  switch (term) {
    case "daily":
      if (!isNaN(val)) return val;
      return format(absoluteDate(val), "M/d/yyyy");
    case "monthly": {
      if (!isNaN(val)) return format(dateFromMonth(val), "MMM");
      const splits = val.split(" M");
      return format(dateFromMonth(splits[1], splits[0]), "MMM yyyy");
    }
    case "weekly": {
      if (!isNaN(val)) return val;
      const splits = val.split(" W");
      return format(dateFromWeek(splits[1], splits[0]), "M/d/yyyy");
    }
    default:
      return val;
  }
};

export const xKeyTicks = (length, index, allTicks) =>
  allTicks || length < 12 || index % Math.floor(length / 10) === 0;

export const termMap = {
  yearly: "Year",
  quarterly: "Quarter",
  monthly: "Month",
  weekly: "Week",
  daily: "Day",
};

export function getXAxisFormat(xKeyFormat, xKey, meta) {
  if (xKeyFormat) {
    return xKeyFormat;
  }

  const { aggregateTypeId, type } =
    (meta?.fields ?? []).find((f) => f.alias === xKey) ?? {};

  switch (aggregateTypeId) {
    case 8: // MONTH
      return "MMM yyyy";

    case 6: // YEAR
      return "yyyy";

    case 7: // QUARTER
      return "'Q'Q yyyy";

    default:
      return type ?? "string";
  }
}
