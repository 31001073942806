import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import TypeIcon from "../TypeIcon";
import { mappingTypes } from "../../utils/constants/dataTypes";

const typeStrings = [
  "string",
  "currency",
  "currency-whole",
  "decimal",
  "percent",
  "date",
  "integer",
  "api-image",
];

export default function FormatSelector(props) {
  const { handleSetValue, active, simple, allowed } = props;

  const iconValue = (t) => {
    const useType = mappingTypes[t] ? t : "string";
    return [mappingTypes[useType].lib, mappingTypes[useType].icon];
  };

  const allowedStrings = allowed
    ? typeStrings.filter((t) => allowed.find((a) => a === t))
    : typeStrings;

  return allowedStrings.map((t) => (
    <TypeIcon
      data-cy="type-editor-edit-option"
      key={t}
      type={t}
      onClick={() => handleSetValue(t)}
      active={t === active}
      simple={simple}
      title={t}
    >
      <FontAwesomeIcon data-cy="type-editor-edit-icon" icon={iconValue(t)} />
    </TypeIcon>
  ));
}

FormatSelector.propTypes = {
  handleSetValue: PropTypes.func.isRequired,
  active: PropTypes.string,
  simple: PropTypes.bool,
};
