import { getComparisonModeItem } from "../../Layout/Block/interruptDashboard";

export function applyComparisonSettingsToDraft(draft, filter) {
  const comparisonModeItem =
    getComparisonModeItem(draft.comparisonMode, draft.activeTab?.uuid) ?? {};

  const isComparisonFilter = comparisonModeItem.options?.find(
    (o) => o === filter.key
  );

  // wee should not limit user with 2 filter items on non comparison pages
  const inComparisonPage = comparisonModeItem.pages?.find(
    (p) => p === draft.activeTab?.uuid
  );

  if (isComparisonFilter && inComparisonPage) {
    setCheckedCount();
    resetOtherComparisonOptions();

    function setCheckedCount() {
      const checkedFilterValues = getCheckedCount();

      if (checkedFilterValues.length > comparisonModeItem.count) {
        const menuFilterIndex = draft.menuFilters.findIndex(
          (mf) => mf.name === filter.key
        );

        const filterValueIndexToUncheck = draft.menuFilters[
          menuFilterIndex
        ].values.findIndex(
          (cf) =>
            cf.value !== filter.value &&
            checkedFilterValues.find((cfv) => cfv.value === cf.value)
        );

        draft.menuFilters[menuFilterIndex].values[
          filterValueIndexToUncheck
        ].checked = false;

        draft.comparisonMode = draft.comparisonMode.map((item) => {
          if (item.pages.includes(draft.activeTab.uuid)) {
            return {
              ...item,
              checked: Number.isFinite(item.count) ? item.count : 1,
            };
          }
          return item;
        });
      } else {
        draft.comparisonMode = draft.comparisonMode.map((item) => {
          if (item.pages.includes(draft.activeTab.uuid)) {
            return { ...item, checked: checkedFilterValues.length };
          }
          return item;
        });
      }
    }

    function getCheckedCount() {
      return draft.menuFilters
        .find((mf) => mf.uuid === filter.menuFilterUuid)
        .values.filter((v) => v.checked);
    }

    function resetOtherComparisonOptions() {
      const otherComparisonFilters = comparisonModeItem.options.filter(
        (o) => o !== filter.key
      );

      otherComparisonFilters.forEach((fil) => {
        const menuFilter = draft.menuFilters.find((mf) => fil === mf.name);
        (menuFilter?.values ?? []).forEach((val) => (val.checked = false));
      });
    }
  }
}
