import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../UI/Modal/Modal";
import { useEffect, useState } from "react";
import {
  getSiteConfiguration,
  updateSiteConfiguration,
} from "../../../../store/actions";
import styled from "@emotion/styled";
import IOButton from "../../../../UI/Form/Button/IOButton";
import Flex from "../../../../UI/Flex/Flex";
import ButtonLink from "../../../../UI/ButtonLink/ButtonLink";
import { scrollbarDe } from "../../../../styles/styledSnippets";
import { useShallowEqualSelector } from "../../../../store";
import { Link } from "react-router-dom";
import ButtonGroup from "../../../../UI/ButtonGroup/ButtonGroup";
import { FILTER_TYPE_DATE_PRESETS } from "../../../../utils/constants/constants";
import { set422Errors } from "../../../../store/actions/charts";

const Container = styled.div`
  font-size: 13px;
  padding: 20px;
`;

const FilterButtons = styled.div(
  ({ theme }) => `
  height: 250px;
  overflow-y: auto;
  ${scrollbarDe(theme)};
`
);

export default function IncompatibleFilters({
  errors,
  setShowErrorModal,
  queryId,
  forceUpdatePreview,
  visualizationId,
}) {
  const siteConfig = useSelector((state) => state.siteConfiguration);

  const { tenantId, tab } = useShallowEqualSelector((state) => ({
    tenantId: state.layout.tenantId,
    tab: state.layout.activeTab,
  }));

  const pageId = tab?.uuid;

  const [state, setState] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSiteConfiguration(tenantId));
  }, [dispatch, tenantId]);

  useEffect(() => {
    if (siteConfig.domain && !state) {
      setState(siteConfig);
    }
  }, [siteConfig, state]);

  const { filterIndexes, filters } = errors ?? {};

  if (!state || !filters?.length) {
    return null;
  }

  const nonValidFilterNames = (filters ?? []).filter((_, index) =>
    (filterIndexes ?? []).includes(index)
  );

  function filterBasedActiveTab(filter) {
    if (filter.show.length) {
      return filter.show.includes(tab.uuid);
    }

    return !filter.hide.includes(tab.uuid);
  }

  const siteConfigFilters = state.menuFilters.list
    .filter((filter) => {
      if (filter.type === FILTER_TYPE_DATE_PRESETS) {
        return nonValidFilterNames.some(
          (name) => name === "StartDate" + filter.name
        );
      }
      return nonValidFilterNames.includes(filter.name);
    })
    .filter(filterBasedActiveTab);

  function getButtonText(filter) {
    if (filter.show.includes(pageId)) {
      return "Remove from whitelist";
    }

    return "Add to blacklist";
  }

  function mapFilterList(filter) {
    if (filter.show.length) {
      return { ...filter, show: filter.show.filter((uuid) => uuid !== pageId) };
    }

    return { ...filter, hide: [...filter.hide, pageId] };
  }

  function hideFilter(filterUuid) {
    setState({
      ...state,
      menuFilters: {
        ...state.menuFilters,
        list: state.menuFilters.list.map((filter) => {
          if (filter.uuid === filterUuid) {
            return mapFilterList(filter);
          }

          return filter;
        }),
      },
    });
  }

  async function onSave() {
    // do not remove this await
    // 'await' has no effect on the type of this expression. message is not true
    await dispatch(updateSiteConfiguration(state));

    if (!siteConfigFilters.length) {
      forceUpdatePreview();
      setShowErrorModal(false);

      dispatch(
        set422Errors({ key: "filterIndexes", values: [], visualizationId })
      );
    }
  }

  return (
    <Modal close={() => setShowErrorModal(false)}>
      <Container>
        <div>
          There are filters on this page that are incompatible with your data.
        </div>

        <br />
        <FilterButtons>
          <Flex alignItems="center" direction="column">
            {siteConfigFilters.map((filter) => (
              <Flex
                width="50%"
                justifyContent="flex-end"
                alignItems="center"
                mb2
                mt2
                key={filter.uuid}
              >
                <span style={{ marginRight: 20 }}>{filter.displayName}</span>
                <IOButton
                  type="button"
                  onClick={() => hideFilter(filter.uuid)}
                  style={{ fontSize: 12 }}
                >
                  {getButtonText(filter)}
                </IOButton>
              </Flex>
            ))}
          </Flex>
        </FilterButtons>

        <br />
        <br />
        <Flex justifyContent="space-between" alignItems="center">
          <Link
            to={`/admin/data-settings/mapping/queries/${queryId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonLink>Go to query page to add parameters</ButtonLink>
          </Link>
          <ButtonGroup style={{ width: "fit-content" }} spacing={10}>
            <IOButton
              cancel
              smallPadding
              onClick={() => setShowErrorModal(false)}
            >
              Cancel
            </IOButton>
            <IOButton
              type="button"
              onClick={onSave}
              smallPadding
              processing={siteConfig.processing}
            >
              Save
            </IOButton>
          </ButtonGroup>
        </Flex>
      </Container>
    </Modal>
  );
}
