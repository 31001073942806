import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { isLight, lightenDarkenColor } from "../../../styles/colorConvertor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "../../../utils/ide";
import { Link } from "react-router-dom";
import isPropValid from "@emotion/is-prop-valid";

const buttonCss = ({
  theme,
  cancel,
  outline,
  circle,
  fit,
  smallPadding,
  add,
  error,
  warning,
  processing,
  success,
  standard,
  outlinePrimary,
  whiteBg,
  color,
  rounded,
  to,
  warningDark,
  info,
  gray100,
  bgPrimary,
}) => css`
  border: ${outline
    ? `1px solid ${
        bgPrimary
          ? theme.textOnPrimary
          : outline
          ? theme.type === "dark"
            ? theme.gray.gray700
            : theme.gray.gray300
          : theme.text.secondary
      }`
    : "none"};
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  display: inline-flex;
  ${fit ? "width: 100%;" : ""}
  justify-content: center;
  align-items: center;
  padding: 8px ${smallPadding ? 20 : add && circle ? 10 : 40}px;
  color: ${circle || cancel
    ? theme.text.secondary || theme.background.secondary
    : outline
    ? bgPrimary
      ? theme.textOnPrimary
      : theme.text.secondary
    : color
    ? isLight(color)
      ? "black"
      : "white"
    : "white"};
  background: ${standard
    ? theme.notification.infoMain
    : success
    ? theme.notification.successMain
    : warning
    ? theme.notification.warningMain
    : circle || outline
    ? "none"
    : cancel
    ? theme.type === "light"
      ? "rgba(0,0,0,0.05)"
      : "rgba(255,255,255,0.05)"
    : error
    ? theme.notification.errorMain
    : color
    ? color
    : info
    ? theme.notification.infoMain
    : theme.primary || theme.background.secondary};
  &:hover {
    background: ${success
      ? lightenDarkenColor(theme.notification.successMain, 10)
      : circle || outline || outlinePrimary
      ? "none"
      : cancel
      ? "rgba(0,0,0,0.1)"
      : lightenDarkenColor(theme.text.secondary, 5)};
    border-color: ${circle || outline
      ? lightenDarkenColor(theme.text.secondary, 25)
      : "none"};
    color: ${circle ||
    outline ||
    outlinePrimary ||
    (cancel && theme.type === "light")
      ? lightenDarkenColor(theme.text.secondary, 25)
      : "white"};
  }
  &:disabled {
    opacity: 0.3;
    background: ${processing
      ? lightenDarkenColor(theme.primary, 25)
      : theme.type === "dark" && !whiteBg
      ? "rgba(255, 255, 255, 0.15)"
      : theme.gray.gray400};
    ${theme.type === "dark" && !whiteBg
      ? `
        color: ${theme.gray.gray500};
      `
      : ""}
    cursor: not-allowed;
  }
  svg {
    position: relative;
  }

  ${outlinePrimary &&
  `
    color: ${theme.primary};
    background: none;
    border: 1px solid ${theme.primary};
  `}

  ${rounded &&
  `
    border-radius: 15px;
  `};

  ${warningDark &&
  `
    background: ${theme.notification.warningDark};
    &:hover {
      background: ${lightenDarkenColor(theme.notification.warningDark, 25)};
    }
  `};

  ${gray100 &&
  `
    background: ${theme.gray.gray100};
    &:hover {
      background: ${lightenDarkenColor(theme.gray.gray100, 5)};
      color: black;
    }
    color: black;
  `}
`;

const ButtonContainer = styled.button(buttonCss);
const ButtonLink = styled(Link, {
  shouldForwardProp(propName) {
    // DOM attributes and Link-specific attributes should be forwarded to <Link>.
    return (
      isPropValid(propName) ||
      ["component", "to", "replace", "innerRef"].includes(propName)
    );
  },
})(buttonCss);

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  height: ${(props) => (props.circle ? 24 : 12)}px;
  width: ${(props) => (props.circle ? 24 : 12)}px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid
    ${({ theme, circle, textColorCircle, info }) =>
      info
        ? theme.notification.infoMain
        : circle
        ? textColorCircle
          ? theme.text.secondary
          : theme.primary
        : "none"};
  path {
    fill: ${({ theme, textColorCircle, info }) =>
      info
        ? theme.notification.infoMain
        : textColorCircle
        ? theme.text.secondary
        : theme.primary};
  }
  margin-left: ${(props) => (props.marginLeft ? 10 : 0)}px;
`;

const IOButton = forwardRef((props, ref) => {
  const { cy, ...rest } = props;
  const Button = props.to ? ButtonLink : ButtonContainer;
  return (
    <Button ref={ref} data-cy={cy} {...rest}>
      {props.icon && (
        <FontAwesomeIcon style={{ marginRight: 8 }} icon={props.icon} />
      )}
      {props.processing ? (
        <div style={{ marginRight: 8 }} data-cy="button-processing-spinner">
          <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
        </div>
      ) : null}
      {props.add ? (
        <IconContainer
          info={props.info}
          textColorCircle={props.textColorCircle}
          circle={props.circle}
        >
          <FontAwesomeIcon icon={["fal", "plus"]} />
        </IconContainer>
      ) : null}
      {props.children}
      {props.expandable && (
        <IconContainer
          textColorCircle={props.textColorCircle}
          circle={props.circle}
          marginLeft
        >
          <FontAwesomeIcon icon={["fas", props.expandableIcon]} />
        </IconContainer>
      )}
    </Button>
  );
});

IOButton.defaultProps = {
  type: "submit",
};

IOButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  cancel: PropTypes.bool,
  processing: PropTypes.bool,
  add: PropTypes.bool,
  outline: PropTypes.bool,
  outlinePrimary: PropTypes.bool,
  smallPadding: PropTypes.bool,
  fit: PropTypes.bool,
  circle: PropTypes.bool,
  textColorCircle: PropTypes.bool,
  cy: PropTypes.string,
  // Makes the button into a react-router <Link>
  to: PropTypes.string,
  info: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  standard: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]),
  whiteBg: PropTypes.bool,
  rounded: PropTypes.bool,
  warningDark: PropTypes.bool,
  gray100: PropTypes.bool,
};

export default IOButton;
