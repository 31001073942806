import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import SortingField from "./SortingField";
import fetchFieldValues from "../../../Pages/DataManagement/DataExplorer/Filters/fetchFieldValues";
import axios from "../../../axios";
import { noop } from "lodash-es";
import IOButton from "../../../UI/Form/Button/IOButton";
import Flex from "../../../UI/Flex/Flex";

const itemHeight = 64.4; // px

const Container = styled.div(
  ({ count }) => `
    min-height: ${count * itemHeight}px;
  `
);

/**
 * @param {string} keyPrefix The key prefix to use for React Hook Form
 * @param fields
 * @param orders
 * @param control
 * @param append
 * @param move
 * @param remove
 * @param applySorting
 * @param {Array<{value: any, label: string, type: string, displayName: string}>} fieldOptions
 * @param queryUuid
 * @param setValue
 * @returns {Element}
 * @constructor
 */
export default function SortableList({
  keyPrefix = "orders",
  fields,
  orders,
  control,
  append,
  move,
  remove,
  applySorting = noop,
  fieldOptions,
  queryUuid,
  setValue,
}) {
  function handleChangeValues(nextValues, index) {
    const updatedOrders = [...orders];
    updatedOrders[index].values = nextValues.map((v) => v.value);
    setValue(keyPrefix, updatedOrders);
    applySorting();
  }

  async function getOptions(index) {
    const fieldName = orders[index]?.name;

    // Return empty array if fieldName is not valid
    if (!fieldName) {
      return [];
    }

    // Otherwise, fetch the field values dynamically
    if (fieldName && queryUuid) {
      // try {
      // setLoading(true); // Show loading state
      return await fetchFieldValues(axios, "string", fieldName, queryUuid);
    }

    // Return empty array if none of the conditions above are met
    return [];
  }

  const filteredFieldOptions = (fieldValue) =>
    fieldOptions.filter(({ value }) => {
      return !orders.find(
        (order) => order.name === value && order.name !== fieldValue
      );
    });

  const isDragDisabled = fields.length === 1;

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    move(source.index, destination.index);
    applySorting();
  };

  const dragDrop = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable-sortings"
        isDropDisabled={isDragDisabled}
      >
        {(provided) => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            count={fields.length}
          >
            {fields.map((field, index) => (
              <Draggable
                key={field.name ?? field.id}
                draggableId={field.id}
                index={index}
                isDragDisabled={isDragDisabled}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <SortingField
                      control={control}
                      keyPrefix={keyPrefix}
                      index={index}
                      orders={orders}
                      remove={remove}
                      applySorting={applySorting}
                      filteredFieldOptions={filteredFieldOptions}
                      handleChangeValues={handleChangeValues}
                      getOptions={getOptions}
                      setValue={setValue}
                      dragHandleProps={provided.dragHandleProps}
                      isDragDisabled={isDragDisabled}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <Flex direction="column" gap="1.25rem">
      {dragDrop}

      <IOButton
        style={{ alignSelf: "baseline" }}
        type="button"
        outline
        cy="add-sorting"
        onClick={() => {
          append({ name: null, sort: "ASC" });
        }}
      >
        + Add Sorting Rule
      </IOButton>
    </Flex>
  );
}
