import styled from "@emotion/styled";
import { scrollbarDe } from "../../../styles/styledSnippets";

const Container = styled.div(
  ({ theme }) => `
  height: calc(100vh - 240px);
  border-radius: 5px;
  padding: 10px;
  overflow-y: auto;
  ${scrollbarDe(theme)}
  width: 240px;
  font-size: 13px;
`
);

const TemplateItem = styled.div(
  ({ theme, isActive }) => `
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: ${theme.text.primary};
  }
  color: ${isActive ? theme.primary : theme.text.secondary};
`
);

export default function TemplateList({
  templates,
  setActiveTemplate,
  activeTemplate,
}) {
  return (
    <Container>
      {templates.map((template) => (
        <TemplateItem
          key={template.uuid}
          onClick={() => setActiveTemplate(template)}
          isActive={template.uuid === activeTemplate?.uuid}
        >
          {template.name}
        </TemplateItem>
      ))}
    </Container>
  );
}
