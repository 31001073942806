import React, { useCallback } from "react";
import Modal from "../../../../UI/Modal/Modal";
import IOButton from "../../../../UI/Form/Button/IOButton";
import Flex from "../../../../UI/Flex/Flex";
import { faPencil, faDownload } from "@fortawesome/pro-solid-svg-icons";

export default function FileViewerModal({
  children,
  close,
  edit,
  download,
  downloading,
}) {
  const onEditClick = useCallback(() => {
    close();
    edit();
  }, [close, edit]);

  return (
    <Modal
      controlledFromOutside
      close={close}
      renderInPortal
      fullScreen
      showClose
      title="View document"
    >
      <Flex direction="column" gap="1rem" alignItems="end">
        <Flex gap="1rem" alignItems="center">
          {edit ? (
            <IOButton type="button" onClick={onEditClick} icon={faPencil}>
              Change Document
            </IOButton>
          ) : null}
          <IOButton
            processing={downloading}
            onClick={download}
            outline
            icon={!downloading ? faDownload : undefined}
          >
            Download
          </IOButton>
        </Flex>
        {children}
      </Flex>
    </Modal>
  );
}
