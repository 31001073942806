import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import IOButton from "../../../UI/Form/Button/IOButton";
import Input from "../../../UI/Form/Input/Input";
import Select from "../../../UI/Form/Select/Select";
import { getAggregationPrefix } from "../SettingsMenu/Layout/Column/RegularColumn";
import {
  formulaOption,
  getOverrideByName,
} from "../SettingsMenu/Layout/helper";

const Container = styled.div`
  position: sticky;
  left: 0;
  z-index: 10000;
`;

export default function LayoutActions({
  setChartState,
  chart,
  removeGroupingEffect,
  setRemoveGroupingEffect,
  selectedGroup,
  setSelectedGroup,
  selectedColumn,
  setSelectedColumn,
  fields,
  dynamicSubTitleKeys,
}) {
  const [grouping, setGrouping] = useState(null);
  const [column, setColumn] = useState(null);
  const { subTitles, staticGroupingKeys, groupingKey, overrides } = chart;

  // hide grouping name input on dynamic groups
  useEffect(() => {
    if (groupingKey || !staticGroupingKeys) {
      setGrouping(null);
    }
  }, [groupingKey, staticGroupingKeys]);

  const noGroupingNewColumn = (option, overrides) => {
    const newItem = [option.name, ...(subTitles[0] ?? [])];

    setChartState({
      ...chart,
      subTitles: staticGroupingKeys
        ? chart.subTitles.map((sub, index) => (index === 0 ? newItem : sub))
        : [newItem],
      overrides,
    });
  };

  const addNewColumn = (option) => {
    const override = getOverrideByName(overrides, option.name);
    const isFormula = option.name.includes("fn::");

    const newOverrides =
      override || isFormula ? overrides : [...overrides, option];

    const prefix = getAggregationPrefix(
      override ? override.aggregation : option.aggregation
    );
    const column = prefix + option.name;

    if (!selectedGroup && !dynamicSubTitleKeys) {
      return noGroupingNewColumn(option, newOverrides);
    }

    if (!dynamicSubTitleKeys) {
      const index = +selectedGroup.split("_")[1];

      const subTitle = [
        ...(subTitles[index] || [...subTitles, []][index]),
        column,
      ];

      setChartState({
        ...chart,
        subTitles:
          index > subTitles.length - 1
            ? [...chart.subTitles, subTitle]
            : chart.subTitles.map((sub, i) => (i === index ? subTitle : sub)),
        overrides: newOverrides,
      });
      return;
    }

    setChartState({
      ...chart,
      overrides: newOverrides,
      dynamicSubTitleKeys: [...chart.dynamicSubTitleKeys, column],
    });
  };

  const handleAddGroupClick = () => {
    if (groupingKey) {
      return setChartState({
        ...chart,
        subTitles: [...chart.subTitles, [""]],
      });
    }

    setGrouping((prev) => (prev !== null ? null : ""));
  };

  const removing = () => {
    selectedGroup && setSelectedGroup(null);
    selectedColumn && setSelectedColumn(null);
    setRemoveGroupingEffect(!removeGroupingEffect);
  };

  const addGrouping = (e) => {
    if (e.key === "Enter") {
      setChartState({
        ...chart,
        staticGroupingKeys: [...chart.staticGroupingKeys, grouping],
        subTitles:
          chart.subTitles.length < chart.staticGroupingKeys.length + 1
            ? [...chart.subTitles, []]
            : chart.subTitles,
      });
      setGrouping("");
    }
  };

  return (
    <Container>
      <ButtonGroup spacing={10} style={{ marginBottom: 20 }}>
        {grouping !== null && (
          <Input
            style={{ marginRight: 10 }}
            placeholder="Grouping"
            value={grouping}
            onChange={(e) => setGrouping(e.target.value)}
            onKeyDown={addGrouping}
            disabled={!!dynamicSubTitleKeys}
          />
        )}
        {(staticGroupingKeys || groupingKey) && (
          <IOButton
            add={grouping === null}
            outline
            smallPadding
            onClick={handleAddGroupClick}
            disabled={!!dynamicSubTitleKeys}
          >
            {grouping === null ? "Add " : "Close "}Grouping
          </IOButton>
        )}

        {column && (
          <div style={{ width: 200 }}>
            <Select
              options={[formulaOption, ...fields]}
              getOptionLabel={(option) =>
                option.mapping?.displayName ?? option.name
              }
              getOptionValue={(option) => option.name}
              value={overrides.find((override) => override.name === column)}
              onChange={addNewColumn}
              cy="add-column-select"
              closeMenuOnSelect={false}
            />
          </div>
        )}

        <IOButton
          add={!column}
          outline
          smallPadding
          onClick={() => setColumn((prev) => !prev)}
          cy="add-column-button"
        >
          {!column ? "Add Column" : "Close Adding"}
        </IOButton>

        <div style={{ marginLeft: 20 }}>
          <IOButton smallPadding onClick={removing}>
            {removeGroupingEffect ? "Stop Removing" : "Start Removing"}
          </IOButton>
        </div>
      </ButtonGroup>
    </Container>
  );
}
