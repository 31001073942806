import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";
import { xKeyTicks } from "../utils/charts/xKeyParser";
import formatter from "../utils/formatters/formatter";
import styled from "@emotion/styled";

const Text = styled.text(
  ({ theme, fontSize, textAnchor, transform }) => `
  text-anchor: ${textAnchor};
  font-size: ${fontSize};
  transform: ${transform};
  fill: ${theme.text.secondary} !important;
  font-weight: 400 !important;
`
);

export default function SimpleXAxis(props) {
  const {
    width,
    height,
    xScale,
    values,
    xKeyFormat,
    allTicks,
    xTicksColor,
    xInner,
  } = props;

  const theme = useTheme();
  const shouldRotateLabels = width / values.length < 70;
  const x = (v) => {
    return Math.floor(
      xScale(v) +
        xScale.bandwidth() * 0.45 +
        (xInner ? xInner.bandwidth() * 0.55 : 0)
    );
  };

  function renderTick(v) {
    return formatter(v, xKeyFormat);
  }

  return (
    <g data-cy="bands-x-axis">
      <line
        x1={0}
        x2={width}
        y1={height}
        y2={height}
        style={{ stroke: theme.divider }}
      />
      <g transform={`translate(-6, ${height + 20})`} id="x-Axis">
        {values.map((v, i, s) =>
          xKeyTicks(s.length, i, allTicks) ? (
            <g
              key={v + "" + i}
              transform={`translate(${x(v)}, 0)`}
              data-cy="x-axis-key"
            >
              <Text
                textAnchor={shouldRotateLabels ? "end" : "middle"}
                fontSize={shouldRotateLabels ? 9 : 10}
                transform={
                  shouldRotateLabels
                    ? "translate(8 -10) rotate(-25) "
                    : "translate(8)"
                }
                fill={xTicksColor}
                data-cy="x-axis-tick-text"
              >
                {renderTick(v)}
              </Text>
            </g>
          ) : null
        )}
      </g>
    </g>
  );
}

SimpleXAxis.propTypes = {
  values: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  xScale: PropTypes.func,
};
