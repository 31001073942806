import React from "react";
import styled from "@emotion/styled";

const Container = styled.div(
  (props) => `
    width: ${props.width ? props.width : "1px"};
    height:  ${props.height};
    margin: 8px;
    border-right: 1px solid ${props.theme.divider};
`
);

export default function VerticalDivider(props) {
  const { height, width } = props.chart;

  return <Container height={height} width={width} />;
}
