import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faFilePdf,
  faFileExcel,
  faFileWord,
  faPercentage,
} from "@fortawesome/pro-light-svg-icons";
import { ACTIVE_TABLE_FILE_TYPE_MAP } from "../../../utils/activeTable";
import formatter from "../../../utils/formatters/formatter";

const LabelSpan = styled.span`
  path {
    fill: ${(props) => props.theme.text.primary} !important;
  }
`;

const Label = (props) => {
  return (
    <LabelSpan>
      <FontAwesomeIcon
        style={{ fontSize: 16, position: "relative", top: 1 }}
        icon={typeof props.icon === "string" ? ["fal", props.icon] : props.icon}
        fixedWidth
      />
      {props.text}
    </LabelSpan>
  );
};

const plainNumberDisplayOptions = [
  {
    label: <Label icon="list-ol" text="Whole number (2,030)" />,
    value: "integer",
  },
  {
    label: <Label icon="list-ol" text="1 decimal (2,030.0)" />,
    value: "decimal-tenth",
  },
  {
    label: <Label icon="list-ol" text="2 decimal (2,030.00)" />,
    value: "decimal",
  },
];

const currencyDisplayOptions = [
  {
    label: <Label icon="dollar-sign" text="Whole number ($2,030)" />,
    value: "currency-whole",
  },
  {
    label: <Label icon="dollar-sign" text="1 decimal ($2,030.0)" />,
    value: "currency-tenth",
  },
  {
    label: <Label icon="dollar-sign" text="2 decimal ($2,030.00)" />,
    value: "currency",
  },
];

const percentageDisplayOptions = [
  {
    label: <Label icon={faPercentage} text="Whole number (20%)" />,
    value: "percent",
  },
  {
    label: <Label icon={faPercentage} text="1 decimal ($20.3%)" />,
    value: "percent-tenth",
  },
  {
    label: <Label icon={faPercentage} text="2 decimal ($20.30%)" />,
    value: "percent-hundredth",
  },
  {
    label: <Label icon={faPercentage} text="% Whole (20 = 20%)" />,
    value: "percent-round",
  },
  {
    label: <Label icon={faPercentage} text="% Whole 1 decimal (20 = 20.0%)" />,
    value: "percent-whole",
  },
  {
    label: <Label icon={faPercentage} text="% Whole 2 decimal (20 = 20.00%)" />,
    value: "percent-whole-2",
  },
];

const exampleDate = "2023-04-23 13:05:45.123";

const dateDisplayOptions = [
  {
    label: (
      <Label
        icon={faCalendar}
        text={`Date (${formatter(exampleDate, "date-active-table")})`}
      />
    ),
    // Can't just use "date", because then it would also edit the field
    // as a date, and ActiveGrid's date editor crashes if the date is not
    // in the exact '2023-04-23' format.
    value: "date-active-table",
  },
  {
    label: (
      <Label
        icon={faCalendar}
        text={`Date with time (${formatter(exampleDate, "datetime")})`}
      />
    ),
    value: "datetime",
  },
];

const numberDisplayOptions = [
  ...plainNumberDisplayOptions,
  ...currencyDisplayOptions,
  ...percentageDisplayOptions,
  ...dateDisplayOptions,
];

const allDisplayOptions = [
  {
    label: "Default",
    value: undefined,
  },
  {
    label: <Label icon="image" text="Image (read-only)" />,
    value: "api-image",
  },
  ...numberDisplayOptions,
];

export const typeOptions = [
  {
    label: <Label icon="mobile-android-alt" text="Integer" />,
    value: "integer",
    displayOptions: [
      {
        label: <Label icon="tally" text="No format (2030)" />,
        value: undefined,
      },
      ...numberDisplayOptions,
    ],
  },
  {
    label: <Label icon="list-ol" text="Decimal" />,
    value: "decimal",
    displayOptions: [
      {
        label: <Label icon="tally" text="No format (2030.4567)" />,
        value: undefined,
      },
      ...numberDisplayOptions,
    ],
  },
  {
    label: <Label icon="toggle-on" text="Boolean" />,
    value: "boolean",
  },
  {
    label: <Label icon="calendar" text="Date" />,
    value: "date",
  },
  {
    label: <Label icon="text-size" text="String" />,
    value: "string",
    displayOptions: allDisplayOptions,
  },
  {
    label: <Label icon="text-width" text="Text (Deprecated)" />,
    value: "text",
    deprecated: true,
  },
  {
    label: <Label icon="file-invoice-dollar" text="Currency" />,
    value: "currency",
    displayOptions: currencyDisplayOptions,
  },
  {
    label: <Label icon="image" text="Image" />,
    value: "api-image",
  },
  {
    label: <Label icon={faFilePdf} text="PDF" />,
    value: "api-pdf",
  },
  {
    label: <Label icon={faFileExcel} text="Excel" />,
    value: "api-excel",
  },
  {
    label: <Label icon={faFileWord} text="Word" />,
    value: "api-word",
  },
  {
    label: (
      <Label
        icon={ACTIVE_TABLE_FILE_TYPE_MAP["api-document"].light_icon}
        text="Any Document"
      />
    ),
    value: "api-document",
  },
];

export const editOptions = [
  {
    label: <Label icon="keyboard" text="Text entry" />,
    value: "string",
  },
  {
    label: <Label icon="list-alt" text="List select" />,
    value: "select",
  },
];

export function getTypeAndDisplayOptions(colDef) {
  const { type, cellEditorParams } = colDef;

  return {
    type,
    displayOptions: allDisplayOptions,
    displayOptionType: cellEditorParams?.displayFromatOverride,
  };
}
