import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";
import Select from "../../../../../UI/Form/Select/Select";
import {
  getBlendedQueryFieldLableWithAlias,
  getOverrideByName,
} from "../helper";
import { getAggregationPrefix } from "./RegularColumn";

export default function ColumnLinkSelector({
  chart,
  setChartState,
  selectedColumn,
  fields,
  overrides,
}) {
  if (!selectedColumn) {
    return null;
  }

  const { column } = selectedColumn;
  const linkColumns = chart.linkColumns ?? [];
  const link = linkColumns.find((link) => link.key === column);

  function onCheckboxChange() {
    setChartState({
      ...chart,
      linkColumns: !link
        ? [...linkColumns, { key: column }]
        : linkColumns.filter((link) => link.key !== column),
    });
  }

  function onURLColumnChange(field) {
    const aggregation = getOverrideByName(overrides, field.name)?.aggregation;
    const aggregationPrefix = getAggregationPrefix(aggregation);

    setChartState({
      ...chart,
      linkColumns: linkColumns.map((link) =>
        link.key === column
          ? { ...link, urlKey: aggregationPrefix + field.name }
          : link
      ),
    });
  }

  return (
    <div style={{ marginBottom: 15 }} data-cy="link-column-container">
      <Checkbox
        label="Link"
        checked={!!link}
        onChange={onCheckboxChange}
        cy="link-column-checkbox"
      />
      <br />
      {link ? (
        <Select
          options={fields}
          getOptionLabel={(option) =>
            getBlendedQueryFieldLableWithAlias(option)
          }
          getOptionValue={(option) => option.name}
          simpleValue={column}
          onChange={onURLColumnChange}
          label="URL Column"
          cy="link-column-select"
        />
      ) : null}
    </div>
  );
}
